import React from "react";

interface PropsTypes {
  onChange: (value: string) => void;
  value: string;
  className?: string;
  row?: number;
  placeholder?: string;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
}

const TextArea: React.FC<PropsTypes> = ({
  onChange,
  value,
  className = "form-control f-12",
  row = 2,
  placeholder,
  disabled,
  minLength,
  maxLength,
}) => {
  const changeHandler = e => {
    onChange(e.target.value);
  };

  const text = value !== undefined && value !== null ? value : "";

  return (
    <textarea
      rows={row}
      className={className}
      placeholder={placeholder}
      maxLength={maxLength}
      minLength={minLength}
      value={text}
      onChange={changeHandler}
      disabled={disabled}
    />
  );
};

export default TextArea;
