import React from "react";
import { Button } from "reactstrap";
import { languageSet } from "@qubit-utill/src";

interface PropTypes {
  color: string;
  className: string;
  data: any;
  name: string;
  type: string;
  size: string;
}

const DownLoad: React.FC<PropTypes> = ({ color = "dark", className, data, name, type, size }) => {
  const makeDownLoad = () => {
    try {
      const a = document.createElement("a");

      const url = window.URL.createObjectURL(data);

      a.href = url;
      a.id = name;
      a.download = `${name}.${type}`;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      var downtag = document.querySelector(`#${name}`); //제거하고자 하는 엘리먼트
      if (!downtag) {
        throw { message: "downtag 없습니다" };
      }

      downtag.parentNode && downtag.parentNode.removeChild(downtag);
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <Button size={size} color={color} onClick={makeDownLoad} className={className}>
      {languageSet("다운로드")}
    </Button>
  );
};

export default DownLoad;
