const { APIURL, CDNURL, COOKIENAME, DEFAULTLANG, COOKIELANG } = window.GlobalPathMap || {
  APIURL: "",
  CDNURL: "",
  COOKIENAME: "",
  DEFAULTLANG: "",
  COOKIELANG: "ko",
};

export default {
  APIURL,
  CDNURL: CDNURL + "/assets/images",
  COOKIENAME,
  DEFAULTLANG,
  COOKIELANG,
};
