import SetterModel from "./SetterModel";
import { observable } from "mobx";
import { languageSet } from "@qubit-utill/src";
import { ModalStore } from "../store";

class ModalModel extends SetterModel {
  constructor(params) {
    super(params);
    params.title ? (this.title = params.title) : (this.title = languageSet("알림"));
  }

  @observable
  titleBg;

  @observable
  color;

  @observable
  isUseCustomModal = false;

  @observable
  uuidKey = "";

  @observable
  closeBlock = false;

  @observable
  closeModal = () => {
    this.closeCallBack && this.closeCallBack();
    ModalStore.closeModal(this.uuidKey);
  };

  @observable
  isUseCancelBtn = false;

  @observable
  isLoading = false;

  @observable
  closeCallBack = () => {};

  @observable
  clickOk = () => {
    this.okCallBack && this.okCallBack();
    !this.closeBlock && ModalStore.closeModal(this.uuidKey);
  };

  @observable
  okCallBack = "";

  @observable
  isUseOkBtn = false;

  @observable
  message = "";

  @observable
  subMessage = "";

  @observable
  childrenParams = {};

  @observable
  title = languageSet("알림");

  @observable
  children = "";

  @observable
  largeModal = false;

  @observable
  initButton = false;

  @observable
  clickInit = () => {};

  @observable
  closeBlock = false;

  @observable
  isUseCustomBtn = false;

  @observable
  customerBtnText = "";

  @observable
  logOrigin = "";

  @observable
  customCloseBlock = false;

  @observable
  customCallBack = () => {};

  @observable
  clickCustom = () => {
    this.customCallBack && this.customCallBack();
    !this.customCloseBlock && ModalStore.closeModal(this.uuidKey);
  };
}

export default ModalModel;
