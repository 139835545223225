import axios from "axios";
import qs from "qs";
import { toJS } from "mobx";

import LoadingStore from "@qubit-utill/src/store/LoadingStore";
import ModalStore from "@qubit-utill/src/store/ModalStore";
import { objectUtil } from "@qubit-utill/src/utils";

import { languageSet } from "@src/utils";
import { getURI, APIURL } from "@src/constants";

const axiosModules = {
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),

  get(url, params?, cancelToken?) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "get",
      params: validParams,
      paramsSerializer: this.paramsSerializer,
      cancelToken,
    });
  },

  post(url, data?, params?) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "post",
      data,
      params: validParams,
      paramsSerializer: this.paramsSerializer,
    });
  },

  del(url, params?) {
    return this.axiosSetting(url, {
      method: "delete",
      params: params,
      paramsSerializer: this.paramsSerializer,
    });
  },

  patch(url, data?, params?) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "patch",
      data,
      params: validParams,
      paramsSerializer: this.paramsSerializer,
    });
  },

  put(url, data?, params?) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "put",
      data,
      params: validParams,
      paramsSerializer: this.paramsSerializer,
    });
  },

  getCsv(url, params, cancelToken) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(
      url,
      {
        method: "get",
        params: validParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      },
      false
    );
  },

  getBlob(url, params) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(
      url,
      {
        method: "get",
        params: validParams,
        paramsSerializer: this.paramsSerializer,
        responseType: "blob",
      },
      false
    );
  },

  async axiosSetting(url, axiosOptions, isCheckValid = true) {
    axiosOptions.url = `${APIURL}${url}`;
    // axiosOptions.headers = {
    //   "X-Frame-Options": "deny",
    //   "X-Content-Type-Options": "nosniff",
    // };

    return this.callAxios(axiosOptions, isCheckValid);
  },

  //TODO: return value 추가 수정 필요.
  checkReturnValid: ({ code, message }) => {
    if (code === "0000") {
      return true;
    } else if (code === "3102" || code === "3104") {
      //로그인 정보 만료
      ModalStore.openAlertModal({
        message: message,
        okCallBack: () => (window.location.href = getURI("LOGIN")),
        closeCallBack: () => (window.location.href = getURI("LOGIN")),
      });
      LoadingStore.completed();
    } else {
      return false;
    }
  },

  handleAPIError: (response) => {
    if (response instanceof Error) {
      ModalStore.openAlertModal({
        message: languageSet("에러_네트워크"),
      });
      LoadingStore.completed();
    }
  },

  callAxios(options, isCheckValid = true) {
    const _this = this;
    options.withCredentials = true;
    // options.headers = { ...options.headers, "Cache-Control": "no-store" };
    options.headers = { ...options.headers };
    return new Promise(function(resolve, reject) {
      axios(options)
        .then((response) => {
          if (response.status === 403 || response.status === 406) {
            ModalStore.openAlertModal({
              title: languageSet("경고"),
              message: languageSet("메세지_해킹의심경고"),
            });
            reject(response);
            return;
          }

          if (isCheckValid) {
            if (!_this.checkReturnValid(response.data)) {
              reject(response);
              return;
            }
          } else if (!response.data) {
            reject(response);
            return;
          }
          resolve(response.data);
        })
        .catch((error) => {
          _this.handleAPIError(error);
          reject(error);
          setTimeout(() => {
            ModalStore.modals = {};
          }, 30000);
        });
    });
  },
};

export default axiosModules;
