import React from "react"
import PropTypes from "prop-types"
import Input from "."

const TextWithTitle = ({ onChange, value, className, title, disabled }) => {
  return (
    <div className="dis-inline-b p-tb-3">
      <span className="dis-inline-b p-r-5 f-13 min_w100">{title}</span>
      <span className="dis-inline-b">
        <Input.Text onChange={onChange} value={value} className={className} disabled={disabled} />
      </span>
    </div>
  )
}

TextWithTitle.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default TextWithTitle
