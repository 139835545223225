import {
  subDays,
  subMonths,
  startOfDay,
  endOfDay,
  getWeek,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  setDay,
  setWeek,
  isBefore,
  startOfWeek,
  addDays,
  subWeeks,
  eachWeekOfInterval,
  format,
  getWeekYear,
} from "date-fns";
import { languageSet } from "@qubit-utill/src";
import { formatConstants } from "@qubit-utill/src/common_constants";

let dateUtil = {
  dateOption: {
    weekStartsOn: 0,
    firstWeekContainsDate: 4,
  },
  parseDateCode(code, mindate) {
    let date = "";

    if (code === "week") {
      date = subDays(new Date(), 6);
    } else if (code === "month") {
      date = subMonths(new Date(), 1);
    } else if (code === "total") {
      date = subDays(new Date(), mindate);
    } else if (code === "yesterday") {
      date = subDays(new Date(), 1);
    } else if (code === "today") {
      date = new Date().toString();
    }

    const startDateOfToDAy = startOfDay(new Date(date));
    const startDate = startDateOfToDAy;
    const endDate = endOfDay(code === "yesterday" ? new Date(date) : new Date());

    return { startDate, endDate };
  },
  getDateByForm: function(date, operator = "-") {
    if (Boolean(date) === false) {
      return Boolean(date);
    }
    return this.format(new Date(date), `yyyy${operator}MM${operator}dd`);
  },

  defaultFormat: function(date) {
    if (Boolean(date) === false) {
      return Boolean(date);
    }
    return this.format(this.newDate(date), formatConstants.DATEFORMAT);
  },

  getDateTimeByForm: function(date) {
    if (Boolean(date) === false) {
      return Boolean(date);
    }

    return this.format(new Date(date), formatConstants.DATEFORMATWITHTIME);
  },

  getWeekDay: function(day) {
    let date = typeof day === "number" ? day : new Date(day).getDay();
    let weekday = [
      languageSet("일요일"),
      languageSet("월요일"),
      languageSet("화요일"),
      languageSet("수요일"),
      languageSet("목요일"),
      languageSet("금요일"),
      languageSet("토요일"),
    ];
    return weekday[date];
  },

  modelDateFormat(date) {
    date = date || "-";
    return date.split(".")[0];
  },

  isCompareDateFlag(limit, compareDate) {
    // 특정기간이 경과하면 false
    let today = new Date();
    return isBefore(subDays(today, limit), this.newDate(compareDate));
  },

  timeage: function(date) {
    var returnDate = "";
    var miliseconds = new Date() - new Date(date);
    var resultDate = Math.floor(miliseconds / 1000); // miliseconds to seconds
    if (resultDate < 60) {
      returnDate = languageSet("방금");
    } else if (resultDate >= 60 && resultDate < 3600) {
      returnDate = languageSet("n분_전", Math.floor(resultDate / 60));
    } else if (resultDate >= 3600 && resultDate < 86400) {
      returnDate = languageSet("n시간_전", Math.floor(resultDate / 3600));
    } else if (resultDate >= 86400 && resultDate < 2419200) {
      returnDate = languageSet("n일_전", Math.floor(resultDate / 86400));
    } else {
      returnDate = date;
    }

    return returnDate;
  },

  format(date, type) {
    if (!date || !date.valueOf()) return "";

    if (typeof date !== "object") {
      date = this.newDate(date);
    }

    var weekName = [
      languageSet("일요일"),
      languageSet("월요일"),
      languageSet("화요일"),
      languageSet("수요일"),
      languageSet("목요일"),
      languageSet("금요일"),
      languageSet("토요일"),
    ];
    var d = date;
    var h = 0;
    const convertor = $1 => {
      switch ($1) {
        case "yyyy":
          return d.getFullYear();
        case "yy":
          return this.zf(d.getFullYear() % 1000, 2);
        case "MM":
          return this.zf(d.getMonth() + 1, 2);
        case "dd":
          return this.zf(d.getDate(), 2);
        case "E":
          return weekName[d.getDay()];
        case "HH":
          return this.zf(d.getHours(), 2);
        case "hh":
          return this.zf((h = d.getHours() % 12) ? h : 12, 2);
        case "mm":
          return this.zf(d.getMinutes(), 2);
        case "ss":
          return this.zf(d.getSeconds(), 2);
        case "a/p":
          return d.getHours() < 12 ? languageSet("오전") : languageSet("오후");
        default:
          return $1;
      }
    };

    const result = type.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, convertor);

    return result;
  },
  string(str, len) {
    var s = "",
      i = 0;
    while (i++ < len) {
      s += str;
    }
    return s;
  },
  zf(str, len) {
    const result = this.string("0", len - str.toString().length) + str.toString();
    return result;
  },

  //ie 대응 new Date
  newDate(dateText, returnData) {
    var dateTime = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])\s([1-9]|[01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
    var date = /^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/;
    var time = /^([1-9]|[01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

    if (dateTime.test(dateText)) {
      var _date = dateText.split(" ")[0].split("-");
      var _time = dateText.split(" ")[1].split(":");
      return new Date(_date[0], Number(_date[1]) - 1, _date[2], _time[0], _time[1], _time[2]);
    } else if (time.test(dateText)) {
      const timeArr = dateText.split(":");
      return new Date(2019, 0, 1, timeArr[0], timeArr[1], timeArr[2]);
    } else if (date.test(dateText)) {
      return new Date(dateText);
    }

    return returnData === null ? null : new Date();
  },

  getWeekNumber(date) {
    return getWeek(date, this.dateOption);
  },

  getWeekYear(date) {
    return getWeekYear(date, this.dateOption);
  },

  getDateInfo(date) {
    return {
      detectYear: date.getFullYear(),
      detectWeek: dateUtil.getWeekNumber(date),
      detectWeekYear: dateUtil.getWeekYear(date),
      detectMonth: date.getMonth() + 1,
      detectDate: dateUtil.format(date, formatConstants.DATEFORMAT),
    };
  },

  getDateByInterval(start, end) {
    let arr = eachDayOfInterval({ start: new Date(start), end: new Date(end) });
    arr = arr.map(d => this.format(d, formatConstants.DATEFORMAT));
    return arr;
  },

  getWeekInterval(date) {
    const arr = eachWeekOfInterval(
      {
        start: startOfMonth(new Date(date)),
        end: endOfMonth(new Date(date)),
      },
      this.dateOption
    );
    return arr.map(d => this.getWeekNumber(d));
  },

  getDateByWeek(year, month, weekIndex, dayIndex) {
    let d = new Date(year, month - 1);
    let arr = this.getWeekInterval(d);
    if (arr[0] > arr[weekIndex] && weekIndex !== 0) {
      d = new Date(year, month);
    }
    d = setWeek(d, arr[weekIndex], this.dateOption);
    d = setDay(d, dayIndex, this.dateOption);
    return dateUtil.format(d, formatConstants.DATEFORMAT);
  },
  getWeekDays(date) {
    const days = [startOfWeek(new Date(date))];
    for (let i = 1; i < 7; i++) {
      days.push(addDays(startOfWeek(new Date(date)), i));
    }
    return days;
  },
  getDateByWeekNumber(year, month, weekNumber, dayIndex) {
    let d = new Date(year, month - 1);
    let arr = this.getWeekInterval(d);
    if (arr[0] > weekNumber) {
      if (arr[0] + weekNumber > 70) {
        d = new Date(year, month - 2);
      } else {
        d = new Date(+year, month);
      }
    }
    d = setWeek(d, weekNumber, this.dateOption);
    d = setDay(d, dayIndex, this.dateOption);
    return dateUtil.format(d, formatConstants.DATEFORMAT);
  },
  subWeeks(subWeek, year, weekNumber, dayIndex) {
    let d = new Date(year.toString());
    d = setWeek(d, weekNumber, this.dateOption);
    d = subWeeks(d, subWeek);
    d = setDay(d, dayIndex);

    return dateUtil.format(d, formatConstants.DATEFORMAT);
  },
  getRangeString(start, end) {
    const startString = dateUtil.format(new Date(start), formatConstants.DATEFORMAT);
    const endString = dateUtil.format(new Date(end), formatConstants.DATEFORMAT);

    return startString === endString ? startString : `${startString}-${endString}`;
  },
  getRangeParamDate(date, seconds) {
    let [start, end] = [new Date(date), new Date(date)];
    switch (seconds) {
      case 1:
        start.setSeconds(start.getSeconds() - 1);
        end.setSeconds(end.getSeconds() + 1);
        break;
      case 5:
        start.setSeconds(start.getSeconds() - 5);
        end.setSeconds(end.getSeconds() + 5);
        break;
      case 60:
        start.setMinutes(start.getMinutes() - 1);
        end.setMinutes(end.getMinutes() + 1);
        break;
      default:
        break;
    }
    start = format(start, formatConstants.DATEFORMATWITHTIME);
    end = format(end, formatConstants.DATEFORMATWITHTIME);
    return [start, end];
  },
  getWeekDayStartMonday: function(day) {
    let date = typeof day === "number" ? day : new Date(day).getDay();
    let weekday = [
      languageSet("월"),
      languageSet("화"),
      languageSet("수"),
      languageSet("목"),
      languageSet("금"),
      languageSet("토"),
      languageSet("일"),
    ];
    return weekday[date];
  },
  getWeekNumberByMonth(date) {
    const inputDate = new Date(date);
    let year = inputDate.getFullYear();
    let month = inputDate.getMonth() + 1;

    // 목요일 기준 주차 구하기 (월과 월 사이의 한주의 시작 기준은 목요일 포함여부)
    const weekNumberByThurFnc = paramDate => {
      const year = paramDate.getFullYear();
      const month = paramDate.getMonth();
      const date = paramDate.getDate();

      const firstDate = new Date(year, month, 1);
      const lastDate = new Date(year, month + 1, 0);
      const firstDayOfWeek = firstDate.getDay();
      const lastDayOfweek = lastDate.getDay();

      const lastDay = lastDate.getDate();
      const firstWeekCheck = firstDayOfWeek === 4 || firstDayOfWeek === 5 || firstDayOfWeek === 6;
      const lastWeekCheck = lastDayOfweek === 1 || lastDayOfweek === 2 || lastDayOfweek === 7;

      const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7);

      let weekNo = Math.ceil((firstDayOfWeek + date) / 7);
      // 입력한 날짜가 첫 주에 있고 첫 날이 일, 월, 화로 시작한다면 'prev'(전달 마지막 주)
      if (weekNo === 1 && firstWeekCheck) weekNo = "prev";
      // 입력한 날짜가 마지막 주에 있고 마지막 날이 일, 월, 화로 끝난다면 'next'(다음달 첫 주)
      else if (weekNo === lastWeekNo && lastWeekCheck) weekNo = "next";
      // 입력한 날짜의 첫 주는 아니지만 첫날이 일, 월, 화로 시작하면 -1;
      else if (firstWeekCheck) weekNo = weekNo - 1;

      return weekNo;
    };

    // 목요일 기준의 주차
    let weekNo = weekNumberByThurFnc(inputDate);
    // 이전달의 마지막 주차일 떄
    if (weekNo === "prev") {
      const afterDate = new Date(year, month - 1, 0);
      year = month === 1 ? year - 1 : year;
      month = month === 1 ? 12 : month - 1;
      weekNo = weekNumberByThurFnc(afterDate);
    }
    // 다음달의 첫 주차일 때
    if (weekNo === "next") {
      year = month === 12 ? year + 1 : year;
      month = month === 12 ? 1 : month + 1;
      weekNo = 1;
    }

    return { year, month, weekNo };
  },
  setYearWeekWeekNo(year, month, weekNo) {
    return languageSet("연도월주차", year, month, weekNo);
  },
  setWeekWithMonthForReport(date, number = 5) {
    let weekInfoArray = [];
    for (let step = 0; step < number; step++) {
      let d = new Date(date);
      const dayOfMonth = d.getDate();
      d.setDate(dayOfMonth - step * 7);
      const _year = d.getFullYear();
      const _month = String("0" + (d.getMonth() + 1)).slice(-2);
      const _day = String("0" + d.getDate()).slice(-2);
      let fullDate = `${_year}-${_month}-${_day}`;
      const { year, month, weekNo } = this.getWeekNumberByMonth(fullDate);
      weekInfoArray.push({ year, month, weekNo });
    }
    return weekInfoArray
      .reverse()
      .map(data => this.setYearWeekWeekNo(data.year, data.month, data.weekNo));
  },
};

export default dateUtil;
