import React from "react";
import { observer } from "mobx-react";

interface propsType {
  value: string;
  placeholder?: string;
  className?: string;
  style?: {};
  disabled?: boolean;
  readOnly?: boolean;
  size?: string | number;
  noSize?: boolean;
  minSize?: number;
  maxSize?: number;
  color?: string;
  noColor?: boolean;
  onKeyDown?: Function;
  onChange?: Function;
  onClick?: () => {};
  onFocus?: () => {};
  onPaste?: () => {};
  autoFocus?: boolean;
}

const Email: React.FC<propsType> = ({
  placeholder,
  className,
  size = "sm",
  color = "white",
  readOnly,
  noSize,
  noColor,
  disabled,
  minSize,
  maxSize,
  value = "",
  onKeyDown,
  onChange,
  onClick,
  style,
  onFocus,
  onPaste,
  autoFocus = false,
}) => {
  const setText = e => {
    try {
      onChange && onChange(e.target.value);
    } catch (error) {}
  };

  const _onKeyDown = e => {
    if (e.keyCode && e.keyCode === 13) {
      onKeyDown && onKeyDown(e.target.value);
    }
  };

  const sizeClass = !noSize ? `input-${size}` : "";
  const colorClass = !noColor ? `bg-${color}` : "";

  return (
    <form>
      <input
        onClick={onClick}
        className={`form-control ${sizeClass} ${colorClass} ${className}`}
        style={style}
        type="email"
        name="email"
        value={value !== undefined && value !== null ? value : ""}
        disabled={disabled}
        onChange={setText}
        placeholder={placeholder}
        readOnly={readOnly}
        onKeyDown={_onKeyDown}
        minLength={minSize}
        maxLength={maxSize}
        onFocus={onFocus}
        onPaste={onPaste}
        autoFocus={autoFocus}
      />
    </form>
  );
};

export default observer(Email);
