import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";

interface PropsTypes {
  placeholder?: string;
  className?: string;
  size?: string;
  color?: string;
  value: any;
  readOnly?: any;
  noSize?: Boolean;
  noColor?: Boolean;
  autoComplete?: string;
  type?: Boolean;
  maxSize?: any;
  onChange: Function;
  onKeyDown?: Function;
  getIsCapsLock?: any;
}

const Password: React.FC<PropsTypes> = ({
  placeholder,
  className,
  size = "sm",
  color = "whtie",
  value,
  readOnly,
  noSize,
  noColor,
  autoComplete,
  type = true,
  maxSize,
  onChange,
  onKeyDown,
  getIsCapsLock,
}) => {
  const state = useLocalStore(() => ({
    setInitInfo() {
      this.text = value;
    },

    text: "",
  }));

  const setText = e => {
    state.text = e.target.value;
    onChange && onChange(e.target.value);
  };

  const _onKeyDown = e => {
    if (e.keyCode && e.keyCode === 13) {
      onKeyDown && onKeyDown(e.target.value);
    }

    if (e.getModifierState("CapsLock")) {
      console.log(e.getModifierState("CapsLock"));
      getIsCapsLock && getIsCapsLock(true);
    } else {
      getIsCapsLock && getIsCapsLock(false);
    }
  };

  useEffect(() => {
    state.setInitInfo();
  });

  return (
    <input
      className={`form-control ${!noSize ? `input-${size}` : ""} ${
        !noColor ? `bg-${color}` : ""
      } ${className}`}
      type={`${type ? "password" : "text"}`}
      value={value ? value : state.text}
      onChange={setText}
      placeholder={placeholder}
      readOnly={readOnly}
      onKeyDown={_onKeyDown}
      autoComplete={autoComplete}
      maxLength={maxSize}
    />
  );
};

export default observer(Password);
