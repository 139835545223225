import axiosModules from "@src/utils/config/axiosSetting";

export default {
  //회사 > 관리 > 라이선스 갱신
  updateCompanyLicense: (data): Promise<any> => {
    return axiosModules.patch("/company/license/update", data);
  },

  //회사 > 관리 > 라이선스 정보
  getCompanyLicenseInfo: (): Promise<any> => {
    return axiosModules.get("/company/license");
  },

  //회사 > 관리 > 회사명 변경
  updateCompanyName: (data): Promise<any> => {
    return axiosModules.patch("/company", data);
  },

  //회사 > 회원 > 회원 목록 조회
  getCompanyMemberList: (params): Promise<any> => {
    return axiosModules.get("/company/member/list", params);
  },

  //회사 > 회원 > 회원 삭제
  deleteCompanyMembers: (params): Promise<any> => {
    return axiosModules.del("/company/member", params);
  },

  // ADMIN (PAUL) > 라이선스 관리 > 라이선스 등급 목록
  getLicenseCodeList: (): Promise<any> => {
    return axiosModules.get("/admin/license/grade");
  },
};
