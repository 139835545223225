import React, { Fragment } from "react";
import { path } from "@qubit-utill/src/common_constants";

const SearchFilterName = ({
  isUseImageFlag,
  selectEntryDisplayName,
  selectEntryImageUri,
  selectEntryImageClass,
  depth,
}) => {
  if (isUseImageFlag) {
    if (selectEntryImageClass) {
      return (
        <Fragment>
          <i className={selectEntryImageClass} />
          {selectEntryDisplayName}
        </Fragment>
      );
    }
    return <img src={`${path.PURPLECOWURL}${selectEntryImageUri}`} alt={selectEntryDisplayName} />;
  } else {
    return <span style={{ paddingLeft: (depth - 1) * 10 }}>{selectEntryDisplayName}</span>;
  }
};

SearchFilterName.propTypes = {};

SearchFilterName.defaultProps = {
  depth: 0,
};

export default SearchFilterName;
