import { useLocalStore } from "mobx-react";
import { observable, action } from "mobx";

class ToggleStore {
  constructor(defaultFlag: boolean) {
    this.isToggle = defaultFlag;
  }
  @observable
  isToggle = false;

  @action
  setIsToggle = e => {
    e && e.preventDefault();
    this.isToggle = !this.isToggle;
  };

  @action
  onMouseOver = e => {
    e && e.preventDefault();
    this.isToggle = true;
  };

  @action
  onMouseLeave = e => {
    e && e.preventDefault();
    this.isToggle = false;
  };
}

export default function useIsToggle(defaultFlag: boolean = false) {
  const state = useLocalStore(() => new ToggleStore(defaultFlag));
  return state;
}
