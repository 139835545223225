import React, { Component } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import PropTypes from "prop-types";
import { observable, action, computed } from "mobx";
import { observer, PropTypes as Mobx_PropTypes, Observer } from "mobx-react";

import { languageSet } from "@qubit-utill/src";
import { CheckBox, Input } from "@qubit-utill/src/components/atoms";

import SearchFilterName from "./SearchFilterName";
import WithDropdown from "../../../hoc/WithDropdown";
import DropGroup from "./DropGroup";

@WithDropdown
@observer
class ListFilterMultiDropdown extends Component {
  componentDidMount() {
    const { selectedValue, items } = this.props;
    items &&
      items.length > 0 &&
      items.forEach(({ selectEntryEntryValue, _setInfo }) => {
        selectEntryEntryValue ===
          (typeof selectedValue === "object"
            ? selectedValue.find(el => el === selectEntryEntryValue)
            : selectedValue) && _setInfo("isSelected")(true);
      });
  }

  componentDidUpdate(prevProps) {
    const { selectedValue, items } = this.props;
    (prevProps.selectedValue === "" || prevProps.selectedValue === undefined) &&
      selectedValue &&
      selectedValue.length > 0 &&
      items &&
      !items.some(({ isSelected }) => isSelected) &&
      items.forEach(({ selectEntryEntryValue, _setInfo }) => {
        selectEntryEntryValue ===
          (typeof selectedValue === "object"
            ? selectedValue.find(el => el === selectEntryEntryValue)
            : selectedValue) && _setInfo("isSelected")(true);
      });
  }

  @observable
  filterValue = "";

  @action
  onChangeFilterValue = value => {
    this.filterValue = value;
  };

  @action
  setAllCheck = () => {
    const isCheck = this.allCheck;
    this.props.items && this.filteredItems.forEach(info => info.setInfo("isSelected", !isCheck));
  };

  @computed
  get allCheck() {
    return (
      this.props.items &&
      this.filteredItems.length > 0 &&
      this.filteredItems.every(info => info.isSelected)
    );
  }

  @computed
  get filteredItems() {
    const { items, info } = this.props;

    if (info.isUseSelectEntryFilterFlag && items) {
      return items.filter(
        ({ selectEntryDisplayName }) =>
          selectEntryDisplayName && selectEntryDisplayName.indexOf(this.filterValue) > -1
      );
    } else {
      return items;
    }
  }

  _renderItems = () => {
    const { info } = this.props;

    const { isUseImageFlag } = info;
    return this.filteredItems
      ? this.filteredItems.map(
          ({
            selectEntryDisplayName,
            selectEntryImageUri,
            selectEntryImageClass,
            uiId,
            isSelected,
            toggleFlag,
          }) => {
            return (
              <DropdownItem tag="li" key={uiId}>
                <a className="p-tb-3">
                  <CheckBox isChecked={isSelected} onToggle={() => toggleFlag("isSelected")} />
                  <SearchFilterName
                    isUseImageFlag={isUseImageFlag}
                    selectEntryDisplayName={selectEntryDisplayName}
                    selectEntryImageUri={selectEntryImageUri}
                    selectEntryImageClass={selectEntryImageClass}
                  />
                </a>
              </DropdownItem>
            );
          }
        )
      : null;
  };

  _selectHandler = () => {
    const { selectHandler, toggleDropdown } = this.props;
    selectHandler();
    toggleDropdown();
  };

  render() {
    const {
      defaultValue,
      dropdownOpen,
      toggleDropdown,
      info,
      dropdownClassName,
      arrowClassName,
      dropDownStyle,
    } = this.props;

    return (
      <DropGroup dropdownClassName={dropdownClassName} style={dropDownStyle}>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle tag="div">
            <a className="c-dark" style={{ cursor: "pointer" }}>
              <span>{defaultValue.name}</span>
              <i className={`fa fa-angle-down p-l-10 ${arrowClassName}`} />
            </a>
          </DropdownToggle>
          <DropdownMenu className="f-12">
            <ul>
              <DropdownItem tag="li" style={{ listStyle: "none" }}>
                <a className="p-tb-3">
                  <CheckBox isChecked={this.allCheck} onToggle={this.setAllCheck} />
                  {defaultValue.name}
                </a>
              </DropdownItem>
              {info.isUseSelectEntryFilterFlag && (
                <li style={{ listStyle: "none" }}>
                  <a className="p-tb-3" style={{ listStyle: "none", paddingLeft: 0 }}>
                    <Input.Text
                      className="form-white"
                      placeholder={`${defaultValue.name} ${languageSet("필터")}`}
                      value={this.filterValue}
                      onChange={this.onChangeFilterValue}
                    />
                  </a>
                </li>
              )}
            </ul>
            <ul className="y_auto max_h200 drop-menu-2x">
              <Observer render={this._renderItems} />
            </ul>
            <span className="divider dis-block" />
            <span className="dis-block t-center m-b-5">
              <a className="cursor_p" onClick={this._selectHandler}>
                <span className="label bg-black f-11 c-white">{languageSet("적용")}</span>
              </a>
            </span>
          </DropdownMenu>
        </Dropdown>
      </DropGroup>
    );
  }
}

ListFilterMultiDropdown.propTypes = {
  defaultValue: PropTypes.object,
  items: Mobx_PropTypes.arrayOrObservableArray,
};

ListFilterMultiDropdown.defualtProps = {
  items: [],
};

export default ListFilterMultiDropdown;
