import axiosModules from "@src/utils/config/axiosSetting";

export default {
  // index > 도메인 검색
  getIndexDomainInfo: (domain: string): Promise<any> => {
    return axiosModules.get("/index/domain/info", {
      domain,
    });
  },

  //index > 접속 IP 조회
  getIndexClientIp: (): Promise<any> => {
    return axiosModules.get("/index/getip");
  },
};
