import { observable, action } from "mobx";

import { withLoading, dateUtil, UserException } from "@qubit-utill/src";

import { dashboardApis } from "@src/apis";
import { languageSet } from "@src/utils";
import { ListCondition } from "@src/utils/store";
import DashboardModel from "@src/pages/Dashboard/model/DashboardModel";

type ContextMenuItemType = {
  text: string;
  onClick: Function | null;
  useDashBar?: boolean;
  isSubMenu?: boolean;
  subMenuItems?: Array<ContextMenuItemType> | null;
};

class DashboardStore extends ListCondition {
  constructor(root) {
    super();

    this.root = root;

    const _sessionStorageParam = sessionStorage.getItem(this.sessionStorageKey);
    const sessionStorageParam = _sessionStorageParam && JSON.parse(_sessionStorageParam);

    if (sessionStorageParam) {
      this.params = { ...this.params, ...sessionStorageParam };
    }
  }

  @observable
  root;

  @observable
  tableList: Array<DashboardModel> = [];

  @observable
  summaryList: Array<{ code: string; count: number; option: string }> = [];

  @observable
  summaryListLength: number = 0;

  @observable
  vwcContextMenuList: Array<ContextMenuItemType> = [];

  @observable
  vwcCheckUrl: string = "";

  @observable
  vwcCheckMenuList: Array<{ key: string; name: string; isChecked: boolean }> = [];

  @observable
  intervalId: any;

  @observable
  playFlag: boolean = true;

  @observable
  updatedDate: string = "";

  @observable
  selectedTab: string = "";

  intervalSeconds: number = 120;

  @observable
  isTotalLoading: boolean = false;

  @observable
  isModalLoading: boolean = false;

  @observable
  modalData: string = "";

  @observable
  speedUnit: string = "ms";

  @observable
  sizeUnit: string = "bytes";

  sessionStorageKey = "dashboard";

  @observable
  isOverPlatinum: boolean = false;

  callback = () => {};

  @action
  setSelectedTab = (tab: string) => {
    this.selectedTab = tab;
  };

  @action
  setSessionStorage = (param) => {
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(param));
  };

  @action
  getList = async () => {
    try {
      const { list, totalCount } = await dashboardApis.getDashboardList(this.params);
      this.tableList = list.map((info) => new DashboardModel(info));
      this.totalCount = totalCount;
      this.updatedDate = dateUtil.getDateTimeByForm(new Date());
      await this.getDashboardSummaryList();

      const saveParam = { onlyWarning: this.params.onlyWarning };
      this.setSessionStorage(saveParam);
    } catch (err) {
      this.tableList = [];
      this.totalCount = 0;
      return err;
    } finally {
      this.isTotalLoading = true;
    }
  };

  @action
  getDashboardSummaryList = async () => {
    try {
      const { list } = await dashboardApis.getDashboardSummaryList();
      const color = {
        0: "red",
        1: "orange",
        2: "green",
      };
      this.summaryList =
        list && list.map(({ child }, index) => child.map((childValue) => ({ ...childValue, color: color[index] }))).flat();
      this.summaryListLength = list.length;
    } catch (err) {
      this.summaryList = [];
      this.summaryListLength = 0;
      return err;
    }
  };

  @action
  dashBoardClearInterval = () => {
    clearInterval(this.intervalId);
    this.intervalTimer(() => {});
    this.timerController("true");
  };

  @action
  timerController = (playFlag?) => {
    if (playFlag) {
      if (playFlag === "true") {
        clearInterval(this.intervalId);
        this.playFlag = true;
      } else {
        this.playFlag = false;
      }
    } else {
      this.playFlag = !this.playFlag;
    }

    if (this.playFlag) {
      this.intervalTimer(this.callback);
    } else {
      clearInterval(this.intervalId);
    }
  };

  @action
  intervalTimer = (callback?) => {
    if (callback) {
      this.callback = callback;
    }
    this.intervalId = setInterval(() => {
      callback();
    }, 1000);
  };

  @action
  getSelectedDomainIndex = (domainId) => {
    return this.tableList.findIndex((info) => info.domainId === domainId);
  };

  @action
  getDnsExpiryInfo = async (domainId) => {
    try {
      const domainIndex = this.getSelectedDomainIndex(domainId);
      const { info } = await dashboardApis.getDnsExpiryInfo({ domainId });
      this.tableList[domainIndex].dns = info;
    } catch (err) {
      return err;
    }
  };

  @action
  getSslExpiryInfo = async (domainId) => {
    try {
      const domainIndex = this.getSelectedDomainIndex(domainId);
      const { info } = await dashboardApis.getSslExpiryInfo({ domainId });
      this.tableList[domainIndex].ssl = info;
    } catch (err) {
      return err;
    }
  };

  // 단일 정보 조회
  @withLoading("isModalLoading")
  @action
  getContextMenuInfo = async (getApi, domainId, url?) => {
    try {
      const { info } = await getApi({ domainId }, url);
      return (this.modalData = info.result);
    } catch ({ data }) {
      throw new UserException(data.message);
    }
  };

  // 다중 정보 조회 (체크박스)
  @withLoading("isModalLoading")
  @action
  getContextMenuMultiInfo = async (domainId) => {
    try {
      let type = "";
      const checkedList = this.vwcCheckMenuList.filter(({ isChecked }) => isChecked);
      const isAllCheck = this.vwcCheckMenuList.length === checkedList.length;

      if (isAllCheck) {
        type = "allCheck";
      } else {
        checkedList.forEach(({ key }, index) => (type = type + (index > 0 ? "," : "") + key));
      }

      const url = this.vwcCheckUrl + type;
      await this.getContextMenuInfo(dashboardApis.getVwcInfo, domainId, url);
    } catch ({ data }) {
      throw new UserException(data.message);
    }
  };

  @action
  getPopoverText = (nullValue, dataList, useCustomPopover = false) => {
    if (nullValue === null) return "";

    const title = {
      try: languageSet("시도"),
      update: languageSet("갱신"),
      create: languageSet("등록일"),
      expiry: languageSet("만료일"),
      ip: languageSet("IP주소"),
      title: languageSet("타이틀"),
      meta: languageSet("메타"),
    };

    const formatList = dataList.map(({ type, data }) => {
      if (type === "try") data = `${data.failCount}/${data.tryCount}`;

      if (type === "ip") {
        if (data && data.length > 0) {
          return data.map((ip, index) => (index < 1 ? `${title[type]} : ${ip}` : ip));
        }
      }

      if (data) return `${title[type]} : ${data}`;
    });

    const popoverList = formatList.filter(Boolean);
    return useCustomPopover ? popoverList.flat() : popoverList.join("\n");
  };

  @action
  getVwcList = async () => {
    try {
      await this.root.LicenseCodeStore.getLicenseInfo();
      this.isOverPlatinum = this.root.LicenseCodeStore.info.isOverPlatinum;

      const { list } = await dashboardApis.getVwcList();
      this.vwcContextMenuList = list.map(({ name, value, url, childList }) => {
        const subMenuItems = childList
          ? childList.map(({ key, name, value: subValue, url: subUrl }) => {
              let subMenuModalTitle = this.isOverPlatinum ? value : `${value} > ${subValue}`;
              return {
                text: name,

                onClick: () => {
                  if (this.isOverPlatinum) {
                    this.setVwcCheckMenuList(childList, key);
                    this.vwcCheckUrl = url;
                  }

                  this.contextCallback(subMenuModalTitle, dashboardApis.getVwcInfo, subUrl, true);
                },
              };
            })
          : null;
        return {
          text: name,
          onClick: url
            ? () => {
                this.vwcCheckMenuList = [];
                this.vwcCheckUrl = "";
                this.contextCallback(value, dashboardApis.getVwcInfo, url);
              }
            : null,
          isSubMenu: Boolean(childList),
          subMenuItems: subMenuItems,
        };
      });
    } catch ({ data }) {
      this.vwcContextMenuList = [];
      return data.message;
    }
  };

  @action
  setVwcCheckMenuList = (list, selectedValue) => {
    this.vwcCheckMenuList = list
      .filter(({ key }) => key !== "allCheck")
      .map(({ key, name }) => {
        return { key, name, isChecked: selectedValue === "allCheck" ? true : selectedValue === key };
      });
  };

  @action
  vwcCheckToggle = (index, isChecked) => {
    return (this.vwcCheckMenuList[index].isChecked = !isChecked);
  };

  @action
  contextCallback = (title, getApi, url?, isSubMenu?) => {};

  @action
  setContextCallback = (callback) => {
    this.contextCallback = callback;
  };
}

export default DashboardStore;
