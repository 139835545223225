import React from "react";
import { observer } from "mobx-react";

import { languageSet, useInjectStore } from "@qubit-utill/src";

const CustomPopover = () => {
  const { PopoverStore } = useInjectStore();
  return (
    <ul
      style={{
        top: "20px",
        right: "20px",
        position: "fixed",
        width: "310px",
        height: "auto",
        margin: "0px",
        padding: "0px",
        listStyleType: "none",
        zIndex: "10000000",
      }}
    >
      <li className="made noty_container_type_success animated bounceIn">
        <div className="noty_bar noty_type_success">
          <div className="noty_message">
            <span className="noty_text">
              <div
                className={`alert media fade ${PopoverStore.isFade ? "in" : "out"} alert-success`}
              >
                <p>
                  <strong>{languageSet("메세지_복사되었다")}</strong>
                  <br />
                  <span className="f-12">
                    {languageSet("메세지_원하는곳에마우스우측버튼으로붙여넣기해라")}
                  </span>
                </p>
              </div>
            </span>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default observer(CustomPopover);
