const languageJa = {
  가능: "可能",
  가입인증이_완료되었습니다: "登録認証が完了しました。",
  가입인증처리: "登録認証処理",
  가입인증처리_하시겠습니까: "登録認証処理しますか？",
  가입일: "登録日",
  개인정보: "個人情報",
  개인정보_수집_이용: "個人情報収集/利用",
  개인정보_처리방침: "プライバシー 処理方針",
  갱신: "更新",
  경고: "警告",
  계정생성: "アカウント作成",
  관리: "管理",
  권한: "権限",
  그래프: "グラフ",
  그룹고유키: "グループ固有のキー",
  나의_정보: "マイ情報",
  님: "様",
  단위: "単位",
  대시보드: "ダッシュボード",
  더보기: "もっと見る",
  도메인: "ドメイン",
  도메인_관리: "ドメイン管理",
  도메인_등록: "ドメイン登録",
  도메인_만료: "ドメイン満了",
  도메인_수정: "ドメイン変更",
  도메인_확인_버튼을_클릭해주세요: "ドメイン確認ボタンをクリックしてください。",
  도움말: "ヘルプ",
  동의: "同意",
  등록일: "登録日",
  라이선스: "ライセンス",
  라이선스_기간: "ライセンス期間",
  라이선스_코드: "ライセンスコード",
  라이선스_코드_관리: "ライセンスコード管理",
  라이선스_코드_생성: "ライセンスコード生成",
  라이선스_코드_생성되었습니다: "ライセンスコードが生成されました。",
  라이선스_코드_히스토리: "ライセンス コードヒストリー",
  로그아웃: "ログアウト",
  로그인: "ログイン",
  마지막_점검: "最後の点検",
  만료: "満了",
  만료일: "満了日",
  메세지_라이선스_만료안내: "ライセンスが満了しました。 新規ライセンスコードを入力してください",
  메세지_로그인해주세요: "ログインしてください。",
  메세지_만료_n일전: "満了%日前",
  메세지_만료_n일전입니다: "満了%日前です。",
  메세지_변경한비밀번호로다시로그인해라: "変更したパスワードで再度ログインしてください。",
  메세지_변경한PIN번호로다시로그인해주세요: "変更したPIN番号に再度ログインしてください。",
  메세지_삭제완료: "削除されました。",
  메세지_삭제하쉴: "削除しますか？",
  메세지_수정완료: "修正されました。",
  메세지_신규라이선스코드_입력안내: "新規ライセンスコード入力時に更新され、以前のライセンスコードは満了処理されます。",
  메세지_요일선택해주세요: "曜日を選択してください。",
  메세지_인증메일_버튼을_클릭하시면_회원가입이_완료됩니다: "確認メールを受信すると、会員登録が完了します。",
  메세지_인증메일_재발송: "認証メールが再送信されました。\n新しく送信された認証メールを確認してください。",
  메세지_입력하신이메일로인증메일발송되며인증되야회원가입완료이다:
    "入力したメールに認証メールが送信され、認証完了時に会員登録されます。",
  메세지_초대하였습니다: "招待しました。",
  메세지_초대할사람이메일입력하라: "招待するメールアドレスを入力してください。",
  메세지_핀번호를_입력하세요: "PIN番号を入力してください。",
  메세지_항목선택: "項目を選択してください。",
  메세지_해킹의심경고: "ハッキングの疑い警告",
  메세지_회원가입완료되었다: "会員登録が完了しました。",
  메세지_회원가입한_이메일로_인증메일이_발송되었습니다: "登録したメールアドレスに確認メールが送信されました。",
  메세지_n개선택했습니다: "%個が選択されました。",
  메타: "メタ",
  멤버: "メンバー",
  멤버수: "メンバー数",
  미사용: "未使用",
  바로가기: "ショートカット",
  발생: "発生",
  번: "番",
  범위: "範囲",
  불가능: "不可能",
  비밀번호: "パスワード",
  비밀번호_변경: "パスワード変更",
  비밀번호_입력: "パスワード入力",
  비밀번호_재입력: "パスワード再入力",
  사용: "使用",
  삭제: "削除",
  삭제_가능한_항목이_없습니다: "削除可能な項目がありません。",
  상태: "状態",
  상태값: "ステータス値",
  새_비밀번호: "新しいパスワード",
  새_비밀번호_확인: "新しいパスワード確認",
  새_PIN번호: "新しいPIN番号",
  새_PIN번호_확인: "新しいPIN番号確認",
  새로고침: "更新",
  새창보기: "新しいウィンドウを表示",
  생성: "生成",
  생성일: "生成日",
  생성자: "生成者",
  선택_취소: "選択解除",
  속도: "速度",
  수정: "修正",
  수정할_회사명_입력: "変更する会社名を入力",
  시도: "試し",
  아이디_이메일: "ユーザ名（メールアドレス）",
  아이디_이메일_입력: "ユーザ名（メールアドレス）を入力",
  알림: "通知",
  에러_네트워크: "ネットワークエラーです。",
  에러_데이터가없습니다: "データがありません。",
  에러_도메인검색_n시도횟수초과: "一日最大%回照会することができます。\n明日またご利用ください。",
  에러_라이선스코드를_입력해_주세요: "ライセンスコードを入力してください。",
  에러_비밀번호를_입력해_주세요: "パスワードを入力してください。",
  에러_비밀번호를입력해주세요: "パスワードを入力してください。",
  에러_비밀번호일치하지않는다: "パスワードが一致しません。",
  에러_빈값을입력하세요: "入力しながった情報があります。情報を入力してください。",
  에러_아이디를입력해주세요: "IDを入力してください。",
  에러_이메일입력해라: "メールアドレスを入力してください。",
  에러_이용약관에동의해라: "利用規約に同意してください。",
  에러_정보를입력해라: "情報を入力してください。",
  에러_회사명입력해라: "会社名を入力してください。",
  에러_PIN번호가_일치하지_않습니다: "PIN番号が一致しません。",
  오늘접속: "今日接続",
  완료일: "完了日",
  원인: "原因",
  웹사이트_위변조: "ウェブサイト偽変造",
  유효기간: "有効期間",
  응답시간: "応答時間",
  응답크기: "応答サイズ",
  의문문_수정하시겠습니까: "修正しますか?",
  이메일: "メール",
  이메일_입력: "メール入力",
  이용약관: "利用規約",
  인증메일_재발송: "認証メール再送",
  인증메일을_재발송하시겠습니까: "認証メールを再送しますか？",
  인증메일이_발송되었습니다: "認証メールが送信されました。",
  인증상태: "認証状態",
  인증서: "認証書",
  인증서_만료: "認証書満了",
  일_전: "日前",
  입력되지_않은_정보_다시_시도: "入力されていない情報があります。\n確認後、もう一度お試しください。",
  재발송: "再送",
  점검: "点検",
  접속일: "接続日",
  직접입력: "直接入力",
  찾기: "検索",
  체험: "体験",
  초기화: "初期化",
  초대: "招待",
  총: "合計",
  총게시물: "総投稿",
  총접속: "総アクセス",
  최근업데이트: "最近の更新",
  최근접속일: "最近接続日",
  최대: "最大",
  최소: "最小",
  취소: "キャンセル",
  취소하시겠습니까_목록으로_이동됩니다: "キャンセルしますか？\n(確認クリックすると保存されず、リストに移動されます。)",
  코드: "コード",
  타이틀: "タイトル",
  태그: "タグ",
  현재_비밀번호: "現在のパスワード",
  현재_PIN번호_입력: "現在のPIN番号を入力",
  확인: "確認",
  회사: "会社",
  회사명: "会社名",
  회사별_관리: "会社別の管理",
  회사소개: "会社紹介",
  회원_관리: "会員管理",
  회원가입: "会員登録",
  회원고유키: "会員固有のキー",
  DNS_스푸핑: "DNSスプーフィング",
  DNS_br_스푸핑: "DNS<br/>スプーフィング",
  IP주소: "IPアドレス",
  n_개_항목_삭제_하시겠습니까: "%個項目を削除しますか?",
  n개_모두_선택: "%個すべてを選択",
  n개_항목: "%個項目",
  n일: "%日",
  n일_전: "%日前",
  PIN번호: "PIN番号",
  PIN번호_변경: "PIN番号変更",
  PIN번호_입력: "PIN番号を入力",
  PIN번호_재입력: "PIN番号再入力",
  PIN번호_찾기: "PIN番号検索",
  SSL_만료: "SSL満了",
};
export default languageJa;
