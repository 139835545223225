/**
 * checurl에서 사용하는 date 관련
 */

import { dateUtil, formatConstants } from "@qubit-utill/src";

export default {
  defaultDateFormat(date: string) {
    return date ? date.split("T")[0] : date;
  },
  defaultDateTimeFormat(date: string) {
    return date ? date.replace("T", " ") : date;
  },
  defaultDateTimeToLocalTime(date: string, isOnlyDate: boolean = false) {
    if (!date) return;
    const _date = new Date(this.defaultDateTimeFormat(date));

    const year = _date.getFullYear();
    const month = _date.getMonth();
    const day = _date.getDate();
    const hour = _date.getHours();
    const min = _date.getMinutes();
    const sec = _date.getSeconds();

    const utcToLocalTime = new Date(Date.UTC(year, month, day, hour, min, sec));
    const formatDate = dateUtil.format(
      utcToLocalTime,
      isOnlyDate ? formatConstants.DATEFORMAT : formatConstants.DATEFORMATWITHTIME
    );

    return formatDate;
  },
};
