import React from "react";
import PropTypes from "prop-types";
import { ModalFooter, ModalBody, Modal, Button } from "reactstrap";
import { inject, observer } from "mobx-react";

import { Buttons, StringFormat } from "@qubit-utill/src/components/atoms";
import { path } from "@qubit-utill/src/common_constants";
import { languageSet } from "@qubit-utill/src";
import Draggable from "react-draggable";

const CommonModal = ({ modals, uuidKey }) => {
  const {
    title,
    clickOk,
    largeModal,
    children: Component,
    childrenParams,
    closeModal,
    isUseCancelBtn,
    isUseOkBtn,
    message,
    subMessage,
    isLoading,
    titleBg,
    customBtnTitle,
    isUseCustomBtn,
    logOrigin,
    clickCustom,
    color,
    scrollable = false,
    className,
    customIcon,
    iconColor,
  } = modals[uuidKey];

  let bsSize = largeModal ? { bsSize: "lg", className: "modal-lg" } : true;
  let isScrollable = scrollable
    ? {
        maxHeight: "calc(100vh - 250px)",
        overflowY: "auto",
      }
    : null;

  return (
    <Draggable handle=".modal-header">
      <Modal {...bsSize} isOpen={true} fade={false} toggle={closeModal} className={className}>
        <div className={`modal-header ${titleBg}`}>
          <Button className="close" onClick={closeModal}>
            <i
              className={
                customIcon ? customIcon : `icons-office-52 ${iconColor ? iconColor : "c-white"}`
              }
            />
          </Button>
          <h4 className="modal-title w-600" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <ModalBody className="p-b-0" style={isScrollable}>
          {message && (
            <div className="p-l-20 p-r-20">
              <p className={`f-16 w-600 ${subMessage ? "m-b-0" : ""}`}>{StringFormat(message)}</p>
              {subMessage && (
                <p className="f-14">
                  <span>{subMessage}</span>
                </p>
              )}
            </div>
          )}
          {logOrigin && (
            <div className="p-l-20 p-r-20">
              <span className="word-wrap f-12">{logOrigin}</span>
            </div>
          )}
          {isLoading && (
            <div className="t-center">
              <img src={`${path.CDNURL}/preloader/2.gif`} height="50px" alt="load_img" />
            </div>
          )}
          {Component && <Component {...childrenParams} />}
        </ModalBody>
        <ModalFooter>
          {isUseCustomBtn && (
            <Buttons.Embossed color="dark" text={customBtnTitle} onClick={clickCustom} />
          )}
          {isUseOkBtn && (
            <Buttons.Embossed color={color} text={languageSet("확인")} onClick={clickOk} />
          )}
          {isUseCancelBtn && (
            <Buttons.Embossed color="default" text={languageSet("취소")} onClick={closeModal} />
          )}
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

CommonModal.propTypes = {
  uuidKey: PropTypes.string.isRequired,
};

export default inject(({ ModalStore }) => ({
  modals: ModalStore.modals,
}))(observer(CommonModal));
