import React from "react";
import { observer } from "mobx-react";
import { useLicenceCheck } from "../../../hooks";

interface Props {
  licenseCode: string | string[];
}

const LicenseCheckRenderWrap: React.FC<Props> = ({ children, licenseCode }) => {
  const { isRender } = useLicenceCheck(licenseCode);
  if (isRender) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default observer(LicenseCheckRenderWrap);
