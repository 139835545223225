import React, { Fragment } from "react";
import { Table } from "reactstrap";
import { observer, Observer } from "mobx-react";

import { languageSet } from "@qubit-utill/src";

import TableLoading from "../Loading/TableLoading";
import ThBuilder from "./ThBuilder";
import { ThFieldsConfig } from "../../../store";
import { useTableSort, useTableCheck } from "../../../hooks";

interface PropsTypes {
  tableList: any[];
  colorTheme: string;
  isSubLoading: boolean;
  isLoading: boolean;
  tableBordered: boolean;
  trType: React.ElementType;
  viewType: React.ElementType;
  offset: number;
  thFields: ThFieldsConfig;
  useIndex: boolean;
  isUseSetting: boolean;
  totalCount: number;

  subInfo?: any;
  tableClassName?: string;
  isView?: boolean;
  isUseCheck?: boolean;
  store?: any;
  subStore?: any;
  className?: string;
}

const TableWithView: React.FC<PropsTypes> = ({
  tableList = [],
  colorTheme,
  isSubLoading,
  tableBordered,
  isUseCheck,
  isView,
  isLoading,
  className = "",
  tableClassName = "",
  trType: Tr,
  viewType: ViewComponent,
  store,
  subStore,
  offset,
  subInfo,
  thFields,
  useIndex,
  isUseSetting,
  totalCount,
}) => {
  const [trSelected, setTrselected] = React.useState(-1);

  React.useEffect(() => {
    if (store) {
      const getList = store.getList;
      store.getList = (value: any) => {
        setTrselected(-1);
        getList(value);
      };
    }

    if (subStore) {
      const getList = subStore.getSubList;
      subStore.getSubList = (value: any) => {
        setTrselected(-1);
        getList(value);
      };
    }
  }, []);

  const toggleView = (trId: number) => {
    setTrselected(trSelected === trId ? -1 : trId);
    return trSelected === trId;
  };

  const _renderTrViewWrap: any = () => {
    if (_tableList.length > 0) {
      return _tableList.map((info, index) => {
        const activeClassName = `${trSelected === index ? "bg-yellow-re" : ""} cursor_p`;
        return (
          <Fragment key={info.uiId}>
            <Tr
              info={info}
              isView={isView}
              toggleView={toggleView}
              index={index}
              offset={offset}
              trSelected={trSelected}
              totalCount={totalCount}
              activeClassName={activeClassName}
              thFields={thFields}
            />
            {trSelected === index && (
              <TableLoading isLoading={isSubLoading} minHeight={200}>
                <tr>
                  <ViewComponent
                    toggleView={toggleView}
                    trKey={index}
                    isView={isView}
                    trSelected={trSelected}
                    info={info}
                    store={store}
                    {...subInfo}
                  />
                </tr>
              </TableLoading>
            )}
          </Fragment>
        );
      });
    } else {
      return (
        <tr key={"no_data"}>
          <td className="dataTables_empty h-300 t-center f-13" colSpan={100}>
            {languageSet("에러_데이터가없습니다")}
          </td>
        </tr>
      );
    }
  };

  React.useEffect(() => {
    toggleView(-1);
  }, [thFields.selectedSort, thFields.sortType]);

  const { _tableList } = useTableSort({ tableList, thFields });
  const { isAllCheck, toggleAllCheck } = useTableCheck(tableList);
  return (
    <Table
      className={`f-13 border-bottom table-hover ${className} ${tableClassName}`}
      bordered={tableBordered}
    >
      <ThBuilder
        thFields={thFields}
        toggleAllCheck={toggleAllCheck}
        isAllCheck={isAllCheck}
        colorTheme={colorTheme}
        isUseCheck={isUseCheck}
        useIndex={useIndex}
        isUseSetting={isUseSetting}
      />
      <tbody>
        <TableLoading minHeight={300} isLoading={isLoading}>
          <Observer render={_renderTrViewWrap} />
        </TableLoading>
      </tbody>
    </Table>
  );
};

export default observer(TableWithView);
