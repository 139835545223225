import { observable, action } from "mobx";
import uuid from "uuid/v1";
import ModalModel from "../model/ModalModel";
import { languageSet } from "@qubit-utill/src";

interface ModalParamType {
  title?: string;
  message: string;
  okCallBack?: Function;
  subMessage?: string;
  closeCallBack?: Function;
  color?: string;
  scrollable?: boolean;
}

const TITLEBG = "bg-dark";
const BTNCOLOR = "dark";

class ModalStore {
  @observable
  titleBg: string = TITLEBG;
  @observable
  color: string = BTNCOLOR;

  @observable
  modals = {};

  @action
  createNewModal = modal => {
    const newModal = new ModalModel({ titleBg: this.titleBg, color: this.color, ...modal });
    const uuidKey = uuid();
    newModal.uuidKey = uuidKey;
    this.modals = {
      ...this.modals,
      [uuidKey]: newModal,
    };

    return uuidKey;
  };

  @action
  openLoadingModal = () =>
    this.createNewModal({
      isLoading: true,
      isUseCancelBtn: false,
      isUseOkBtn: false,
    });

  @action
  openConfirmModal = ({ title, message, okCallBack, subMessage, color }: ModalParamType) =>
    this.createNewModal({
      title: title || languageSet("알림"),
      message,
      subMessage,
      okCallBack,
      isUseCancelBtn: true,
      isUseOkBtn: true,
      color,
    });

  @action
  openAlertModal = ({ title, message, okCallBack, closeCallBack, subMessage }: ModalParamType) =>
    this.createNewModal({
      title: title || languageSet("알림"),
      message,
      okCallBack,
      closeCallBack,
      isUseOkBtn: true,
      subMessage,
    });

  @action
  customModal = ({
    title,
    message,
    okCallBack,
    isUseCancelBtn,
    isUseOkBtn,
    children,
    scrollable,
    largeModal,
    customAttribute,
    className,
  }) =>
    this.createNewModal({
      title,
      message,
      okCallBack,
      isUseCancelBtn,
      isUseOkBtn,
      children,
      scrollable,
      largeModal,
      customAttribute,
      className,
    });

  //todo createSuccessModal closeCallBack 사용 안하는중
  @action
  createSuccessModal = (okCallBack: Function, closeCallBack: Function) => {
    const message: string = languageSet("메세지_등록완료") as string;
    this.openAlertModal({ message, okCallBack });
  };

  @action
  updateSuccessModal = (okCallBack, message) =>
    this.openAlertModal({ message: message || languageSet("메세지_수정완료"), okCallBack });

  @action
  createFailModal = ({ okCallBack, message }) =>
    this.openAlertModal({ message: message || languageSet("메세지_등록실패"), okCallBack });

  @action
  updateFailModal = ({ okCallBack, message }) =>
    this.openAlertModal({ message: message || languageSet("메세지_수정실패"), okCallBack });

  @action
  deleteSuccessModal = okCallBack => {
    this.openAlertModal({ message: languageSet("메세지_삭제완료"), okCallBack });
  };

  @action
  deleteFailModal = okCallBack =>
    this.openAlertModal({ message: languageSet("메세지_삭제실패"), okCallBack });

  @action
  deleteConfirmModal = okCallBack =>
    this.openConfirmModal({
      message: languageSet("메세지_삭제확인"),
      okCallBack,
    });

  @action
  closeModal = uuidKey => {
    let newModals = {
      ...this.modals,
    };

    delete newModals[uuidKey];

    this.modals = newModals;
  };
}

export default new ModalStore();
