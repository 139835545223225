import languageSet from "@src/utils/lang/languageSet";
import getURI from "@src/constants/uriset";
import { LicenseCodeContainer } from "@src/pages";

export default {
  LICENSECODE: {
    link: getURI("LICENSECODE"),
    title: languageSet("라이선스_코드"),
    component: LicenseCodeContainer,
  },
};
