import uicode from "./uicode";
import indexlang from "./indexlang";
import licensecode from "./licensecode";
import dashboard from "./dashboard";
import domain from "./domain";
import management from "./management";
import userinfo from "./userinfo";

export default {
  ...uicode,
  ...indexlang,
  ...licensecode,
  ...dashboard,
  ...domain,
  ...management,
  ...userinfo,
};
