import { observable, action } from "mobx";

import { ModalStore, LoadingStore, SearchListFilterStore } from "@qubit-utill/src";

import { CookieStore, CodeStore } from "@src/utils/store";
import DashboardStore from "@src/pages/Dashboard/store/DashboardStore";
import DomainGraphStore from "@src/pages/Domain/store/DomainGraphStore";
import DomainStore from "@src/pages/Domain/store/DomainStore";
import LicenseCodeStore from "@src/pages/LicenseCode/stores/LicenseCodeStore";
import UserInfoStore from "@src/pages/UserInfo/stores/UserInfoStore";

class RootStore {
  constructor() {
    this.ModalStore = ModalStore;
    this.LoadingStore = LoadingStore;
    this.SearchListFilterStore = new SearchListFilterStore(this);
    this.CodeStore = new CodeStore();

    this.CookieStore = new CookieStore(this);
    this.DashboardStore = new DashboardStore(this);
    this.DomainGraphStore = new DomainGraphStore(this);
    this.DomainStore = new DomainStore();
    this.LicenseCodeStore = new LicenseCodeStore(this);
    this.UserInfoStore = new UserInfoStore(this);
  }

  @action
  getInitUserInfo = async () => {
    await this.LicenseCodeStore.getLicenseInfo(); //라이선스 정보
  };

  @observable
  ModalStore;

  @observable
  LoadingStore;

  @observable
  SearchListFilterStore;

  @observable
  CodeStore;

  @observable
  CookieStore;

  @observable
  DashboardStore;

  @observable
  DomainGraphStore;

  @observable
  DomainStore;

  @observable
  LicenseCodeStore;

  @observable
  UserInfoStore;
}

export default new RootStore();
