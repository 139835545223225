import React from "react";
import styled from "styled-components";

const DropGroupWrap = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
`;

const DropGroup = ({ dropdownClassName = "", children, style }) => {
  return (
    <DropGroupWrap className={`${dropdownClassName} cursor_p`} style={style}>
      {children}
    </DropGroupWrap>
  );
};

DropGroup.defaultProps = {
  dropdownClassName: "m-r-10",
  style: {},
};

export default DropGroup;
