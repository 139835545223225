import React from "react";
import { Button } from "reactstrap";

interface PropTypes {
  text?: string;
  color?: string;
  className?: string;
  disabled?: string;
  onClick?: Function;
}

const Embossed: React.FC<PropTypes> = ({ text, color, className, disabled, onClick }) => {
  return (
    <Button
      color={color}
      className={`btn-embossed ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default Embossed;
