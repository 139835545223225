import React from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import format from "date-fns/format";
import { languageSet } from "@qubit-utill/src";
import { formatConstants } from "../../../common_constants";

const WEEKDAYS_SHORT = [
  languageSet("일"),
  languageSet("월"),
  languageSet("화"),
  languageSet("수"),
  languageSet("목"),
  languageSet("금"),
  languageSet("토"),
];

const MONTHS = (() => {
  let arr = [];
  for (var i = 1; i <= 12; i++) {
    arr.push(languageSet("n월", i));
  }
})();

const WEEKDAYS_LONG = [
  languageSet("일요일"),
  languageSet("월요일"),
  languageSet("화요일"),
  languageSet("수요일"),
  languageSet("목요일"),
  languageSet("금요일"),
  languageSet("토요일"),
];

const DatePicker = ({
  size,
  inputWidth,
  onChange,
  onClick,
  value,
  dateFormat,
  maxDate,
  minDate,
  isUseIcon = false,
  disabled,
}) => {
  const onDayClick = (selectedDay, modifiers) => {
    !modifiers.disabled && onClick(selectedDay);
  };

  const onDayChange = (selectedDay, modifiers) => {
    !modifiers.disabled && onChange(selectedDay);
  };

  const formatDate = date => format(date, dateFormat);

  return (
    <DayPickerInput
      value={value}
      inputProps={{
        className: `b-datepicker form-control input-${size} cursor_p prepend-icon ${inputWidth} ${isUseIcon &&
          "p-l-30"}`,
        disabled: disabled,
      }}
      placeholder="Calendar"
      formatDate={formatDate}
      onDayChange={onDayChange}
      dayPickerProps={{
        selectedDays: value ? new Date(value) : null,
        onDayClick: onClick ? onDayClick : onDayChange,
        disabledDays: {
          before: minDate,
          after: maxDate,
        },
        months: MONTHS,
        weekdaysLong: WEEKDAYS_LONG,
        weekdaysShort: WEEKDAYS_SHORT,
      }}
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_SHORT}
    />
  );
};

DatePicker.propTypes = {
  size: PropTypes.string,
  dateFormat: PropTypes.string,
};

DatePicker.defaultProps = {
  dateFormat: formatConstants.DATEFORMAT,
  value: "",
  inputWidth: "w_180px",
  onChange: () => {},
};

export default DatePicker;
