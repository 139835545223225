import React, { useState } from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";

import { languageSet, dateUtil } from "@qubit-utill/src";
import { COOKIELANG, formatConstants } from "../../../common_constants";

import "react-day-picker/lib/style.css";

const WeekPicker = ({
  size,
  onChange,
  dateFormat,
  maxDate,
  minDate,
  value,
  className,
  inputClassName,
  placeholderText,
  dateTitleStyle,
}) => {
  const [days, setDays] = useState(dateUtil.getWeekDays(new Date(value)));
  const [hoverRange, setHoverRange] = useState(dateUtil.getWeekDays(new Date(value)));

  const _modifiers = () => {
    const daysAreSelected = days.length > 0;
    return {
      hoverRange: hoverRange && { from: hoverRange[0], to: hoverRange[6] },
      selectedRange: daysAreSelected && {
        from: days[0],
        to: days[6],
      },
    };
  };

  const handleDayEnter = date => {
    setHoverRange(dateUtil.getWeekDays(date));
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  const handleDayClick = (date, modifiers) => {
    !modifiers.disabled && setDays(dateUtil.getWeekDays(date));
    !modifiers.disabled && onChange(date, dateUtil.getWeekNumber(date));
  };
  const obj = {
    year: dateUtil.getWeekNumberByMonth(value).year,
    month: dateUtil.getWeekNumberByMonth(value).month,
    weekNo: dateUtil.getWeekNumberByMonth(value).weekNo,
  };

  return (
    <>
      <div className={`dis-inline-b ${className} bg-lighter`}>
        <DayPickerInput
          value={value}
          inputProps={{
            className: `b-datepicker form-control input-${size} cursor_p opacity-0 ${inputClassName}`,
            style: { position: "relative", zIndex: 2 },
          }}
          modifiers={_modifiers()}
          placeholderText={placeholderText}
          dayPickerProps={{
            modifiers: _modifiers(),
            showWeekNumbers: true,
            showOutsideDays: true,
            format: dateFormat,
            selectedDays: days,
            disabledDays: {
              before: minDate,
              after: maxDate,
            },
            locale: COOKIELANG,
            onDayClick: handleDayClick,
            onDayMouseEnter: handleDayEnter,
            onDayMouseLeave: handleDayLeave,
          }}
        />
        <span className="cursor_p" style={{ ...dateTitleStyle, position: "absolute", zIndex: 1 }}>
          {languageSet("연도월주차", obj.year, obj.month, obj.weekNo)}
        </span>
      </div>
    </>
  );
};

WeekPicker.propTypes = {
  size: PropTypes.string,
  dateFormat: PropTypes.string,
};

WeekPicker.defaultProps = {
  dateFormat: formatConstants.DATEFORMAT,
  value: "2019-10-12",
  onChange: () => {},
  placeholderText: "Show calendar...",
  className: "m-r-10",
  inputClassName: "",
  dateTitleStyle: { left: "24px", top: "8px" },
};

export default WeekPicker;
