const makeDecorator = defineValue => loadingProp => (_, property, descriptor) => {
  if (descriptor && descriptor.value) {
    //typescript method (){}
    //js method (){}
    return {
      enumerable: false,
      configurable: true,

      get() {
        const method = descriptor.value || descriptor.initializer.call(this);

        Object.defineProperty(this, property, {
          enumerable: false,
          writable: true,
          configurable: true,
          value: defineValue(this, loadingProp, method),
        });

        return this[property];
      },
    };
  } else if (descriptor && descriptor.initializer) {
    //js method ()=>{}
    function initializer() {
      return defineValue(this, loadingProp, descriptor.initializer.call(this));
    }

    return {
      enumerable: false,
      configurable: true,
      writable: true,
      initializer,
    };
  } else if (descriptor) {
    //js with action  bound method ()=>{}
    return {
      enumerable: false,
      configurable: true,
      get() {
        Object.defineProperty(this, property, {
          enumerable: false,
          writable: true,
          configurable: true,
          value: defineValue(this, loadingProp, descriptor.get.call(this)),
        });

        return this[property];
      },
    };
  } else {
    //typescript method ()=>{}
    return {
      enumerable: false,
      configurable: true,
      set(v) {
        Object.defineProperty(this, property, {
          enumerable: false,
          writable: true,
          configurable: true,
          value: defineValue(this, loadingProp, v.bind(this)),
        });
      },
      get() {
        return undefined;
      },
    };
  }
};

export default makeDecorator;
