import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const Minus = ({ size, color, className, children, onClick, disabled, fontSize }) => {
  const iSize = { fontSize }
  return (
    <Button size={size} color={color} onClick={onClick} className={className} disabled={disabled}>
      <i className={`fa fa-minus ${children ? "p-r-5" : ""}`} style={iSize} />
      {children}
    </Button>
  )
}

Minus.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fontSize: PropTypes.number
}

Minus.defaultProps = {
  size: "sm",
  color: "dark",
  disabled: false,
  fontSize: 12
}

export default Minus
