import React, { useMemo, useRef } from "react";
import { Observer, observer, useLocalStore } from "mobx-react";

import { languageSet, useIsToggle } from "@qubit-utill/src";
import Input from ".";
import WithToggle from "../../../hoc/WithToggle";
import { Common_Utils } from "@qubit-utill/src/utils";

const MonthPicker = ({ minDate, maxDate, onChange, value }) => {
  const newDate = new Date(value);
  const timeText = useMemo(
    () => `${newDate.getFullYear()}-${Common_Utils.pad2(newDate.getMonth() + 1)}`,
    [value]
  );
  const state = useLocalStore(() => ({
    year: newDate.getFullYear(),
    month: newDate.getMonth() + 1,
    setYear(value) {
      this.year = value;
    },
    setMonth(value) {
      this.month = value;
    },
  }));
  const toggle = useIsToggle();
  const targetRef = useRef(null);

  const buttonClass = (flag, selected) =>
    flag ? (selected ? "DayPicker-Day--selected" : "") : "DayPicker-Day--disabled";

  const _onClickMonth = (flag, month) => () => {
    if (flag) {
      toggle.setIsToggle();
      state.setMonth(month + 1);
      onChange(new Date(state.year, month));
    }
  };

  const _onClickYear = (flag, year) => () => {
    flag && state.setYear(year);
  };

  const _renderMonth = () => {
    let arr = [];
    for (let i = 0; i < 12; i++) {
      const d = new Date(state.year, i);
      const flag = d >= minDate && d <= maxDate;
      arr.push(
        <div
          onClick={_onClickMonth(flag, i)}
          className={`DayPicker-Day ${buttonClass(flag, state.month - 1 === i)} `}
          style={{ padding: "12px 1em" }}
        >
          {languageSet("n월", i + 1)}
        </div>
      );
    }
    return (
      <>
        <div className="DayPicker-Week">{arr.slice(0, 4)}</div>
        <div className="DayPicker-Week">{arr.slice(4, 8)}</div>
        <div className="DayPicker-Week">{arr.slice(8, 12)}</div>
      </>
    );
  };

  const _renderYear = () => {
    const prevFlag = state.year > minDate.getFullYear();
    const nextFlag = state.year < maxDate.getFullYear();
    return (
      <div className="DayPicker-NavBar">
        <span
          onClick={_onClickYear(prevFlag, state.year - 1)}
          className="DayPicker-NavButton DayPicker-NavButton--prev"
        />
        <span
          onClick={_onClickYear(nextFlag, state.year + 1)}
          className="DayPicker-NavButton DayPicker-NavButton--next"
        />
      </div>
    );
  };
  return (
    <div ref={targetRef} className="DayPickerInput w_180px" style={{ position: "relative" }}>
      <Input.Text
        onClick={toggle.setIsToggle}
        value={timeText}
        readOnly
        noColor
        size="sm"
        className="b-datepicker cursor_p"
      />
      {toggle.isToggle && (
        <div
          className="DayPickerInput-OverlayWrapper"
          style={{ position: "absolute", top: "30px", left: "0px" }}
        >
          <div className="DayPickerInput-Overlay w_180px">
            <div className="DayPicker">
              <div className="DayPicker-wrapper">
                <Observer render={_renderYear} />
                <div className="DayPicker-Months w_180px">
                  <div className="DayPicker-Month" role="grid">
                    <div className="DayPicker-Caption" role="heading">
                      <div>{state.year}</div>
                    </div>
                    <div className="DayPicker-Body">
                      <Observer render={_renderMonth} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(MonthPicker);
