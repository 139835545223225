import { observer } from "mobx-react";
import React from "react";

import PartialLoading from "./PartialLoading";

interface PropTypes {
  isLoading: boolean;
  minHeight?: number | string;
  height?: number;
}

const PartialLoadingWrapper: React.FC<PropTypes> = ({
  isLoading,
  children,
  minHeight = 300,
  height
}) => {
  if (isLoading) {
    return <>{children}</>;
  } else {
    return <PartialLoading minHeight={minHeight} height={height} />;
  }
};

export default observer(PartialLoadingWrapper);
