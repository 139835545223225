import { observable, action } from "mobx";

import { Common_Utils, UserException } from "@qubit-utill/src/utils";
import { withLoading } from "@qubit-utill/src/decorator";

import { languageApis, accountApis } from "@src/apis";
import { COOKIENAME, getURI, LANGCOOKIENAME, DEFAULTLANG } from "@src/constants";
import UserPasswordRuleModel from "@src/utils/model/UserPasswordRuleModel";

class CookieStore {
  constructor(root: any) {
    this.root = root;
  }
  root: any;

  @observable
  isLogin = false;

  @observable
  isRenderPasswordChangePage = false;

  @observable
  userPasswordRuleInfo: UserPasswordRuleModel = new UserPasswordRuleModel([]);

  @observable
  langList: Array<{ name: string; value: string }> = [];

  @withLoading("isLoading")
  @action
  getUserPasswordRule = async () => {
    try {
      const { list } = await accountApis.getAccountRegex();
      this.userPasswordRuleInfo = new UserPasswordRuleModel(list);
    } catch {
      this.userPasswordRuleInfo = new UserPasswordRuleModel([]);
    }
  };

  /**
   * PW/PIN 찾기 메일발송
   * @param params
   * @returns
   */
  @action
  createFindPwPinSendMail = async (params: { type: string; email: string }) => {
    try {
      await accountApis.createAccountFindPwPinSendMail(params);
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };

  /**
   * PW/PIN 찾기 코드확인
   * @param code
   * @returns
   */
  @action
  getAccountFindPwPinCheck = async (code: string) => {
    try {
      const { info } = await accountApis.getAccountFindPwPinCheck(code);
      return info;
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };

  /**
   * PW/PIN 찾기 변경
   * @param params
   * @returns
   */
  @action
  updateAccountFindPwPinChange = async (params: { code: string; key: string }) => {
    try {
      await accountApis.updateAccountFindPwPinChange(params);
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };

  @action
  checkIsLogin = () => {
    let cookieName = `${COOKIENAME}`;
    this.setIsLogin(Boolean(Common_Utils.checkCookie(cookieName)));
    return this.isLogin;
  };

  @action
  createLoginWeb = async (params: { email: string; password: string; pin: string }) => {
    try {
      await accountApis.createSignIn(params);

      this.checkIsLogin();
      if (this.isLogin) {
        //로그인 성공 시 회원정보관련 api 호출
        await this.root.getInitUserInfo();
      }
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };

  @action
  setIsLogin = (flag) => {
    this.isLogin = flag;
  };

  @action
  logout = () => {
    accountApis.createSignOut().then(() => {
      this.setIsLogin(false);
      window.location.replace(getURI("LOGIN"));
    });
  };

  @action
  checkLang = async () => {
    const cookieName = `${LANGCOOKIENAME}`;
    const cookie = Common_Utils.checkCookie(cookieName);
    if (Boolean(cookie)) {
      try {
        Common_Utils.lang = cookie[2] || DEFAULTLANG;
      } catch (e) {}
    } else {
      Common_Utils.lang = DEFAULTLANG;
    }
  };

  @action
  getLanguageList = async () => {
    try {
      const { list } = await languageApis.getLanguageList();
      this.langList = list;
    } catch (error) {
      this.langList = [];
    }
  };

  @action
  setLang = async (lang, isReload = true) => {
    try {
      await languageApis.createLanguageInfo(lang);
      if (isReload) {
        window.location.reload();
      } else {
        window.location.href = getURI("/");
      }
    } catch ({ message }) {}
  };
}

export default CookieStore;
