import { asyncComponent } from "@qubit-utill/src";

const TopBar = asyncComponent(() =>
  import(
    /* webpackChunkName: 'TopBar' */
    "@src/pages/TopBar/TopBar"
  )
);

const IndexKoContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'IndexKoContainer' */
    "@src/pages/Index/IndexKoContainer"
  )
);

const IndexJaContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'IndexJaContainer' */
    "@src/pages/Index/IndexJaContainer"
  )
);

const IndexEnContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'IndexEnContainer' */
    "@src/pages/Index/IndexEnContainer"
  )
);

const LicenseCodeContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'LicenseCodeContainer' */
    "@src/pages/LicenseCode/LicenseCodeContainer"
  )
);

const DashboardContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DashboardContainer' */
    "@src/pages/Dashboard/DashboardContainer"
  )
);

const DomainSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DomainSetContainer' */
    "@src/pages/Domain/DomainSetContainer"
  )
);

const DomainGraphContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DomainGraphContainer' */
    "@src/pages/Domain/DomainGraphContainer"
  )
);

const ManagementContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ManagementContainer' */
    "@src/pages/Management/ManagementContainer"
  )
);

const UserInfoContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'UserInfoContainer' */
    "@src/pages/UserInfo/UserInfoContainer"
  )
);

const UserInfoEditContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'UserInfoEditContainer' */
    "@src/pages/UserInfo/UserInfoEditContainer"
  )
);

const LoginContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'LoginContainer' */
    "@src/pages/Login/LoginContainer"
  )
);

const SignUpContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SignUpContainer' */
    "@src/pages/SignUp/SignUpContainer"
  )
);

const SignUpCertContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SignUpCertContainer' */
    "@src/pages/SignUp/SignUpCertContainer"
  )
);

const FindPwPinContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'FindPwPinContainer' */
    "@src/pages/Find/FindPwPinContainer"
  )
);

const FindChangePwContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'FindChangePwContainer' */
    "@src/pages/Find/FindChangePwContainer"
  )
);

const FindChangePinContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'FindChangePinContainer' */
    "@src/pages/Find/FindChangePinContainer"
  )
);

export {
  TopBar,
  IndexKoContainer,
  IndexJaContainer,
  IndexEnContainer,
  LicenseCodeContainer,
  DashboardContainer,
  DomainSetContainer,
  DomainGraphContainer,
  ManagementContainer,
  UserInfoContainer,
  UserInfoEditContainer,
  LoginContainer,
  SignUpContainer,
  SignUpCertContainer,
  FindPwPinContainer,
  FindChangePwContainer,
  FindChangePinContainer,
};
