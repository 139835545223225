import React from "react"
import PropTypes from "prop-types"
import { observer } from "mobx-react"

const CheckBox = ({ onToggle, disabled, color, type, isChecked, tag: TagComponent, className }) => {
  const _toggleChecked = e => {
    e.stopPropagation()
    e.preventDefault()
    if (!disabled) {
      onToggle && onToggle()
    }
  }
  const cursorStyle = disabled ? { cursor: "inherit" } : {}
  const typeColor = disabled ? "icheckbox_flat-aero" : `icheckbox_${type}-${color}`
  return (
    <TagComponent
      className={`${typeColor} ${isChecked && "checked"} ${className}`}
      style={{ position: "relative", ...cursorStyle }}
      onClick={_toggleChecked}
    >
      <input type="checkbox" style={{ position: "absolute", opacity: 0 }} value={isChecked} />

      <ins
        className={`iCheck-helper ${disabled ? "cursor_d" : "cursor_p"}`}
        style={{
          position: "absolute",
          top: "0%",
          left: "0%",
          display: "block",
          width: "100%",
          height: "100%",
          margin: 0,
          padding: 0,
          background: "rgb(255, 255, 255)",
          border: 0,
          opacity: 0
        }}
      />
    </TagComponent>
  )
}

CheckBox.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  tag: PropTypes.string,
  className: PropTypes.string
}

CheckBox.defaultProps = {
  type: "minimal",
  color: "blue",
  tag: "div",
  className: ""
}

export default observer(CheckBox)
