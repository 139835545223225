import { action, set, observable } from "mobx";
import uuid from "uuid/v1";

import Common_Utils from "../utils/utils";
import { languageSet } from "@qubit-utill/src";

class SetterModel {
  constructor(params?: any) {
    set(this, params);
    if (params) {
      this.registerDate = Common_Utils.dateFormat(params.registerDate);
      this.updateDate = Common_Utils.dateFormat(params.updateDate);

      if (params) {
        if (params.tagServicePropertyList) {
          params.tagServicePropertyList.forEach((info: any) => {
            for (const key in params) {
              if (key === info.tagServiceProperty) {
                //예외상황#1 시작
                //보안탐지 > 계정탈취 : '호스트' 의 경우 string 구조체 형식으로 되어 있어서 별도로 처리할수밖에 없음
                //보고서 > 계정탈취 분석 : '하단TOP 리스트' 의 경우 string 구조체 형식으로 되어 있어서 별도로 처리할수밖에 없음
                if(key === "detectHttpHost" || key === "detectHost") {
                  const _detectHttpHost = Common_Utils.setValueForMultiplePathHost(this[info.tagServiceProperty], "detectHttpHost")

                  _detectHttpHost.forEach(({detectHttpHost}) => {
                    this[key] = detectHttpHost
                  })
                }
                //예외상황#1 끝
                this.setTagIpListByProperty(info.tagServiceProperty, params.tagValueTypeList);
              }

              //예외상황#2 시작
              //보안탐지 > 상관분석 : 분석정보의 탐지 필터는 'detectLogTemplateList'의 구조체 안에 별도 정의되어 있음
              if(key === "detectLogTemplateList") {
                this[key] && this[key].forEach((data: any) => {
                  if(data.isDisplay) {
                    //weblogDetect 는 object
                    if(data.logType === "weblogDetect") {
                      for ( const key in data.detectLog) {
                        key === info.tagServiceProperty && this.setTagIpListByValue(data.detectLog[key], params.tagValueTypeList)
                      }
                    } else {
                      //syslogDetect 는 arrayObject
                      data.viewTemplate.forEach((data: any) => {
                        data.name === info.tagServiceProperty && this.setTagIpListByValue(data.value, params.tagValueTypeList)
                      })
                    }
                  }
                })
              }
              //예외상황#2 끝
            }

            //분석정보의 경우는 viewTemplate 배열에 있기 때문에 별도처리해줘야 함
            if(params.viewTemplate) {
              params.viewTemplate.forEach((data: any) => {
                if(data.name === info.tagServiceProperty) {
                  this.setTagIpListByValue(data.value, params.tagValueTypeList)
                }
              })
            }
          });

          const newTagIpList = [...new Set(this.tagIpList)];
          newTagIpList.forEach((tagIp, index) => this.setTagDataList(tagIp, params.getTagServiceDataList && params.getTagServiceDataList, index));

          if(params.getTagServiceDataListFn) {
            newTagIpList.forEach((info) => this.setTagInfo(info, params.getTagServiceDataListFn))
          }
        }
      }
    }
    this.uiId = uuid();
  }

  @action
  setTagInfo =  async (info: any, getTagServiceDataList: Function) => {

    const tagReferenceType = {
      db : languageSet("평판DB"),
      ctas: "CTAS",
      general: languageSet("일반"),
      plura: "PLURA"
    }

    try {
      const list = await getTagServiceDataList({tagValue: info});

      const _html = list && list.reduce((acc:any, cur:any) => {
        if(cur.isGlobal === "1") {
          if(cur.isUse === "1") {
            acc.general.length === 0 && acc.general.push(
              `<div class="line-h-15">[${languageSet("기본")}]</div>`
            )

            acc.general.push(
              `<div class="line-h-15"><i class="fa fa-caret-right p-r-5"></i>
                ${tagReferenceType[cur.tagReferenceType]} : ${cur.tagContent}
              </div>`
            )
          }
        } else {
          if(cur.isUse === "1") {
            acc.user.length === 0 && acc.user.push(
              `<div class="line-h-15">[${languageSet("사용자")}]</div>`
            )

            acc.user.push(
              `<div class="line-h-15"><i class="fa fa-caret-right p-r-5"></i>
                ${tagReferenceType[cur.tagReferenceType]} : ${cur.tagContent}
              </div>`
            )
          }
        }
        return acc
      }, {
        general: [],
        user: [],
      })

      let _tagContent = ""

      for(const property in _html) {
        if(_html[property].length > 0) {
          _html[property].forEach((info: string) => _tagContent = _tagContent.concat(info))
        }
      }

      let _tagContentLayout = _tagContent ? ` <ul className="dropdown-menu f-11"><li class="p-2" style="list-style:none">%</li></ul>` : ""

      this.tagInfo = {...this.tagInfo, [info] : _tagContentLayout.replace("%", _tagContent)}
    } catch (error) {
      this.tagInfo = {}
      console.log("error")
    }
  };

  @observable
  tagInfo = {}

  @observable
  tagIpList: any[] = [];

  @action
  isCheckedReg = (tagValueTypeList: any, value: string) => {
    return tagValueTypeList.find((info:any) => {
      const re = new RegExp(info.tagValueTypeRegex.replace(/\\\n/gi, `/`));
      return re.test(value);
    });
  }

  @action
  setTagIpListByProperty = (property: string, tagValueTypeList: any) => {
    const passedReg = this.isCheckedReg(tagValueTypeList, this[property])
    this[property] && this[property] !== "NONE" && passedReg && this.tagIpList.push(this[property]);
  };

  @action
  setTagIpListByValue = (value: string, tagValueTypeList: any) => {
    const passedReg = this.isCheckedReg(tagValueTypeList, value)
    value && passedReg && this.tagIpList.push(value);
  }

  @observable
  tagDataList: any = [];

  @action
  setTagDataList = (tagIp: any, getTagServiceDataList: any, index: number) => {
    this.tagDataList.push({
      text: tagIp,
      onClick: () => getTagServiceDataList({ tagValue: tagIp }),
      useDashBar: index === 0 && true
    });
  };

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  uiId = "";

  @action
  validText = (elementName: string) => {
    return this[elementName] === "";
  };

  @action
  setInfo(elementName: string, value: any) {
    this[elementName] = value;
  }

  @action
  _setInfo = (elementName: string) => (value: any) => {
    this.setInfo(elementName, value);
  };

  @action
  toggleIsFlag = (elementName: string) => {
    this[elementName] = this[elementName] === "1" ? "0" : "1";
  };

  @action
  _toggleIsFlag = (elementName: string) => () => {
    this[elementName] = this[elementName] === "1" ? "0" : "1";
  };

  @action
  toggleFlag = (elementName: string) => {
    this[elementName] = !this[elementName];
  };

  @action
  _toggleFlag = (elementName: string) => () => {
    this.toggleFlag(elementName);
  };

  @action
  updateInfo = (model: any) => {
    set(this, model);
  };

  @action
  setInfoOnlyNumber = (elementName: string, value: any) => {
    if (Common_Utils.onlyNumber(value)) {
      this.setInfo(elementName, value);
    }
  };

  @action
  _setInfoOnlyNumber = (elementName: string) => (value: any) => {
    if (Common_Utils.onlyNumber(value)) {
      this._setInfo(elementName)(value);
    }
  };

  @action
  _setInfoWithValue = (elementName: string, value: any) => () => {
    this._setInfo(elementName)(value);
  };

  @action
  _setInfoOnlyText = (elementName: string, value: any) => {
    this._setInfo(elementName)(Common_Utils.setOnlyText(value));
  };

  @action
  _setInfoAtOnce = (params: Array<[string, any]>) => {
    params?.length > 0 && params.forEach((info)=>this._setInfo(info[0])(info[1]))
  };
}

export default SetterModel;
