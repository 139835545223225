import React, { Component } from "react";
import PartialLoading from "../atoms/Loading/PartialLoading";

function asyncComponent<T>(importComponent: any) {
  class AsyncComponent extends Component<T> {
    static Splitted = null;

    _isMounted = false;

    static preload() {
      importComponent().then(({ default: component }) => {
        AsyncComponent.Splitted = component;
      });
    }

    state = {
      component: AsyncComponent.Splitted
    };

    constructor(props) {
      super(props);

      importComponent().then(({ default: component }) => {
        if (this._isMounted) {
          this.setState({
            component: component
          });
        }
      });
    }

    componentDidMount() {
      this._isMounted = true;
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const LoadedComponent: any = this.state.component;

      return LoadedComponent ? <LoadedComponent {...this.props} /> : <PartialLoading />;
    }
  }

  return AsyncComponent;
}

export default asyncComponent;
