import { observable, action } from "mobx";

import { SetterModel } from "@src/utils/model";

export interface LineType {
  type: string;
  name: string;
  data: Array<number>;
}

class LineModel extends SetterModel {
  constructor(param: { category?: Array<string> | null; data: Array<number> }) {
    super(param);
    if (param.category) this.category = param.category;
    param.data.length > 0 && this.chartData.push(this.getChartData(param.data));
  }

  @action
  getChartData = (data: Array<number>) => {
    return {
      type: "line",
      name: "",
      data,
    };
  };

  @observable
  chartData: LineType[] = [];

  @observable
  category: string[] = [];
}

export default LineModel;
