import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { observer, Observer } from "mobx-react";
import { subDays } from "date-fns";

import { languageSet } from "@qubit-utill/src";
import { dateUtil, Common_Utils } from "@qubit-utill/src/utils";
import { observable, action } from "mobx";
import Input from "../../atoms/Input";
import DatePicker from "../../atoms/Input/DatePicker";
import { DropdownWithChilren } from "../DropDown";
import { formatConstants } from "../../../common_constants";
import DropGroup from "./DropGroup";
import ModalStore from "../../../store/ModalStore";

@observer
class ListFilterWithDate extends Component {
  constructor(props) {
    super();
    const { info, listParams, onlyHour } = props;
    if (onlyHour) {
      const startTime = listParams[info.datepickerMinDateParameter].split(" ")[1];
      const endTime = listParams[info.datepickerMaxDateParameter].split(" ")[1];

      this.state = {
        startTime: startTime,
        endTime: endTime,
      };
    }
  }

  @observable
  startDate = dateUtil.format(new Date(), formatConstants.DATEFORMAT);
  @observable
  endDate = dateUtil.format(new Date(), formatConstants.DATEFORMAT);
  @observable
  startTime = "00:00:00";
  @observable
  endTime = "23:59:59";
  @observable
  timeText = dateUtil.format(new Date(), formatConstants.DATEFORMAT);
  @observable
  selectedTime = "today";

  componentDidMount() {
    const { info, listParams } = this.props;
    const currentPathUrl = Common_Utils.getCurrentPathUrl();
    const storage = JSON.parse(sessionStorage.getItem(currentPathUrl));

    if (storage) {
      listParams[info.datepickerMinDateParameter] = storage[info.datepickerMinDateParameter];
      listParams[info.datepickerMaxDateParameter] = storage[info.datepickerMaxDateParameter];
    }
    const {
      timeText,
      startTime,
      endTime,
      startDate,
      endDate,
      selectedTime,
    } = this.setPropsTimeToThis(listParams, info);

    this.timeText = timeText;
    this.startTime = startTime;
    this.endTime = endTime;
    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedTime = selectedTime;
  }

  @action
  setPropsTimeToThis = (listParams, info) => {
    let maxDate = listParams[info.datepickerMaxDateParameter];
    let minDate = listParams[info.datepickerMinDateParameter];
    let timeText = "";
    let startTime = "";
    let endTime = "";
    let startDate = "";
    let endDate = "";
    let selectedTime = "";

    if (maxDate && minDate) {
      startTime = minDate.split(" ")[1];
      endTime = maxDate.split(" ")[1];

      maxDate = dateUtil.format(dateUtil.newDate(maxDate), formatConstants.DATEFORMAT);
      minDate = dateUtil.format(dateUtil.newDate(minDate), formatConstants.DATEFORMAT);
      if (maxDate === minDate) {
        timeText = dateUtil.getDateByForm(minDate, "-");
        startDate = minDate;
        endDate = minDate;
      } else {
        timeText =
          dateUtil.getDateByForm(minDate, "-") + "~" + dateUtil.getDateByForm(maxDate, "-");
        startDate = dateUtil.getDateByForm(minDate, "-");
        endDate = dateUtil.getDateByForm(maxDate, "-");
      }
      // eslint-disable-next-line no-self-assign
      timeText = timeText;
      selectedTime = "";
    }
    return {
      timeText,
      startTime,
      endTime,
      startDate,
      endDate,
      selectedTime,
    };
  };

  componentDidUpdate(prevProps, nextState) {
    const { info, listParams } = this.props;
    const thisMaxDate = listParams[info.datepickerMaxDateParameter];
    const thisMinDate = listParams[info.datepickerMinDateParameter];

    const nextMaxDate = prevProps.listParams[info.datepickerMaxDateParameter];
    const nextMinDate = prevProps.listParams[info.datepickerMinDateParameter];

    if (thisMaxDate !== nextMaxDate || thisMinDate !== nextMinDate) {
      const { timeText, startTime, endTime, startDate, endDate } = this.setPropsTimeToThis(
        listParams,
        info
      );

      this.timeText = timeText;
      this.startTime = startTime;
      this.endTime = endTime;
      this.startDate = startDate;
      this.endDate = endDate;
    }
  }

  @observable
  isChangeTime = false;

  onChangeDate = key => date => {
    this[key] = dateUtil.getDateByForm(date, "-");
  };

  onChangeTime = key => date => {
    let newDate = date.length === 5 ? date + ":00" : date;
    this[key] = newDate;
    this.selectedTime = "";
  };

  onClickApply = () => {
    const { onlyHour } = this.props;
    const startTime = onlyHour ? this.state.startTime : this.startTime;
    const endTime = onlyHour ? this.state.endTime : this.endTime;

    if (this.startDate && this.endDate) {
      const _startDate = new Date(`${this.startDate} ${startTime}`).getTime();
      const _endDate = new Date(`${this.endDate} ${endTime}`).getTime();

      const dateErr = _startDate > _endDate;

      if (dateErr) {
        return ModalStore.openAlertModal({
          message: languageSet("에러_시간설정다시"),
        });
      }
    }

    if (onlyHour) {
      const _startTime = `${startTime.split(":")[0]}:00:00`;
      const _endTime = `${endTime.split(":")[0]}:00:00`;

      this.setState({
        startTime: _startTime,
        endTime: _endTime,
      });
      this.startTime = _startTime;
      this.endTime = _endTime;
    }

    this.selectedTime = "";
    this.setTimeText();
    this._setListParams();
  };

  @action
  setTimeText = () => {
    if (this.isChangeTime) {
      this.timeText = `${this.startDate} ${this.startTime}~${this.endDate} ${this.endTime}`;
    } else {
      this.timeText = `${this.startDate}~${this.endDate}`;
    }
  };

  _setListParams = (startDate, endDate) => {
    const { setListParams, info, setListNewParams, isUsePagination } = this.props;
    setListParams(
      info.datepickerMinDateParameter,
      startDate || `${this.startDate} ${this.startTime}`
    );
    setListNewParams(
      info.datepickerMaxDateParameter,
      endDate || `${this.endDate} ${this.endTime}`,
      true,
      isUsePagination
    );
  };

  onSelect = ({ value }) => () => {
    const { info, onlyHour } = this.props;
    const { startDate, endDate } = dateUtil.parseDateCode(value, info.datepickerMinDate);

    const formatedStartDate = dateUtil.format(new Date(startDate), formatConstants.DATEFORMAT),
      formatedEndDate = dateUtil.format(new Date(endDate), formatConstants.DATEFORMAT);

    this.timeText = formatedStartDate;
    this.selectedTime = value;

    if (value === "week" || value === "month") {
      this.timeText = `${formatedStartDate}~${formatedEndDate}`;
    }

    const timeFormatStartDate = dateUtil.format(
        new Date(startDate),
        formatConstants.DATEFORMATWITHTIME
      ),
      timeFormatEndtDate = dateUtil.format(new Date(endDate), formatConstants.DATEFORMATWITHTIME);

    this._setListParams(timeFormatStartDate, timeFormatEndtDate);

    onlyHour &&
      this.setState({
        startTime: "00:00:00",
        endTime: "23:00:00",
      });
  };

  _renderItems = items =>
    items.map(value => (
      <DropdownItem tag="li" key={value.value} onClick={this.onSelect(value)}>
        <a className="p-tb-3">
          {this.selectedTime === value.value && (
            <span className="check_w">
              <i className="fa fa-check" />
            </span>
          )}
          {value.name}
        </a>
      </DropdownItem>
    ));

  _renderItemsHandler = () => {
    let items = [
      { name: languageSet("오늘"), value: "today", displayName: languageSet("오늘날짜") },
      { name: languageSet("어제"), value: "yesterday", displayName: languageSet("어제날짜") },
      { name: languageSet("일주"), value: "week", displayName: languageSet("일주") },
      { name: languageSet("일개월"), value: "month" },
    ];
    if (this.props.info.datepickerMinDate === 0) {
      items = [items[0]];
    }
    return this._renderItems(items);
  };

  render() {
    const { info, dropdownClassName, arrowClassName, onlyHour } = this.props;

    return (
      <DropGroup dropdownClassName={dropdownClassName}>
        <DropdownWithChilren>
          <DropdownToggle tag="div" disabled={info.datepickerMinDate === 0}>
            <a className="c-dark">
              <span>{this.timeText}</span>
              <i className={`fa fa-angle-down p-l-10 ${arrowClassName}`} />
            </a>
          </DropdownToggle>
          <DropdownMenu tag="ul" className="f-12 drop-menu-lg">
            <Observer render={this._renderItemsHandler} />
            <li className="divider" />
            <div className="form-group m-b-0 text-center">
              <label className="form-label">
                <i className="fa fa-angle-left p-r-5" />
                {languageSet("기간설정")}
                <i className="fa fa-angle-right p-l-5" />
              </label>
              <div className="m-b-5">
                <DatePicker
                  size="sm"
                  value={this.startDate}
                  minDate={subDays(new Date(), info.datepickerMinDate)}
                  maxDate={new Date()}
                  onClick={this.onChangeDate("startDate")}
                />
              </div>
              <div className="form-group prepend-icon">
                {onlyHour ? (
                  <>
                    <input
                      className="form-control input-sm p-l-30"
                      type="time"
                      value={this.state.startTime}
                      onChange={e => {
                        this.setState({
                          startTime: e.target.value,
                        });
                      }}
                      step={10}
                    />
                    <i className="icon-clock" />
                  </>
                ) : (
                  <Input.Time
                    size="sm"
                    className="p-l-30"
                    value={this.startTime}
                    onChange={this.onChangeTime("startTime")}
                  />
                )}
              </div>
              <div className="m-b-5">
                <DatePicker
                  size="sm"
                  value={this.endDate}
                  minDate={subDays(new Date(), info.datepickerMinDate)}
                  maxDate={new Date()}
                  onClick={this.onChangeDate("endDate")}
                />
              </div>
              <div className="form-group prepend-icon">
                {onlyHour ? (
                  <>
                    <input
                      className="form-control input-sm p-l-30"
                      type="time"
                      value={this.state.endTime}
                      onChange={e => {
                        this.setState({
                          endTime: e.target.value,
                        });
                      }}
                      step={10}
                    />
                    <i className="icon-clock" />
                  </>
                ) : (
                  <Input.Time
                    size="sm"
                    className="p-l-30"
                    value={this.endTime}
                    onChange={this.onChangeTime("endTime")}
                  />
                )}
              </div>
              <span className="dis-block t-center m-b-5">
                <a className="cursor_p" onClick={this.onClickApply}>
                  <span className="label bg-black f-11 c-white">{languageSet("적용")}</span>
                </a>
              </span>
            </div>
          </DropdownMenu>
        </DropdownWithChilren>
      </DropGroup>
    );
  }
}

ListFilterWithDate.propTypes = {
  info: PropTypes.object,
  listParams: PropTypes.object,
  setListParams: PropTypes.func,
  setListNewParams: PropTypes.func,
};

export default ListFilterWithDate;
