import React, { Component } from "react"
import PropTypes from "prop-types"

class Scroll extends Component {
  static PropTypes = {
    scrollStepInPx: PropTypes.number,
    delayInMs: PropTypes.number
  }
  constructor() {
    super()
    this.state = {
      intervalId: 0,
      scrollBtn: false
    }
  }
  handleScroll = e => {
    let scrollTop = window.scrollY || window.pageYOffset

    this.setState({
      scrollBtn: scrollTop > 100
    })
  }
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll)
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll)
  }
  scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId)
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx)
  }
  scrollToTop = () => {
    clearInterval(this.state.intervalId)
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs)
    this.setState({ intervalId: intervalId })
  }
  render() {
    const { scrollBtn } = this.state
    return (
      scrollBtn && (
        <a
          className="scrollup"
          style={{ display: "block", cursor: "pointer" }}
          onClick={this.scrollToTop}
        >
          <i className="fa fa-angle-up" />
        </a>
      )
    )
  }
}

Scroll.propTypes = {}

export default Scroll
