import React from "react"
import PropTypes from "prop-types"

const Danger = ({ className, title, onClick }) => {
  return (
    <a onClick={onClick}>
      <span className={`${className} label label-danger`}>{title}</span>
    </a>
  )
}

Danger.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func
}

export default Danger
