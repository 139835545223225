export const TABLE = {
  //센터 정렬
  NO: "width_30 t-center",
  CHECKBOX: "width_45 t-center",
  SINGLE_ICON: "width_60 t-center", // TODO: DOUBLE_ICON과 동일하게 넓힘 (수정가능성있음)
  DOUBLE_ICON: "width_60 t-center",
  TICKET_ICON: "width_60 t-center",
  MATRICES_ICON: "w_85px t-center",
  REGIST_BTN: "width_45 t-center",
  CENTER_NUMBER: "width_60 t-center",
  OWASP: "width_45 t-center",
  STATUS: "w_100px t-center",
  RESULT: "width_60 t-center",
  DATE: "w_75px t-center",
  MICROSEC: "w_85px t-center",
  METHOD: "w_75px t-center",
  LOGIN_STATUS: "w_85px t-center",
  SINGLE_MIDDLE_ICON: "w_85px t-center",
  REGISTER_USER_NAME: "w_100px t-center",
  HOST_REFERENCE_COUNT: "w_75px t-center",
  ISUSE: "w_90px t-center",
  DETECT_TYPE: "w_75px t-center",
  //오른쪽 정렬
  NUMBER: "w_100px t-right",
  UPLOAD_NUMBER: "w_200px t-right",
  UPLOAD_NUMBER_SHORT: "w_180px t-right",
  UPLOAD_NUMBER_MIDDLE: "w_240px t-right",
  UPLOAD_NUMBER_LONG: "w_270px t-right",
  MAX_RESPONSE: "w_110px t-right",
  OBJECT: "w_130px t-left",
  //th == td
  TARGET: "w_75px",
  LOG: "w_75px t-center",
  SEVERITY: "w_85px",
  REGISTER: "w_85px",
  //th != td
  CATEGORY: "w_85px",
  SERVERGROUP: "w_85px",
  OSTYPE: "min_w120",
  CHANNEL: "w_90px",
  DETECTTYPE: "w_85px",
  //th == td
  EVENTTYPE: "w_120px",
  PRIORITY: "w_90px",
  COLLECT: "w_90px",
  AGENT: "w_100px",
  REGIST_DATE: "w_130px t-center",
  //th != td
  TYPE: "w_110px",
  HOST: "w_90px",
  REFERER: "w_90px",
  USER_AGENT: "w_150px",
  LOGINID: "w_90px",
  COOKIE: "w_90px",
  //th == td
  REGIST: "w_100px",
  IPADDRESS: "w_120px",
  NICKNAME: "w_110px",
  ATTACK_IP: "w_180px",
  MSG: "w_400px min_w150",
  ADDRESS: "w_100px",
  //th != td
  HOSTNAME: "w_130px",
  HOSTDOMAIN: "w_130px",
  SERVERGROUP_ICON: "w_110px",
  LOG_PATH_LONG: "w_302px t-center",
  LOG_PATH_SHORT: "w_200px t-center",
  LOG_TAG: "w_100px t-center",
  AGENT_STATUS: "w_130px t-center",
  GROUP: "w_75px",
  SYSTEM: "w_110px",
  CPU: "w_85px",
  DISK_ACTIVE_TIME: "w_120px",
  NETWORK_TRAFFIC: "w_120px",
  //min~
  FILTER: "min_w100",
  FILTER_NAME: "min_w150",
  FILTER_EVENT_TYPE: "min_w100",
  PATH: "min_w100",
  MESSAGE: "min_w170",
  WEB_FILTER: "min_w150",
  PURPOSE: "min_w150",
  QUERYPOSTBODY: "min_w170",
  LONG_HOST: "min_w150",
  MIDDLE_HOST: "min_w100",
  LONG_PATH: "min_w150",
  OS_VERSION: "min_w150",
  UPDATE_VERSION: "min_w170",
  DEFENSESTATUS: "min_w81",
  ONELINE_IPADDRESS: "min_w200",
  TAG: "min_w150",
  USERAGENT: "w_120px t-center",
  REGISTE_SYSTEM: "min_w150",
  COUNTRY_REGION: "min_w150",
  EXTRACTION_IPADDRESS: "min_w150",
  DETAIL: "min_w100",
  FILTER_TYPE: "min_w120",
  ATTACKER: "min_w120",
  MITRE_DOMAIN: "min_w120",
  MITRE_TECHNIQUES: "min_w150",
  MITRE_ID: "min_w100",
  RELATED_GROUP: "min_w100",
  RELATED_SOFTWARE: "w_120px",
  DETECT_NUMBER: "w_120px t-right",
  CLASSIFICATION: "min_w120",
  ATTACKFIELD: "min_w120",
  CONNECTION_NUMBER: "w_100px t-center",
  MEMBER: "w_85px t-center",
  COUNT_NUMBER: "w_85px t-right",
  SYSTEM_HOST: "w_200px",
  HEATMAP_INFO: "min_w150",
  DAY_OF_WEEK: "w_120px",
  META_COLUMN: "w_130px t-right",
  DEFENSE_EXPIRE: "w_160px t-right",
  TECHNIQUE_ID: "w_100px",
  PLATFORM: "w_120px",
  TATICS: "min_w150",
  TECHNIQUE: "min_w210",
  LOGIN_PROPERTIES: "w_70px",
};

//span class 별도 분리
export const SPAN = {
  DETECT_NUMBER: "w_60px",
  TARGET: "w_75px",
  OSTYPE: "w_85px",
  OS_VERSION: "w_120px",
  USERAGENT: "w_90px",
  FILTER_TYPE: "w_85px",
  CONNECTION_NUMBER: "w_70px",
};

export const TD = {
  FILTER: TABLE.FILTER + " word_b_all",
  FILTER_NAME: TABLE.FILTER_NAME + " word_b_all",
  FILTER_EVENT_TYPE: TABLE.FILTER_EVENT_TYPE + " word_b_all",
  //오른쪽정렬
  NUMBER: TABLE.NUMBER + " p-r-25",
  UPLOAD_NUMBER: TABLE.UPLOAD_NUMBER + " p-r-25",
  UPLOAD_NUMBER_SHORT: TABLE.UPLOAD_NUMBER_SHORT + " p-r-25",
  UPLOAD_NUMBER_MIDDLE: TABLE.UPLOAD_NUMBER_MIDDLE + " p-r-25",
  UPLOAD_NUMBER_LONG: TABLE.UPLOAD_NUMBER_LONG + " p-r-25",
  OBJECT: TABLE.OBJECT + " word_b_all",
  //말줄임표
  PATH: TABLE.PATH + " word_b_all line3_el",
  MESSAGE: TABLE.MESSAGE + " word_b_all line3_el",
  WEB_FILTER: TABLE.WEB_FILTER + " word_b_all line1_el",
  PURPOSE: TABLE.PURPOSE + " word_b_all line1_el",
  QUERYPOSTBODY: TABLE.QUERYPOSTBODY + " word_b_all line3_el",
  LONG_HOST: TABLE.LONG_HOST + " word_b_all line3_el",
  MIDDLE_HOST: TABLE.MIDDLE_HOST + " word_b_all line3_el",
  LONG_PATH: TABLE.LONG_PATH + " word_b_all line3_el",
  OS_VERSION: TABLE.OS_VERSION + " word_b_all line3_el",
  UPDATE_VERSION: TABLE.UPDATE_VERSION + " word_b_all line3_el",
  HOSTNAME: TABLE.HOSTNAME + " word_b_all line3_el",
  HOSTDOMAIN: TABLE.HOSTDOMAIN + " word_b_all line3_el",
  NICKNAME: TABLE.NICKNAME + " word_b_all line3_el",
  CHANNEL: TABLE.CHANNEL + " word_b_all line3_el",
  ONELINE_IPADDRESS: TABLE.ONELINE_IPADDRESS + " word_b_all line1_el",
  MSG: TABLE.MSG + " word_b_all line1_el",
  CATEGORY: TABLE.CATEGORY + " word_b_all",
  SERVERGROUP: TABLE.SERVERGROUP + " word_b_all",
  TYPE: TABLE.TYPE + " word_b_all",
  //완전 동일
  HOST: TABLE.HOST + " word_b_all ellip",
  HOST_FULL: TABLE.HOST,
  REFERER: TABLE.REFERER + " word_b_all ellip",
  USER_AGENT: TABLE.USER_AGENT + " word_b_all ellip",
  LOGINID: TABLE.LOGINID + " word_b_all ellip",
  COOKIE: TABLE.COOKIE + " word_b_all ellip",
  SERVERGROUP_ICON: TABLE.SERVERGROUP_ICON + " word_b_all",
  DEFENSESTATUS: TABLE.DEFENSESTATUS + " word_b_all",
  TAG: TABLE.TAG + " word_b_all line3_el",
  DETECT_NUMBER: TABLE.DETECT_NUMBER + " p-r-25",
  CLASSIFICATION: TABLE.CLASSIFICATION + " word_b_all",
  SYSTEM_HOST: TABLE.SYSTEM_HOST + " word_b_all",
  HEATMAP_INFO: TABLE.HEATMAP_INFO + " word_b_all",
  SINGLE_ICON: TABLE.SINGLE_ICON + " p-l-5",
  DOUBLE_ICON: TABLE.DOUBLE_ICON + " p-l-5",
  TICKET_ICON: TABLE.TICKET_ICON + " p-l-5",
  MATRICES_ICON: TABLE.MATRICES_ICON + " p-l-5",
  COUNT_NUMBER: TABLE.COUNT_NUMBER + " p-r-25",
  META_COLUMN: TABLE.META_COLUMN + " p-r-25",
  MITRE_DESCRIPTION: "width-40p min_w170",
  FILTER_TYPE: TABLE.FILTER_TYPE,
  DEFENSE_EXPIRE: TABLE.DEFENSE_EXPIRE + " p-r-25",
  MAX_RESPONSE: TABLE.MAX_RESPONSE + " p-r-25",
};
