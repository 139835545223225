import React from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

interface propsType {
  placeholder?: string;
  className?: string;
  size?: string;
  color?: string;
  readOnly?: boolean;
  noSize?: boolean;
  noColor?: boolean;
  disabled?: boolean;
  onBlur?: Function | any;
  autoComplete?: string;
  minSize?: number;
  maxSize?: number;
  value: string | number;
  onKeyDown?: Function;
  onChange?: Function | any;
  onClick?: Function | any;
  style?: object;
  onFocus?: Function | any;
  onPaste?: Function | any;
}

const Text: React.FC<propsType> = ({
  placeholder,
  className,
  size = "sm",
  color = "white",
  readOnly,
  noSize,
  noColor,
  disabled,
  onBlur,
  autoComplete,
  minSize,
  maxSize,
  value = "",
  onKeyDown,
  onChange,
  onClick,
  style,
  onFocus,
  onPaste,
}) => {
  const setText = e => {
    try {
      onChange && onChange(e.target.value);
    } catch (error) {}
  };

  const _onKeyDown = e => {
    if (e.keyCode && e.keyCode === 13) {
      onKeyDown && onKeyDown(e.target.value);
    }
  };

  const sizeClass = !noSize ? `input-${size}` : "";
  const colorClass = !noColor ? `bg-${color}` : "";

  return (
    <input
      onClick={onClick}
      className={`form-control ${sizeClass} ${colorClass} ${className}`}
      type="text"
      value={value !== undefined && value !== null ? value : ""}
      disabled={disabled}
      onChange={setText}
      placeholder={placeholder}
      readOnly={readOnly}
      onKeyDown={_onKeyDown}
      onBlur={onBlur ? onBlur : () => {}}
      autoComplete={autoComplete}
      minLength={minSize}
      maxLength={maxSize}
      style={style}
      onFocus={onFocus}
      onPaste={onPaste}
    />
  );
};

export default observer(Text);
