const languageKo = {
  _1단계: "1단계",
  _1차_구분자_필드: "1차 구분자 (필드)",
  _24시간: "24시간",
  _2단계: "2단계",
  _2차_구분자_토큰: "2차 구분자 (토큰)",
  _2차_인증_OTP메일: "2차 인증: OTP메일",
  _2차_인증_PIN번호: "2차 인증: PIN번호",
  _2차_인증: "2차 인증",
  _2차_인증OTP메일: "2차 인증<br/>(OTP메일)",
  _2차_인증PIN번호: "2차 인증<br/>(PIN번호)",
  _2차_플랫폼_노출명: "2차 플랫폼 노출명",
  _2차_플랫폼_코드: "2차 플랫폼 코드",
  _2차_플랫폼: "2차 플랫폼",
  _3번_패키지_설치_포함됨: "3번 패키지 설치 포함됨",
  _78_mysql_conf_수정: "78-mysql.conf 수정",
  가능: "가능",
  가변: "가변",
  가입_변경일자: "가입/변경일자",
  가입대기: "가입대기",
  가입멤버: "가입멤버",
  가입여부: "가입여부",
  가입완료: "가입완료",
  가입인증이_완료되었습니다: "가입인증이 완료되었습니다.",
  가입인증처리_하시겠습니까: "가입인증처리 하시겠습니까?",
  가입인증처리: "가입인증처리",
  가입일: "가입일",
  가입일자: "가입일자",
  간단_설명: "간단 설명",
  간략설명: "간략설명",
  개별: "개별",
  개인_대시보드_수정: "개인 대시보드 수정",
  개인_대시보드: "개인 대시보드",
  개인알림: "개인알림",
  개인정보_관련없는_번호로_변경:
    "휴대폰번호 / 주민등록번호 / 생일 등의 개인정보와 관련없는 번호로 변경해주세요.",
  개인정보_마스킹_설정: "개인정보 마스킹 설정",
  개인정보_마스킹: "개인정보 마스킹",
  개인정보_수정: "개인정보 수정",
  개인정보_수집_이용: "개인정보 수집/이용",
  개인정보_숨김_설정: "개인정보 숨김 설정",
  개인정보_처리방침: "개인정보 처리방침",
  개인정보숨김_설정: "개인정보숨김 설정",
  개인정보숨김: "개인정보숨김",
  개인정보숨김설정_추가: "개인정보숨김설정 추가",
  개인정보취급방침: "개인정보취급방침",
  개행_안함: "개행 안함",
  개행_타입: "개행 타입",
  갯수설정: "갯수설정",
  건당_평균_크기: "건당 평균 크기",
  검사자등록: "검사자등록",
  검색_개인화: "검색 개인화",
  검색_쿼리_방식: "검색 쿼리 방식",
  검색_키워드_상세: "검색 키워드 상세",
  검색_키워드: "검색 키워드",
  검색: "검색",
  검색결과: "검색결과",
  검색대상: "검색대상",
  검색제공메뉴: "검색제공메뉴",
  검색제공메뉴가_없습니다: "검색제공메뉴가 없습니다.",
  검색추가: "검색추가",
  검색항목_관리: "검색항목 관리",
  검색항목관리: "검색항목관리",
  격리_해제: "격리 해제",
  격리: "격리",
  결과문서_구조: "결과문서 구조",
  결과문서_선택: "결과문서 선택",
  경고: "경고",
  경로_등록: "경로 등록",
  경로_예시: "경로 예시",
  경로: "경로",
  계정_탈취_공격_방어: "계정 탈취 공격 방어",
  계정_탈취_공격_보안탐지: "계정 탈취 공격 보안탐지",
  계정_탈취_공격: "계정 탈취 공격",
  계정생성: "계정생성",
  계정탈취_보고서: "계정탈취 보고서",
  계정탈취_보안탐지: "계정탈취 보안탐지",
  계정탈취_보안필터_등록: "계정탈취 보안필터 등록",
  계정탈취_보안필터_수정: "계정탈취 보안필터 수정",
  계정탈취_수정: "계정탈취 수정",
  계정탈취_예외: "계정탈취 예외",
  계정탈취: "계정탈취",
  계정탈취공격_보안필터_수정: "계정탈취공격 보안필터 수정",
  계정탈취공격: "계정탈취공격",
  고_정: "고 정",
  고객지원_결과: "고객지원 결과",
  고객지원_담당자: "고객지원 담당자",
  고객지원_요청_사유: "고객지원 요청 사유",
  고객지원_요청하기: "고객지원 요청하기",
  고객지원_이용약관: "고객지원 이용약관",
  고객지원_진행: "고객지원 진행",
  고객지원_회사: "고객지원 회사",
  고객지원: "고객지원",
  고객지원요청_관리: "고객지원요청 관리",
  고객지원요청_상세: "고객지원요청 상세",
  고객지원요청: "고객지원요청",
  고객지원요청관리: "고객지원요청관리",
  고유아이디: "고유아이디",
  고유NO: "고유NO",
  고정: "고정",
  공격: "공격",
  공격목적: "공격목적",
  공격목적코드: "공격목적코드",
  공격분포: "공격분포",
  공격수: "공격수",
  공격유형_분포_요일별: "공격유형 분포(요일별)",
  공격유형_분포_주별: "공격유형 분포(주별)",
  공격유형_분포: "공격유형 분포",
  공격유형_선택: "공격유형 선택",
  공격유형: "공격유형",
  공격유형매핑: "공격유형매핑",
  공격자: "공격자",
  공격코드: "공격코드",
  공격패턴: "공격패턴",
  공격확률: "공격확률",
  공격확률별_로그발생_현황: "공격확률별 로그발생 현황",
  공격확률별_설정: "공격확률별 설정",
  공격확률별_탐지_현황: "공격확률별 탐지 현황",
  공격확률별_현황: "공격확률별 현황",
  공격횟수: "공격횟수",
  공격IP주소: "공격IP주소",
  공지: "공지",
  공지보기: "공지보기",
  공지사항: "공지사항",
  관련_높은: "관련 높은",
  관련그룹: "관련그룹",
  관련소프트웨어: "관련소프트웨어",
  관리_무료: "관리(무료 추천)",
  관리: "관리",
  관리자_설정: "관리자 설정",
  구분: "구분",
  국가_설정: "국가 설정",
  국가_언어_설정: "국가/언어 설정",
  국가_지역: "국가(지역)",
  국가: "국가",
  국가별_공격_IP주소_현황: "국가별 공격 IP주소 현황",
  국가별_접속_상위_IP주소_TOP20: "국가별 접속 상위 IP주소 TOP 20",
  권한_선택: "권한 선택",
  권한: "권한",
  권한변경: "권한변경",
  권한변경하기: "권한변경하기",
  그_외: "그 외",
  그래프: "그래프",
  그래프를_제공하지_않습니다: "그래프를 제공하지 않습니다.",
  그룹_등록: "그룹 등록",
  그룹_선택: "그룹 선택",
  그룹_설정: "그룹 설정",
  그룹_수정: "그룹 수정",
  그룹_추가: "그룹 추가",
  그룹: "그룹",
  그룹고유키: "그룹고유키",
  그룹관리: "그룹관리",
  그룹내_기본값_사용: "그룹내 기본값 사용",
  그룹명: "그룹명",
  그룹별_웹훅: "그룹별 웹훅",
  그룹별_Syslog: "그룹별 Syslog",
  그룹별: "그룹별",
  그룹선택: "그룹선택",
  그룹설정: "그룹설정",
  그룹알림: "그룹알림",
  금: "금",
  금요일: "금요일",
  금월: "금월",
  금일_비밀번호_변경: "금일 비밀번호가 변경되었습니다.",
  금일: "금일",
  금주: "금주",
  기간설정: "기간설정",
  기본_실행: "기본 실행",
  기본: "기본",
  기본정보: "기본정보",
  기술: "기술",
  기타_정보: "기타 정보",
  기타: "기타",
  나의_정보: "나의 정보",
  나중_변경: "나중에 변경하기",
  날짜: "날짜",
  날짜형: "날짜형",
  남은_기간: "남은 기간",
  남은기간: "남은기간",
  남음: "남음",
  낮음: "낮음",
  내부_IP: "내부 IP",
  내용: "내용",
  네트워크_격리_해제: "네트워크 격리 해제",
  네트워크_격리: "네트워크 격리",
  네트워크_단일필터_등록: "네트워크 단일필터 등록",
  네트워크_단일필터_수정: "네트워크 단일필터 수정",
  네트워크_단일필터: "네트워크 단일필터",
  네트워크_등록: "네트워크 등록",
  네트워크_로그: "네트워크 로그",
  네트워크_수정: "네트워크 수정",
  네트워크_업로드: "네트워크 업로드",
  네트워크_전체로그: "네트워크 전체로그",
  네트워크_필터탐지: "네트워크 필터탐지",
  네트워크_호스트명: "네트워크 호스트명",
  네트워크: "네트워크",
  네트워크로그_수집_경로: "네트워크로그 수집 경로",
  네트워크로그_수집: "네트워크로그 수집",
  네트워크로그_업로드_수: "네트워크로그 업로드 수",
  네트워크로그_업로드: "네트워크로그 업로드",
  네트워크로그: "네트워크로그",
  네트워크IP: "네트워크IP",
  년: "년",
  년도별: "년도별",
  노드: "노드",
  노드구분: "노드구분",
  노드상태: "노드상태",
  노출_문구: "노출 문구",
  노출_컬럼을_관리합니다: "기본 컬럼 외 데이터 노출 컬럼을 관리합니다.",
  노출: "노출",
  노출명: "노출명",
  노출설정: "노출설정",
  노출순서: "노출순서",
  노출여부: "노출여부",
  높음: "높음",
  닉네임: "닉네임",
  님_회원정보: "님 회원정보",
  님: "님",
  다운로드_한_파일의_경로에서_cmd_명령창_실행: "다운로드 한 파일의 경로에서 cmd 명령창 실행",
  다운로드: "다운로드",
  다음: "다음",
  다크_모드: "다크 모드",
  단_epel_release_사전_설치_필요: "단, epel-release 사전 설치 필요",
  단위: "단위",
  단위변환: "단위변환",
  단일: "단일",
  단일필터_네트워크_분류: "단일필터(네트워크) 분류",
  단일필터_등록: "단일필터 등록",
  단일필터_수정: "단일필터 수정",
  단일필터_시스템_분류: "단일필터(호스트) 분류",
  단일필터: "단일필터",
  닫기: "닫기",
  담당자_선택: "담당자 선택",
  담당자: "담당자",
  답변_메일알림_받기: "답변 메일알림 받기",
  답변: "답변",
  답변대기: "답변대기",
  답변완료: "답변완료",
  당일: "당일",
  대기: "대기",
  대량등록: "대량등록",
  대상_서버에_FilePlura_설치: "대상 서버에 FilePlura 설치",
  대상_서버에서_설정: "대상 서버에서 설정",
  대상_시스템: "대상 호스트",
  대상_장비에서_설정: "대상 장비에서 설정",
  대상_주소: "대상 주소",
  대상_포트_이름: "대상 포트 이름",
  대상_호스트_이름: "대상 호스트 이름",
  대상: "대상",
  대상건: "대상건",
  대상for서버: "대상",
  대상IP주소_등록: "대상IP주소 등록",
  대상IP주소: "대상IP주소",
  대시보드: "대시보드",
  대응: "대응",
  대표_웹훅: "대표 웹훅",
  대표_Syslog: "대표 Syslog",
  대표: "대표",
  대표번호: "대표번호",
  대표자: "대표자",
  더_자세한_정보: "더 자세한 정보",
  더보기: "더보기",
  데이터_값: "데이터 값",
  데이터_소스: "데이터 소스",
  데이터_입력: "데이터 입력",
  데이터_저장기간: "데이터 저장기간",
  데이터_타입: "데이터 타입",
  데이터루트: "데이터루트",
  데이터베이스_비교: "데이터베이스 비교",
  데이터소스: "데이터소스",
  데이터유출_방어: "데이터유출 방어",
  데이터유출_보안탐지: "데이터유출 보안탐지",
  데이터유출_보안필터_등록: "데이터유출 보안필터 등록",
  데이터유출_보안필터_수정: "데이터유출 보안필터 수정",
  데이터유출_보안필터: "데이터유출 보안필터",
  데이터유출_수정: "데이터유출 수정",
  데이터유출: "데이터유출",
  데이터유출필터_분류: "데이터유출필터 분류",
  데이터유형_선택: "데이터유형 선택",
  데이터유형: "데이터유형",
  도구: "도구",
  도메인_등록: "도메인 등록",
  도메인_수정: "도메인 수정",
  도메인_코드: "도메인 코드",
  도메인: "도메인",
  도메인관리: "도메인관리",
  도메인등록: "도메인등록",
  도메인명: "도메인명",
  도움말_바로가기: "도움말 바로가기",
  도움말: "도움말",
  돌아가기: "돌아가기",
  동기화_현황: "동기화 현황",
  동기화: "동기화",
  동기화관리: "동기화관리",
  동기화단위: "동기화단위",
  동기화테이블: "동기화테이블",
  동기화현황: "동기화현황",
  동기화DATA: "동기화DATA",
  동시접속: "동시접속",
  동영상_가이드: "동영상 가이드",
  동의: "동의",
  동의안함: "동의안함",
  동일_숫자_3자리_이상: "동일 숫자 3자리 이상",
  동일: "동일",
  동일로그: "동일로그",
  동일요소수: "동일요소수",
  동작: "동작",
  동작시간_GMT09: "동작시간(GMT+09)",
  동작시간: "동작시간",
  동적필터_코드_선택: "동적필터 코드 선택",
  동적필터: "동적필터",
  동적필터등록: "동적필터등록",
  동적필터수정: "동적필터수정",
  두개_이상_필터_등록_가능: "2개 이상 필터 등록 가능(or)",
  등록_가능한_정보입력: "등록 가능한 정보입력",
  등록_가능한_호스트_개수: "등록 가능한 호스트 총 %개",
  등록_가능한_n: "등록 가능한 %",
  등록_불가능한_n: "등록 불가능한 %",
  등록_서버: "등록 서버",
  등록_시스템: "등록 호스트",
  등록_패턴: "등록 패턴",
  등록_확인_후_가능: "등록 확인 후 가능",
  등록: "등록",
  등록게시물수: "등록게시물수",
  등록되었습니다: "등록되었습니다",
  등록된_목록: "등록된 목록",
  등록된_서버: "등록된 서버",
  등록된_시스템_확인: "등록된 호스트 확인",
  등록된_시스템: "등록된 호스트",
  등록된_항목수: "등록된 항목수",
  등록된_data_name_리스트: "등록된 data name 리스트",
  등록된_data_name: "등록된 data name",
  등록된_IP주소_수: "등록된 IP주소 수",
  등록된_IP주소: "등록된 IP주소",
  등록된IP주소그룹: "등록된IP주소그룹",
  등록된IP주소수: "등록된IP주소수",
  등록방법: "등록방법",
  등록서버많은순: "등록서버많은순",
  등록서비스: "등록서비스",
  등록순: "등록순",
  등록업체_바로가기: "등록업체 바로가기",
  등록업체: "등록업체",
  등록일: "등록일",
  등록자_등록일: "등록자/등록일",
  등록자: "등록자",
  등록필터: "등록필터",
  등록필터수: "등록필터수",
  등록하기: "등록하기",
  등록한_퍼센트이상부터_서비스_노출: "등록한 %이상부터 서비스 노출",
  디스크로드률: "디스크로드률",
  디스크사용률: "디스크사용률",
  디스크용량: "디스크용량",
  디코딩: "디코딩",
  디폴트_메뉴_설정: "디폴트 메뉴 설정",
  디폴트_탭설정: "디폴트 탭설정",
  디폴트: "디폴트",
  라우트: "라우트",
  라이선스_기간: "라이선스 기간",
  라이선스_등록_및_실행: "라이선스 등록 및 실행",
  라이선스_등록: "라이선스 등록",
  라이선스_변경_신청: "라이선스 변경 신청",
  라이선스_키_입력: "라이선스 키 입력",
  라이선스: "라이선스",
  라이선스키: "라이선스 키",
  라이트_모드: "라이트 모드",
  라인_그래프: "라인 그래프",
  라인_추가: "라인 추가",
  라인을_주석처리: "라인을 주석처리",
  랜덤_발생: "랜덤 발생",
  레드마인_고유NO: "레드마인 고유NO",
  레벨: "레벨",
  레퍼러: "레퍼러",
  로고: "로고",
  로그_기능: "로그 기능",
  로그_바로가기: "로그 바로가기",
  로그_심각도: "로그 심각도",
  로그_업로드_설정: "로그 업로드 설정",
  로그_업로드_합계: "로그 업로드 합계",
  로그_취합_서버_Datos_설치_포트미러링_페이지_Linux_Datos_탭:
    "로그 취합 서버에 Datos 설치 후 포트미러링 구성 → 페이지 상단 메뉴에서 Linux > Datos 탭",
  로그_취합_서버에_포트미러링_구성: "로그 취합 서버에 포트미러링 구성",
  로그_취합서버에_로그콜렉터_설치: "로그 취합서버에 로그콜렉터 설치",
  로그_취합서버에_에이전트_설치: "로그 취합서버에 에이전트 설치",
  로그_취합서버에서_인바운드_TCP_5514_포트_오픈: "로그 취합서버에서 인바운드 TCP 5514 포트 오픈",
  로그: "로그",
  로그구분: "로그구분",
  로그발생_현황: "로그발생 현황",
  로그별_업로드_분포: "로그별 업로드 분포",
  로그보기: "로그보기",
  로그상세: "로그상세",
  로그설정: "로그설정",
  로그수집경로입력: "로그수집경로입력",
  로그아웃: "로그아웃",
  로그업로드_분포: "로그업로드 분포",
  로그업로드: "로그업로드",
  로그업로드량: "로그업로드량",
  로그유형: "로그유형",
  로그유형그룹: "로그유형그룹",
  로그인_메일: "로그인 메일",
  로그인_비밀번호_입력: "로그인 비밀번호 입력",
  로그인_속성: "로그인 속성",
  로그인_제한: "로그인 제한",
  로그인_히스토리: "로그인 히스토리",
  로그인: "로그인",
  로그인메일: "로그인메일",
  로그인허용_IP주소_추가: "로그인허용 IP주소 추가",
  로그인허용IP주소: "로그인허용IP주소",
  로그인ID: "로그인ID",
  로그저장크기: "로그저장크기",
  로그조회_리스트_컬럼에_정보_노출함: "로그조회 리스트 컬럼에 정보 노출함",
  로그취합상태: "로그취합상태",
  로그취합서버IP: "로그취합서버IP",
  로그콜렉터_삭제: "로그콜렉터 삭제",
  로그콜렉터_재실행: "로그콜렉터 재실행",
  로그포렌식_설치환경_안내_윈도우10_넷프레임워크45:
    "Windows 10 또는 .NET Framework 4.5 이상 환경에서 설치 가능",
  로그포렌식_지원시스템_종류: "로그 업로드 지원 시스템 : Windows, Linux, Android",
  루틴_프로시저_함수: "루틴(프로시저, 함수)",
  룰_입력: "룰 입력",
  를_선택합니다: "를 선택합니다",
  리눅스_명령어_command_설치_rsyslog_사용: "리눅스 명령어(command) 설치 (rsyslog 사용)",
  리더: "리더",
  리셀러_등록: "리셀러 등록",
  리셀러: "리셀러",
  리소스_등록: "리소스 등록",
  리소스_수정: "리소스 수정",
  리소스_수집: "리소스 수집",
  리소스_현황: "리소스 현황",
  리소스: "리소스",
  리소스관리: "리소스관리",
  리소스를_등록하세요: "리소스를 등록하세요.",
  리소스명: "리소스명",
  리소스모니터링_메타정의: "% > % > 메타정의",
  리소스모니터링: "리소스모니터링",
  리소스코드: "리소스코드",
  리스트_컬럼_노출_설정: "리스트 컬럼 노출 설정",
  리스트: "리스트",
  링크: "링크",
  마이크로초: "마이크로초",
  마이터_어택_탐지_분포: "마이터 어택 탐지 분포",
  마이터_어택_탐지: "마이터 어택 탐지",
  마이터_어택: "마이터 어택",
  마이터_통계_동기화: "마이터 통계 동기화",
  마이터: "마이터",
  마이터관리: "마이터관리",
  마지막: "마지막",
  마케팅_메일: "마케팅 메일",
  만료상태: "만료상태",
  만료예정: "만료예정",
  만료일: "만료일",
  매뉴얼: "매뉴얼",
  매칭_키워드: "매칭 키워드",
  매트릭스_이동: "매트릭스 이동",
  매트릭스: "매트릭스",
  매트릭스보기: "매트릭스보기",
  매핑_컴플라이언스: "매핑 컴플라이언스",
  매핑: "매핑",
  매핑소프트웨어: "매핑소프트웨어",
  매핑수: "매핑수",
  매핑안함: "매핑안함",
  매핑전술: "매핑전술",
  매핑정보_관리: "매핑정보 관리",
  매핑정보_등록: "매핑정보 등록",
  매핑주소: "매핑주소",
  매핑주소관리: "매핑주소관리",
  매핑코드_컬럼: "(searchColumn)",
  매핑코드: "매핑코드",
  매핑플랫폼: "매핑플랫폼",
  매핑필터_리스트: "매핑필터 리스트",
  매핑필터: "매핑필터",
  매핑해킹그룹: "매핑해킹그룹",
  머신러닝의_패턴분석결과: "머신러닝의 패턴분석결과",
  멀티등록_반점구분: "멀티등록 , 구분",
  메뉴_노출_설정: "메뉴 노출 설정",
  메모리사용률: "메모리사용률",
  메모리용량: "메모리용량",
  메세지_2차구분자입력하세요: "2차 구분자를 입력해 주세요.",
  메세지_가입완료후비밀번호변경요청해라: "가입 완료 후 비밀번호 변경을 요청해 주세요.",
  메세지_개발팀문의: "개발팀에 문의해 주세요.",
  메세지_개인대시보드노출필터설정: "개인 대시보드에 노출할 필터를 설정해 주세요.",
  메세지_개인정보처리방침에동의한다: "위의 개인정보 처리방침에 동의합니다.",
  메세지_검색결과n건있다: `검색결과 <span class="c-black bold">%</span>건 있습니다.`,
  메세지_검색키워드등록시안내: "검색키워드 등록시에는 수정할 수 없습니다.",
  메세지_검색키워드리스트없귀: "등록된 검색 키워드가 없습니다.",
  메세지_검색타입선택: "검색 타입을 선택해 주세요.",
  메세지_경로입력: "경로를 입력해 주세요.",
  메세지_계정탈취필터등록후사용가능: "계정탈취 필터 등록 후 사용 가능합니다.",
  메세지_계정탈취필터등록후확인가능: "계정탈취 필터 등록 후 확인 가능합니다.",
  메세지_고객지원로그인시히스토리관리에정보가노출된다:
    "PLURA 고객지원 로그인 시 히스토리 관리에 정보가 노출됩니다.",
  메세지_고객지원요청완료: "고객지원이 요청되었습니다.",
  메세지_고객지원용아이디를통한접속정보는DB에저장한다:
    "고객지원용 아이디를 통한 접속정보는 DB에 저장됩니다.",
  메세지_공격유형을_선택하세요: "공격유형을 선택해 주세요.",
  메세지_공격IP주소가block되방어실행됨방어할거냐:
    "공격IP주소가 IP Block에 추가되어 방어가 실행됩니다.\n방어하시겠습니까?",
  메세지_관리자가_확인_후_답변_등록_예정입니다: "관리자가 확인 후 답변 등록 예정입니다.",
  메세지_관리자만수정가능하다: "관리자만 수정 가능합니다.",
  메세지_관리자에게문의후로그인시도: "관리자에게 문의 후 로그인 시도해 주시기 바랍니다.",
  메세지_그룹삭제입력정보모두삭제: "그룹을 삭제하시겠습니까?\n입력된 정보가 모두 삭제됩니다.",
  메세지_그룹을생성한멤버는해당그룹의소속으로자동설정됩니다:
    "그룹을 생성한 멤버는 해당 그룹의 소속으로 자동 설정됩니다.",
  메세지_그룹을조회할멤버를선택하세요: "그룹을 조회할 멤버를 선택하세요.",
  메세지_그룹을추가해야함: "그룹을 추가해 주세요.",
  메세지_그룹이등록되었습니다: "그룹이 등록 되었습니다.",
  메세지_내부DNS아닌경우host에서지정한다: `web server에 설정된 주소가 내부 DNS 주소가 아닌 경우, hosts 파일에서 <span className="c-red">internal-web-address</span> 지정`,
  메세지_내용을_입력해주세요: "내용을 입력해 주세요.",
  메세지_네트워크_격리: "네트워크 격리를 진행합니다.",
  메세지_네트워크_격리해제: "네트워크 격리를 해제합니다.",
  메세지_다양한테마적용나만의프루라만드세요: "다양한 테마 적용으로 나만의 PLURA를 만드세요.",
  메세지_다운로드_로그수_최대n개_입력: "다운로드 받을 로그 수를 입력해주세요. (최대 %개)",
  메세지_다운로드_수_입력: "2천개 이하의 다운로드 받을 % 수를 입력해주세요.",
  메세지_다운로드실패: "다운로드가 실패하였습니다.",
  메세지_담당자를_선택해_주세요: "담당자를 선택해 주세요.",
  메세지_답변글을_등록해_주세요: "답변글을 등록해 주세요.",
  메세지_대시보드로이동: "변경된 정보가 저장되지 않고 대시보드로 이동됩니다.",
  메세지_데이터값입력: "데이터 값을 입력해 주세요.",
  메세지_데이터루트를선택하세요: "데이터 루트를 선택해 주세요.",
  메세지_동기화실패: "동기화에 실패하였습니다.",
  메세지_동기화완료: "동기화 완료되었습니다.",
  메세지_동기화요청: "동기화 요청 되었습니다.",
  메세지_동기화요청실패: "동기화 요청 실패했습니다.",
  메세지_동기화진행취소불가: "동기화 진행 중에는 취소가 불가능합니다.",
  메세지_동기화진행하쉴: "동기화를 진행하시겠습니까?",
  메세지_동기화필수: "순서대로 동기화 필수!!!",
  메세지_동기화하시겠습니까: "동기화를 진행하시겠습니까?",
  메세지_동일한검색어가있습니다: "동일한 검색어가 있습니다.",
  메세지_등록가능한필터수초과: "등록 가능한 필터 수가 모두 등록되었습니다.",
  메세지_등록되었습니다_클릭시적용: "등록되었습니다.\n경로 팝업 하단 확인 버튼 클릭 시 적용됩니다.",
  메세지_등록된_채널이_없습니다: "등록된 채널이 없습니다.",
  메세지_등록된항목삭제불가항목삭제후다시시도:
    "등록된 항목이 있어 삭제가 불가능합니다. 항목 삭제 후 다시 시도해 주세요.",
  메세지_등록된항목이없습니다: "등록된 항목이 없습니다.",
  메세지_등록된IP만로그인가능하다: "등록된 IP만 로그인이 가능합니다.",
  메세지_등록실패: "등록이 실패하였습니다.",
  메세지_등록완료: "등록 되었습니다.",
  메세지_등록하쉴: "등록 하시겠습니까?",
  메세지_등록할목록그룹명을입력하라: "등록할 목록그룹명을 입력해 주세요.",
  메세지_등록할목록명: "등록할 목록명을 입력해 주세요.",
  메세지_등록할태그명을입력하세요: "등록할 태그명을 입력하세요.",
  메세지_등록할호스트명을입력하세요: "등록할 호스트명을 입력하세요.",
  메세지_디폴트설정하쉴이미설정된디폴트취소:
    "디폴트로 설정하시겠습니까?\n이미 설정된 디폴트는 취소됩니다.",
  메세지_디폴트OFF: "디폴트 OFF",
  메세지_디폴트ON: "디폴트 ON",
  메세지_라이센스확인: "라이센스를 확인해 주세요.",
  메세지_로그인10회실패: "로그인 10회실패로 로그인이 제한됩니다.",
  메세지_로그인성공실패선택: "로그인 성공, 실패여부를 선택해 주세요.",
  메세지_로그인속성입력: "로그인 속성을 입력해 주세요.",
  메세지_로그인실패제한_영구:
    "% %회 실패로 서비스 이용이 제한됩니다.\n관리자에게 문의하시기 바랍니다.",
  메세지_로그인실패제한:
    "% %회 실패로 서비스 이용이 % 제한됩니다.\n잠시 후 다시 시도해 주시기 바랍니다.",
  메세지_로그인정보불일치: "사용자가 없거나 정보가 일치하지 않습니다.",
  메세지_로그인해주세요: "로그인을 해주세요.",
  메세지_로그취합안하쉴: "로그를 취합을 중지하시겠습니까?",
  메세지_로그취합하쉴: "로그를 취합하시겠습니까?",
  메세지_리소스_등록_후_사용가능합니다: "리소스 등록 후 사용가능합니다.",
  메세지_리스트삭제최종삭제:
    "리스트에서 삭제표시됩니다. 우측 상단 확인 버튼 클릭 시 최종 삭제처리됩니다.",
  메세지_리스트없음: "검색한 리스트가 없습니다.",
  메세지_리스트에서_삭제: "리스트에서 삭제되었습니다.",
  메세지_매일설정한시간에만필터동작: "매일 설정한 시간에만 필터가 동작합니다.",
  메세지_매핑된항목없음: "매핑된 항목이 없습니다.",
  메세지_매핑코드입력: "매핑코드를 입력해 주세요.",
  메세지_매핑필터중복안됨: "매핑필터는 중복 될 수 없습니다.",
  메세지_메뉴선택: "메뉴를 선택해 주세요.",
  메세지_메일을발송했다: "메일을 발송했습니다.",
  메세지_멤버로그인시비밀번호변경필요: "멤버 로그인 시 비밀번호 변경 필요.",
  메세지_멤버에포함되지않는고객지원용아이디로로그인한다:
    "멤버에 포함되지 않는 고객지원용 아이디로 로그인합니다.",
  메세지_모드프루라업데이트: "메세지 모드프루라를 업데이트합니다.",
  메세지_모드프루라업데이트하쉴: "모드프루라를 업데이트 하시겠습니까?",
  메세지_모든IP로그인가능하다: "모든 IP가 로그인이 가능합니다.",
  메세지_문의하실_내용을_입력해_주세요: "문의하실 내용을 입력해 주세요!",
  메세지_미적용하시겠습니까: "미적용하시겠습니까?",
  메세지_발급된임시비밀번호는24시간이내만료된다: "발급된 임시비밀번호는 24시간 이내 만료됩니다.",
  메세지_방어대상필터없음: `방어대상 필터가 없습니다.\n방어 필요한 필터 등록 시 노출되며 설정이 가능합니다.`,
  메세지_방어실행된다: "방어가 실행됩니다.",
  메세지_방화벽스위치IDSIPS등네트워크장비의SYSLOG를취합:
    "방화벽, 스위치, IDS, IPS 등 네트워크 장비의 syslog 를 취합하기 위한 기능 제공",
  메세지_변경되었습니다_클릭시적용: "변경되었습니다.\n경로 팝업 하단 확인 버튼 클릭 시 적용됩니다.",
  메세지_변경된내용이없습니다: "변경된 내용이 없습니다.",
  메세지_변경된내용이있습니다_적용: "변경된 내용이 있습니다.\n적용하시겠습니까?",
  메세지_변경된내용이있습니다_취소: "변경된 내용이 있습니다.\n취소하시겠습니까?",
  메세지_변경사항적용하쉴: "변경사항을 적용하시겠습니까?",
  메세지_변경한비밀번호로다시로그인해라: "변경한 비밀번호로 다시 로그인해 주세요.",
  메세지_변경한정보저징확인클릭:
    "(변경한 정보를 저장하시려면 페이지에서 ‘확인’ 버튼을 클릭해 주세요.)",
  메세지_변경할_을_선택하세요: "변경할 %을 선택해 주세요.",
  메세지_변경할IP주소그룹명을입력하라: "변경할 IP주소그룹명을 입력해 주세요.",
  메세지_보안_n개_수정완료: "%개의 호스트가 %되었습니다.",
  메세지_복사되었다: "복사되었습니다.",
  메세지_복사하려는os와동일하다: "복사하려는 OS와 동일한 OS입니다.",
  메세지_복사하시겠습니까: "복사하시겠습니까?",
  메세지_복사할os선택하세요: "복사할 OS를 선택해 주세요.",
  메세지_복원사용기간입력: "복원설정 사용 기간을 입력하세요. (최대 20일)",
  메세지_복원승인대기중: "복원설정 요청 승인 대기중입니다.",
  메세지_복원시스템_삭제_1: "선택한 복원 호스트를 삭제하시겠습니까?",
  메세지_복원시스템_삭제_2: "삭제 시 복원된 로그도 삭제됩니다.",
  메세지_복원시스템_삭제_3: "삭제 시 로그량에 따라 수분의 시간이 걸릴 수 있습니다.",
  메세지_복원요청: "복원설정 요청이 필요합니다.",
  메세지_복원요청승인_승인일_시작일로_노출:
    "복원설정 요청을 승인하시겠습니까?\n승인일이 시작일로 노출됩니다.",
  메세지_복원진행: "복원을 진행합니다.",
  메세지_복원진행시간소요: "복원 시 로그량에 따라 수분의 시간이 걸릴 수 있습니다.",
  메세지_복원진행해제_해당회사_복원설정_불가능:
    "복원 진행을 해제하시겠습니까?\n해당회사의 복원설정이 불가능해집니다.",
  메세지_복원취소시_복원된_로그포함삭제:
    "진행중인 복원을 취소 하시겠습니까?\n취소하시면 복원된 로그도 삭제됩니다.",
  메세지_분류를_선택하세요: "분류를 선택해 주세요.",
  메세지_분류index입력해주세요: "분류 index를 입력해 주세요.",
  메세지_사용자정의설정확인: "등록된 서비스 정보가 없거나 사용자 정의 설정이 OFF로 되어 있습니다.",
  메세지_사용할_PIN번호를_입력해주세요: "사용할 PIN번호를 입력해주세요.",
  메세지_사용할컴플라이언스선택매핑정보확인:
    "사용할 컴플라이언스 선택하시면 매핑된 정보를 확인할 수 있습니다.",
  메세지_사용ID수값확인: "사용ID수는 1이상 99,999이하로 입력 가능합니다.",
  메세지_사용ID수를입력: "사용ID수를 입력해 주세요.",
  메세지_삭제되었습니다_클릭시적용: "삭제되었습니다.\n경로 팝업 하단 확인 버튼 클릭 시 적용됩니다.",
  메세지_삭제된시스템은Default그룹으로이동된다: "삭제된 호스트는 Default그룹으로 이동됩니다.",
  메세지_삭제불가: "등록된 게시물이 있어 삭제가 불가능합니다.",
  메세지_삭제실패: "삭제가 실패하였습니다.",
  메세지_삭제완료: "삭제 되었습니다.",
  메세지_삭제하쉴: "삭제하시겠습니까?",
  메세지_삭제확인_등록게시물: "삭제하시겠습니까?\n등록된 게시물이 없어야 삭제가 가능합니다.",
  메세지_삭제확인:
    "삭제 명령어가 실행됩니다.\n 삭제 명령어 실행 후에는 ON 변경이 불가능합니다.\n OFF 처리 하시겠습니까?",
  메세지_상관필터그룹공격확률입력:
    "상관필터 Group의 공격 확률은 첫 번째 Group만 0으로 지정해야 됩니다.",
  메세지_상태값입력: "상태값을 입력해 주세요.",
  메세지_상태값입력값확인: "상태값은 100이상 599이하로 입력 가능합니다.",
  메세지_새롭게생성된그룹탐지방법: "새롭게 생성된 그룹은 필터를 추가하셔야 탐지가 가능합니다.",
  메세지_서비스명을_선택하세요: "서비스명을 선택하세요.",
  메세지_서비스타입미선택: "서비스 타입 정보가 없습니다.",
  메세지_선택한테마적용하시겠습니까: "선택한 테마를 적용하시겠습니까?",
  메세지_설정체크: "설정을 체크해 주세요.",
  메세지_설정필터중시간별설정: "설정한 필터 중 시간별 탐지현황을 설정할 수 있습니다.",
  메세지_설정필터최신순확인: " 필터 발생 시 최신순으로 확인할 수 있습니다.",
  메세지_설정필터탐지카운트확인: "설정한 필터의 탐지 카운트를 확인할 수 있습니다.",
  메세지_설정하기필터추가필터정보가능:
    "설정하기를 통해 필터를 추가하면 추가한 필터의 정보 확인이 가능합니다.",
  메세지_세션종료시간을_변경하지_않았습니다: "세션종료시간을 변경하지 않았습니다.",
  메세지_속성값선택: "속성값을 선택해 주세요.",
  메세지_속성선택: "속성을 선택해 주세요.",
  메세지_수정실패: "수정이 실패 하였습니다.",
  메세지_수정완료: "수정 되었습니다.",
  메세지_수정이전데이터복사합니다:
    "수정이전의 데이터를 복사합니다. 수정데이터 복사가 필요하다면, 수정완료 후 복사해 주세요.",
  메세지_수정하쉴: "수정하시겠습니까?",
  메세지_승인_후_복원설정_가능: "승인이 완료되면 복원설정이 가능합니다.",
  메세지_시간선택: "시간을 선택해 주세요.",
  메세지_시간설정다시: "시작시간과 종료시간이 동일합니다. 다시 설정해 주세요.",
  메세지_시간을선택하세요: "시간을 선택해 주세요.",
  메세지_시간을설정: "시간을 설정해 주세요.",
  메세지_시도횟수값확인: "시도횟수는 1이상 9,999이하로 입력 가능합니다.",
  메세지_시도횟수를입력: "시도횟수를 입력해 주세요.",
  메세지_시스템그룹명_입력하세요: "호스트그룹명을 입력해 주세요.",
  메세지_시스템그룹선택: "호스트그룹을 선택하세요.",
  메세지_시스템그룹을_선택: "호스트그룹을 선택해 주세요.",
  메세지_시스템로그_n개_수정완료: "%개의 호스트가 수정되었습니다.",
  메세지_시스템목록갱신중: "호스트 목록을 가져오고 있습니다.\n잠시만 기다려 주세요.",
  메세지_시작합니다: "% 시작합니다.",
  메세지_실행완료: "실행되었습니다.",
  메세지_아이디로사용하는이메일은수정불가능하다: "아이디로 사용하는 이메일로 수정이 불가능합니다.",
  메세지_업데이트실패실패시재시도:
    "* 서버 및 네트워크 상태에 따라 업데이트가 실패할 수 있습니다. 실패 시 재시도 해주세요.",
  메세지_업데이트필요모드프루라전체업데이트하쉴:
    "* 업데이트가 필요한 모드프루라 리스트입니다. 전체 업데이트하려면 아래 체크박스를 선택해 주세요.",
  메세지_업데이트확인: "업데이트 현황 페이지에서 진행사항을 확인할 수 있습니다.",
  메세지_요소가등록되어있어야합니다: "요소가 등록되어 있어야 합니다.",
  메세지_요소값입력해주세요: "요소 값을 입력해주세요.",
  메세지_요소선택: "요소를 선택해 주세요.",
  메세지_요소수선택: "요소수를 선택해 주세요.",
  메세지_요일선택해주세요: "요일을 선택해주세요.",
  메세지_요청후7일이내고객지원이루어지고7일이후엔종료된다:
    "요청 후 7일 이내 고객지원이 이루어지며 7일 이후에는 종료됩니다.",
  메세지_운영체제ProgramName1차구분자입력하세요:
    "운영체제, ProgramName, 1차 구분자를 입력해 주세요.",
  메세지_원본로그포함여부경고:
    "원본로그포함 시 연동사이트 정책상 정상적으로 티켓이 발행되지 않을 수 있습니다.",
  메세지_원하는곳에마우스우측버튼으로붙여넣기해라:
    "원하는 곳에 마우스 우측 버튼으로 붙여넣기해 주세요.",
  메세지_웹로그수집시작한다: "웹로그 수집을 시작합니다.",
  메세지_웹로그수집중지한다: "웹로그 수집을 중지합니다.",
  메세지_웹모듈을_삭제합니다: "웹모듈을 삭제합니다.",
  메세지_웹방화벽로그전송설정: "웹방화벽 로그전송 설정 (PLURA 운영자용)",
  메세지_웹방화벽설정할수있음: "웹방화벽 예외설정을 할 수 있습니다.",
  메세지_웹탐지예외설정예시: `예) 000 <span class="c-red">XXXXXXXXXXX</span>일때, <span>000</span>`,
  메세지_위항목은등록후수정이불가합니다: "위 항목은 등록 후 수정이 불가능합니다.",
  메세지_위험도별설정체크: "위험도별 설정을 체크해 주세요.",
  메세지_윈도우는지원하지않음: "윈도우는 지원하지 않습니다.",
  메세지_유료서비스를이용해더강력한해킹서비스와혜택받아보세요: ` <span class="c-blue bold bold p-r-5">Standard, Gold, Platinum, Premium</span>서비스를이용하여 더 강력한 해킹탐지서비스와 혜택을 받아보세요.`,
  메세지_유형중복: "이미 등록된 유형입니다.",
  메세지_응답크기입력: "응답크기를 입력해 주세요.",
  메세지_응답크기입력값확인: "응답크기의 최솟값이 최댓값보다 큽니다.",
  메세지_응답크기최댓값확인: "응답크기의 최댓값은 9,999,999이하 입니다.",
  메세지_이동됐다: "이동되었습니다.",
  메세지_이미_등록된_컬렉션명입니다: "이미 등록된 컬렉션명입니다.",
  메세지_이미_적용된_공격유형입니다: "이미 적용된 공격유형입니다.",
  메세지_이미등록된목록명: "이미 등록된 목록명입니다.",
  메세지_이미등록된이벤트타입입니다: "이미 등록된 이벤트 타입입니다.",
  메세지_이미등록된코드: "이미 등록된 코드입니다.",
  메세지_이미업데이트: "이미 업데이트가 진행중입니다.",
  메세지_이벤트타입미선택: "이벤트 타입 정보가 없습니다.",
  메세지_이용약관동의하면고객지원요청된다: "이용약관 동의를 해주시면 고객지원이 요청됩니다.",
  메세지_이용약관에동의한다: "위의 이용약관에 동의합니다.",
  메세지_이후고객지원필요시재요청필요하다: "이후 고객지원 필요 시 재요청이 필요합니다.",
  메세지_인증URL클릭하면회원가입완료된다: "인증 URL을 클릭 하시면 회원가입이 완료됩니다.",
  메세지_입력된정보를확인하세요: "입력된 정보를 확인해 주세요.",
  메세지_입력하신이메일로인증메일발송되며인증되야회원가입완료이다:
    "입력하신 이메일로 인증메일이 발송되며 인증 완료 시 회원가입 됩니다.",
  메세지_자동입력방지안내: `비밀번호를 <span class="c-white">%회 이상 잘못 입력하면,</span></span><br><span class="dis-inline-b f-12 c-gray-light">정보보호를 위해 자동입력방지 후 로그인하셔야 합니다.</span>`,
  메세지_자동입력방지확인: "비밀번호와 자동입력방지를 확인해 주세요.",
  메세지_잠금처리되었습니다수정해재등록해주시기바랍니다:
    "잠금처리되었습니다. 수정해 재등록해주시기 바랍니다.",
  메세지_잠김해제하쉴: "% 잠김을 해제하시겠습니까?",
  메세지_재시도버튼클릭: "재시도 버튼 클릭해 주세요.",
  메세지_재정렬되었습니다: "재정렬되었습니다.",
  메세지_재정렬후_저장_가능: "재정렬후 저장 가능",
  메세지_저장되었습니다: "저장되었습니다.",
  메세지_저장하쉴: "저장하시겠습니까?",
  메세지_적용하시겠습니까: "적용하시겠습니까?",
  메세지_전화번호를_입력해주세요: "- 없이 번호를 입력해 주세요.",
  메세지_접속하기:
    "접속 시 로그인된 어드민 및 PLURA에서 로그아웃됩니다.\n해당 업체 PLURA에 접속하시겠습니까?",
  메세지_정렬대상서비스를선택하세요: "정렬 대상 서비스를 선택하세요.",
  메세지_정보삭제후진행가능합니다: "정보 삭제 후 진행 가능합니다.",
  메세지_제목을_입력해주세요: "제목을 입력해 주세요",
  메세지_중복된목록명: "중복된 목록명입니다.",
  메세지_지원시작: "접속 계정이 생성됩니다.\n시작하시겠습니까?",
  메세지_지원종료: "접속 계정이 비활성화됩니다.\n종료하시겠습니까?",
  메세지_진행상황_복원히스토리페이지: "진행사항은 [복원 히스토리] 페이지에서 확인해주세요.",
  메세지_차단IP삭제: "삭제 시 차단이 해제됩니다.\n삭제하시겠습니까?",
  메세지_채널단일설정: "(필터 등록 시 이벤트타입 선택 영역 히든처리)",
  메세지_채널을_선택하세요: "채널을 선택해 주세요.",
  메세지_초대하였습니다: "초대하였습니다.",
  메세지_초대할사람이메일입력하라: "초대할 사람의 이메일을 입력해 주세요.",
  메세지_최대30개까지등록된다: "최대 30개까지 등록됩니다.",
  메세지_최대초입력가능: "최대 % 입력 가능",
  메세지_최대n개IP주소등록: `<span class="c-red">최대 %개의 IP주소를 등록</span>할 수 있습니다.`,
  메세지_최신버전: "최신 버전입니다.",
  메세지_추가됐다: "추가되었습니다",
  메세지_추가할_필터분류를_선택하세요: "추가할 필터분류를 선택해 주세요.",
  메세지_추가할멤버의정보입력해라: "추가할 멤버의 정보를 입력해 주세요.",
  메세지_취소되었습니다: "취소되었습니다.",
  메세지_컴플라이언스삭제하쉴: "선택한 컴플라이언스를 삭제하시겠습니까?",
  메세지_코드입력: "코드를 입력해 주세요.",
  메세지_키워드별상세설정안내: "키워드별 상세 설정을 할 수 있습니다.",
  메세지_탐지_우선순위_적용을_해지하쉴: "탐지 우선순위 적용을 해지하시겠습니까?",
  메세지_탐지노출입력: "탐지 노출 0을 입력할 수 없습니다.",
  메세지_탐지시간입력: "탐지 시간은 0 초과 %초 이하를 입력해 주세요.",
  메세지_탐지우선순위설정취소:
    "수정값이 저장되지 않고 우선순위 목록 화면으로 변경됩니다. \n 취소하시겠습니까?",
  메세지_테마가적용되었습니다: "테마가 적용되었습니다.",
  메세지_테마선택하세요: "테마를 선택해 주세요.",
  메세지_테마저장하지않고이동됩니다: "선택한 테마가 저장되지 않고 이동됩니다.",
  메세지_특정필터탐지카운트정보비교: "특정 필터의 탐지 카운트를 1일 전 정보와 비교할 수 있습니다.",
  메세지_특정Key를입력하세요: "특정 key 설정 > Key를 입력해 주세요.",
  메세지_특정key분류index를선택하세요: "특정 key 설정 > 분류 index를 선택해 주세요.",
  메세지_특정key분류index와key확인하세요:
    "특정 key 설정 > 분류 index, Key를 확인해 주세요.\n([분류 index & Key]가 한쌍으로 동일한 [index & Key]는 존재할 수 없습니다.",
  메세지_특정KeyValueIndex를입력하세요: "특정 key 설정 > Value Index 를 입력해 주세요.",
  메세지_티켓발행시원본로그포함: "티켓 발행 시 원본로그포함",
  메세지_티켓사이트삭제:
    "삭제 시 이미 사용중인 고객의 경우 티켓발행 정보가 사라지며 재발행이 가능한 상태로 변경됩니다.\n또한 연동된 버전 및 파라미터 정보도 삭제됩니다.\n삭제하시겠습니까?",
  메세지_티켓이발행됐다: "티켓이 발행되었습니다.",
  메세지_티켓OFF하쉴:
    "OFF 설정시 이미 사용중인 고객의 경우 티켓발행 정보가 사라지며 재발행이 가능한 상태로 변경됩니다.\nOFF 처리하시겠습니까?",
  메세지_파일업로드실패: "파일 업로드가 실패했습니다.",
  메세지_파일업로드완료: "파일 업로드 완료됐습니다.",
  메세지_핀번호를_입력하세요: "PIN번호를 입력하세요.",
  메세지_필터레벨선택: "필터레벨을 선택해주세요.",
  메세지_필터명선택: "필터명을 선택해 주세요.",
  메세지_필터명입력: "필터명을 입력해 주세요.",
  메세지_필터명입력필요: "필터명을 입력해 주세요.",
  메세지_필터상태OFF하쉴: "선택한 필터의 상태를 OFF 하시겠습니까?",
  메세지_필터상태ON하쉴:
    "선택한 필터의 상태를 ON 하시겠습니까? \n ON 설정 시 필터가 동작하며 보안필터에 탐지됩니다.",
  메세지_필터상태ON하시겠습니까: "선택한 필터의 상태를 ON 하시겠습니까?",
  메세지_필터중복: "이미 등록된 필터입니다.",
  메세지_하단특정key설정추가하세요: "하단 특정 key 설정을 추가해 주세요.",
  메세지_항목선택: "항목을 선택해 주세요.",
  메세지_항목체크: "항목을 체크해 주세요.",
  메세지_해지되었습니다: "해지되었습니다.",
  메세지_허용IP주소를입력해주세요: "허용IP주소를 입력해 주세요.",
  메세지_호스트_종료: "호스트를 종료합니다.",
  메세지_호스트입력: "호스트를 입력해 주세요.",
  메세지_호스트정보도도시적용됩니다: "호스트 정보도 동시 적용됩니다.",
  메세지_호스트종료후_재시작수동: "호스트 종료 후 재시작은 수동으로 해야합니다.",
  메세지_확률n퍼센트입니다: "확률 %입니다.",
  메세지_확인후다시시도해주세요: "확인 후 다시 시도해주세요.",
  메세지_회원가입시등록한이메일정보를입력하시면해당이메일로임시비밀번호발송한다:
    "회원가입 시 등록한 이메일 정보를 입력하시면 해당 이메일로 임시 비밀번호가 발송됩니다.",
  메세지_회원가입완료되었다: "회원가입이 완료되었습니다.",
  메세지_회원가입하신이메일로인증URL발송했다: "회원가입하신 이메일로 인증URL이 발송되었습니다.",
  메세지_Audit활성화를위한안내: "Audit 활성화를 위하여 아래 1, 2번 중 선택 사용",
  메세지_burst를입력해주세요: "burst를 입력해 주세요.",
  메세지_CapsLock활성: "CapsLock이 켜져있습니다.",
  메세지_CNAME을입력해주세요: "CNAME을 입력해 주세요.",
  메세지_DataName치환: "치환할 대상과 값을 입력해 주세요.",
  메세지_Host를입력: "Host를 입력해 주세요.",
  메세지_HostPath선택: "Host, Path 선택 시 시간대별 통계를 확인 할 수 있습니다.",
  메세지_index는0부터시작합니다: "index는 0부터 시작합니다.",
  메세지_IP주소가_아래와_같이_등록됩니다: "IP주소가 아래와 같이 등록됩니다.",
  메세지_IP주소등록완료시차단: "IP주소 등록 완료 시 차단이 실행됩니다.",
  메세지_IP주소를입력해주세요: "IP주소를 입력해 주세요.",
  메세지_IP중복: "이미 등록된 IP주소입니다.",
  메세지_listenPort입력해주세요: "Listen port를 입력해 주세요.",
  메세지_ML탐지예외설정할수있다: "ML 탐지의 예외 설정을 할 수 있습니다.",
  메세지_n개선택했습니다: "%개가 선택되었습니다.",
  메세지_n님안녕하세요: "%님 안녕하세요!",
  메세지_n으로권한을변경합니다: "%로 권한을 변경합니다.",
  메세지_n표항목은필수입니다: "%표시 항목은 필수항목입니다.",
  메세지_NOOS: "운영체제를 선택해 주세요.",
  메세지_OFF설정시매핑된정보OFF처리하쉴:
    "OFF 설정 시 이미 서비스 매핑된 정보도 사라집니다. OFF 처리하시겠습니까?",
  메세지_ON설정사용자가이드: "(ON 설정 시 사용자가이드 입력, 필터상태 OFF)",
  메세지_ON설정서비스노출: "(ON 설정 시 서비스 노출)",
  메세지_ON설정시서비스노출ON처리하쉴: "ON 설정 시 서비스에 노출됩니다. ON 처리하시겠습니까?",
  메세지_ON설정시잠금이되며서비스에필터비활성화처리:
    "(ON 설정 시 잠금이 되며 서비스에 필터 비활성화 처리)",
  메세지_ON설정탐지노출: "ON 설정 시 탐지노출",
  메세지_ON설정필터적용: "(ON 설정 시 필터 적용)",
  메세지_ON설정필터ON상태: "(ON 설정 시 필터 ON 상태)",
  메세지_ON하쉴: "ON 처리하시겠습니까?",
  메세지_OS를_선택하세요: "OS를 선택해 주세요.",
  메세지_OTP메일번호가발송되었습니다: "OTP메일 번호가 발송되었습니다.",
  메세지_Passphrase값확인: "Passphrase값은 1~32자리입니다.",
  메세지_PLURA_관리자가_내용확인_후_답변_드릴_예정입니다:
    "PLURA 관리자가 내용확인 후 답변 드릴 예정입니다.",
  메세지_PLURA제공_보안필터전용: "PLURA 제공 보안필터 전용",
  메세지_Port입력해주세요: "Port를 입력해 주세요.",
  메세지_rate를입력해주세요: "rate를 입력해 주세요.",
  메세지_SSL_CRT값확인: "SSL CRT값을 1000자리 이상 입력해주세요.",
  메세지_SSL_Key값확인: "SSL Key값을 1000자리 이상 입력해주세요.",
  메세지_Timestamp유형은한개만등록가능합니다: "Timestamp 유형은 한개만 등록 가능합니다.",
  메세지_Windows2008자동설치미지원:
    "Windows Server 2008용 IIS (Internet Information Server) 자동 설치 미지원",
  메세지: "메세지",
  메소드: "메소드",
  메시지_경로를등록해야업로드가능합니다: "경로를 등록해야 업로드 가능합니다.",
  메시지_변경내용적용: "변경된 내용이 있습니다.\n적용하시겠습니까?",
  메시지_변경내용취소: "변경된 내용이 있습니다.\n취소하시겠습니까?",
  메시지_삭제완료10초이상소요: "삭제 완료까지 10초 이상의 시간이 소요될 수 있습니다.",
  메시지_서버등록후경로입력가능: "서버 등록 후 경로 입력 가능",
  메시지_자식서버가등록되었습니다: "자식 서버가 등록되었습니다.",
  메시지_자식시스템_n개_동시삭제: `<span class="c-red">선택된 부모호스트(들)에 등록된 자식호스트 %개가 동시 삭제</span>됩니다.`,
  메시지_IP차단을위해이유를적으세요: "IP주소를 차단하시겠습니까?\n차단 이유를 적어주세요.",
  메인: "메인",
  메일발송내용: "메일발송내용",
  메일보내기: "메일보내기",
  메타_정의_후_사용가능합니다: "메타 정의 후 사용가능합니다.",
  메타_정의: "메타 정의",
  메타서비스관리: "메타서비스관리",
  멤버_수: "멤버 수",
  멤버_이름_입력: "멤버 이름 입력",
  멤버: "멤버",
  멤버관리: "멤버관리",
  멤버수: "멤버수",
  멤버초대_초대현황: "멤버초대/초대현황",
  멤버초대: "멤버초대",
  멤버추가: "멤버추가",
  명령어_선택: "명령어 선택",
  명령어_입력_실행: "명령어 입력 실행",
  명령어_입력: "명령어 입력",
  명령어_히스토리: "명령어 히스토리",
  명령어: "명령어",
  모드프루라_등록: "모드프루라 등록",
  모드프루라_버전관리: "모드프루라 버전관리",
  모드프루라_수정: "모드프루라 수정",
  모드프루라_업데이트: "모드프루라 업데이트",
  모드프루라_전체_업데이트: "모드프루라 전체 업데이트",
  모드프루라_종류_버전: "모드프루라(종류/버전)",
  모드프루라_종류: "모드프루라 종류",
  모든그룹: "모든그룹",
  모든서버그룹: "모든서버그룹",
  모든서버그룹선택: "모든서버그룹선택",
  목: "목",
  목록_등록: "목록 등록",
  목록: "목록",
  목록그룹_등록: "목록그룹 등록",
  목록그룹명_변경: "목록그룹명 변경",
  목록등록: "목록등록",
  목록명_변경: "목록명 변경",
  목록명: "목록명",
  목요일: "목요일",
  무료_체험기간: "무료(체험기간)",
  무료: "무료",
  무료체험기간: "무료체험기간",
  무료평가판시작: "무료 평가판 시작",
  묶음: "묶음",
  문의하기: "문의하기",
  문자형: "문자형",
  미동작: "미동작",
  미등록: "미등록",
  미등록된_주소: "미등록된 주소",
  미리보기: "미리보기",
  미사용: "미사용",
  미수집: "미수집",
  미업로드: "미업로드",
  미인증: "미인증",
  미저장: "미저장",
  미적용: "미적용",
  미제공: "미제공",
  미제한: "미제한",
  미취합: "미취합",
  미확인_IP: "미확인 IP",
  바로가기: "바로가기",
  바이패스: "바이패스",
  박스플롯: "박스플롯",
  발급필요: "발급필요",
  발급하기_버튼을_클릭하시면_생성: "발급하기 버튼을 클릭하시면 임시비밀번호가 생성됩니다.",
  발급하기: "발급하기",
  발생_횟수: "발생 횟수",
  발생: "발생",
  발생일: "발생일",
  발행: "발행",
  발행일: "발행일",
  발행자: "발행자",
  발행티켓: "발행티켓",
  방금: "방금",
  방어_명령어_입력: "방어 명령어 입력",
  방어: "방어",
  방어관리: "방어관리",
  방어구분: "방어구분",
  방어대상: "방어대상",
  방어로그: "방어로그",
  방어상태: "방어상태",
  방어실행: "방어실행",
  방어원인: "방어원인",
  방어IP주소: "방어IP주소",
  방화벽_유형: "방화벽 유형",
  방화벽: "방화벽",
  방화벽설정: "방화벽설정",
  백업: "백업",
  백업루트: "백업루트",
  버전_선택: "버전 선택",
  버전_출력_예시: "버전 출력 예시",
  버전_확인_API: "버전 확인 API",
  버전_n_다운로드_링크: "버전 % 다운로드 링크",
  버전: "버전",
  버전에_따른_재시작_예시: "위의 예시로 확인된 버전에 따른 재시작 예시",
  버전에버전확인: "버전에 따라 서비스명이 다르므로 버전 확인",
  변경_시점: "변경 시점",
  변경: "변경",
  변경할_권한: "변경할 권한",
  변수_설명: "변수 설명",
  변환: "변환",
  보고서_네트워크: "보고서_네트워크",
  보고서_메일: "보고서 메일",
  보고서_박스플롯: "보고서_박스플롯",
  보고서_분류: "보고서 분류",
  보고서_분류별_탐지_현황: "보고서 분류별 탐지 현황",
  보고서_상관분석: "보고서_상관분석",
  보고서_시스템: "보고서_호스트",
  보고서_알림: "보고서 알림",
  보고서_웹: "보고서_웹",
  보고서_히트맵: "보고서_히트맵",
  보고서_IP주소: "보고서_IP주소",
  보고서: "보고서",
  보고서메일: "보고서메일",
  보기: "보기",
  보안_유료: "보안(유료)",
  보안: "보안",
  보안탐지: "보안탐지",
  보안필터_등록: "보안필터 등록",
  보안필터_수정: "보안필터 수정",
  보안필터: "보안필터",
  보안필터수정: "보안필터수정",
  복구: "복구",
  복사_위치: "복사 위치",
  복사: "복사",
  복수: "복수",
  복원: "복원",
  복원기간: "복원기간",
  복원대상: "복원대상",
  복원목적: "복원목적",
  복원서비스_사용기간: "복원서비스 사용 기간",
  복원서비스: "복원서비스",
  복원설정: "복원설정",
  복원설정요청: "복원설정요청",
  복원설정요청관리: "복원설정요청관리",
  복원시간: "복원시간",
  복원시스템: "복원호스트",
  복원시스템관리: "복원호스트관리",
  복원시작: "복원시작",
  복원이용약관_1: "복원설정 요청한 기간 동안에만 복원설정이 가능합니다.",
  복원이용약관_2: "하루에 10건의 복원이 가능합니다. (완료된 복원 기준)",
  복원이용약관_3: "복원된 로그는 복원된 시점부터 30일간 조회 가능합니다.",
  복원이용약관_4: "복원된 로그는 조회가 가능한 상태에서 재복원 되지 않습니다.",
  복원히스토리: "복원히스토리",
  본문: "본문",
  볼륨_메트릭: "볼륨 메트릭",
  부모_시스템: "부모(P) 호스트",
  부분삭제: "부분삭제",
  부서_입력: "부서 입력",
  부서: "부서",
  분: "분",
  분류_관리: "분류 관리",
  분류_선택: "분류 선택",
  분류_index_관리: "분류 index 관리",
  분류_index: "분류 index",
  분류: "분류",
  분류관리: "분류관리",
  분류별_탐지_분포: "분류별 탐지 분포",
  분류별_탐지_현황: "분류별 탐지 현황",
  분류수정: "분류수정",
  분석_변경_히스토리: "분석 변경 히스토리",
  분석: "분석",
  분석정보_영역에_정보를_노출함: "분석정보 영역에 정보를 노출함",
  분석정보: "분석정보",
  분포: "분포",
  불가능: "불가능",
  불러오기: "불러오기",
  불일치: "불일치",
  뷰: "뷰",
  비교_테이블_생성_실패: "비교 테이블 생성 실패",
  비교_테이블_생성_완료: "비교 테이블 생성 완료",
  비교_테이블_생성: "비교 테이블 생성",
  비교_테이블_생성합니다: "비교 테이블을 생성합니다.",
  비교_필터_리스트: "비교 필터 리스트",
  비교: "비교",
  비밀번호_변경_시점_n일_초과: `비밀번호를 변경한지 <span class="c-red">%일</span>이 지났습니다.`,
  비밀번호_변경_안내: "비밀번호 변경 안내",
  비밀번호_변경_요청: "비밀번호를 주기적으로 변경해주세요.",
  비밀번호_변경_후_로그아웃: "비밀번호 변경 후 기존 계정은 로그아웃됩니다.",
  비밀번호_변경_후_서비스_이용: "비밀번호를 주기적으로 변경해 서비스를 이용하세요.",
  비밀번호_변경: "비밀번호 변경",
  비밀번호_변경을_권고: "비밀번호 변경을 권고해 주시기 바랍니다!",
  비밀번호_변경하기: "비밀번호 변경하기",
  비밀번호_생성_규칙_관리: "비밀번호 생성 규칙 관리",
  비밀번호_설정_완료: "비밀번호가 설정되었습니다.",
  비밀번호_입력: "비밀번호 입력",
  비밀번호_재입력: "비밀번호 재입력",
  비밀번호_찾기: "비밀번호 찾기",
  비밀번호_확인: "비밀번호 확인",
  비밀번호: "비밀번호",
  비밀번호를_변경해주세요: "비밀번호를 변경해주세요.",
  빌드: "빌드",
  사용_컴플라이언스: "사용 컴플라이언스",
  사용: "사용",
  사용된_변수: "사용된 변수",
  사용로그: "사용로그",
  사용불가: "사용불가",
  사용설정: "사용설정",
  사용여부: "사용여부",
  사용자_가이드: "사용자 가이드",
  사용자_값_선택입력: "사용자 값 선택입력",
  사용자_설정: "사용자 설정",
  사용자_에이전트: "사용자-에이전트",
  사용자_정보: "사용자 정보",
  사용자_정의: "사용자 정의",
  사용자_환경에_따라_아래_패키지가_필요: "사용자 환경에 따라 아래 패키지가 필요",
  사용자: "사용자",
  사용자가이드: "사용자가이드",
  사용자설정: "사용자설정",
  사용자에이전트: "사용자에이전트",
  사용자정의: "사용자정의",
  사용자필터: "사용자필터",
  사용자필터수정: "사용자필터수정",
  사용중: "사용중",
  사용하시는_장비에서_설정: "사용하시는 장비에서 설정",
  사용ID수: "사용ID수",
  사이: "사이",
  사전_n_설치_필요: "사전 % 설치 필요",
  삭제: "삭제",
  삭제가_불가능합니다_등록업체문제: "삭제가 불가능합니다.\n등록 업체 삭제 후 시도해주세요.",
  삭제가_불가능합니다_리소스문제: "삭제가 불가능합니다.\n리소스 삭제 후 시도해주세요.",
  삭제가_불가능합니다_메타문제: "삭제가 불가능합니다.\n메타 삭제 후 시도해주세요.",
  삭제가_불가능합니다_적용업체문제: "삭제가 불가능합니다.\n적용업체 삭제 후 시도해주세요.",
  삭제된_정보입니다: "삭제된 정보입니다.",
  삭제명령어: "삭제명령어",
  삭제시스템: "삭제호스트",
  삭제취소: "삭제취소",
  삭제하기: "삭제하기",
  상관_분석_방어: "상관 분석 방어",
  상관보안필터시작: "상관보안필터시작",
  상관분석_방어필터: "상관분석 방어필터",
  상관분석_보고서: "상관분석 보고서",
  상관분석_보안탐지: "상관분석 보안탐지",
  상관분석_보안필터_등록: "상관분석 보안필터 등록",
  상관분석_보안필터_수정: "상관분석 보안필터 수정",
  상관분석_보안필터: "상관분석 보안필터",
  상관분석_수정: "상관분석 수정",
  상관분석: "상관분석",
  상관필터_분류: "상관필터 분류",
  상관필터_시작: "상관필터 시작",
  상관필터_Group_추가: "상관필터 Group 추가",
  상단_영역_노출: "상단 영역 노출",
  상세_설정: "상세 설정",
  상세: "상세",
  상태_코드: "상태 코드",
  상태: "상태",
  상태값: "상태값",
  새_비밀번호_확인: "새 비밀번호 확인",
  새_비밀번호: "새 비밀번호",
  새_PIN번호_숫자_6자리_입력: "새 PIN번호 숫자 6자리 입력",
  새_PIN번호_숫자_6자리_재입력: "새 PIN번호 숫자 6자리 재입력",
  새로운_키워드명: "새로운 키워드명",
  새창: "새창",
  새창보기: "새창보기",
  생성_제한_공통_규칙: "생성 제한 공통 규칙",
  생성: "생성",
  생성업체: "생성업체",
  생성일: "생성일",
  생성하기: "생성하기",
  샤드관리: "샤드관리",
  서버_그룹_등록: "서버 그룹 등록",
  서버_그룹: "서버 그룹",
  서버_등록_필요: "서버 등록 필요",
  서버_총_n개: "서버 총 %개",
  서버: "서버",
  서버관리: "서버관리",
  서버그룹_등록: "서버그룹 등록",
  서버그룹_선택: "서버그룹 선택",
  서버그룹_설정: "서버그룹 설정",
  서버그룹_수정: "서버그룹 수정",
  서버그룹_전체: "서버그룹 전체",
  서버그룹: "서버그룹",
  서버그룹관리: "서버그룹관리",
  서버그룹명: "서버그룹명",
  서버그룹선택: "서버그룹선택",
  서버등록: "서버등록",
  서버리스트: "서버리스트",
  서버목록_선택: "서버목록 선택",
  서버수: "서버수",
  서버수정: "서버수정",
  서버정보: "서버정보",
  서버추가: "서버추가",
  서버IP주소_입력: "서버IP주소 입력",
  서버IP주소: "서버IP주소",
  서비스_등록: "서비스 등록",
  서비스_수정: "서비스 수정",
  서비스_실행: "서비스 실행",
  서비스_이용_재로그인_필요: "서비스를 이용하려면 재로그인이 필요합니다.",
  서비스_전환_신청하기: "서비스 전환 신청하기",
  서비스: "서비스",
  서비스관리: "서비스관리",
  서비스그룹별_웹훅: "서비스그룹별 웹훅",
  서비스그룹별_Syslog: "서비스그룹별 Syslog",
  서비스그룹별: "서비스그룹별",
  서비스대상_필터키_입력: "서비스대상/필터키 입력",
  서비스대상_필터키: "서비스대상/필터키",
  서비스등록: "서비스등록",
  서비스리젼: "서비스리젼",
  서비스명_확인_경로_예시: "서비스명 확인 경로 예시",
  서비스명: "서비스명",
  서비스별_공격_IP주소_현황: "서비스별 공격 IP주소 현황",
  서비스별_로그발생: "서비스별_로그발생",
  서비스별_상세_확인하기: "서비스별 상세 확인하기",
  서비스별_탐지_분포: "서비스별 탐지 분포",
  서비스별_탐지_양: "서비스별 탐지 양",
  서비스별_탐지발생_현황: "서비스별 탐지발생 현황",
  서비스별: "서비스별",
  서비스종류: "서비스종류",
  서비스코드: "서비스코드",
  선별_예외_설정: "선별, 예외 설정",
  선별: "선별",
  선택_값_선택: "선택 값 선택",
  선택_취소: "선택 취소",
  선택: "선택",
  선택국가: "선택국가",
  선택삭제: "선택삭제",
  선택입력_자동정렬: "선택입력(자동 정렬)",
  선택입력: "선택입력",
  선택필터: "선택필터",
  선택한_n개: "선택한 %개",
  설명_호스트명: "설명(호스트명)",
  설명: "설명",
  설명입력: "설명입력",
  설정_값: "설정 값",
  설정_필요한_필터: "설정 필요한 필터",
  설정: "설정",
  설정검사: "설정검사",
  설정된_필터리스트: "설정된 필터리스트",
  설정하기: "설정하기",
  설치_매뉴얼: "설치 매뉴얼",
  설치_안내: "설치 안내",
  설치: "설치",
  설치순서를_따라_진행합니다: "설치 순서를 따라 진행합니다.",
  설치시_궁금한_내용은_고객센터로_연락: `설치시 궁금한 내용은 고객지원센터(<span class="c-red">support@qubitsec.com, 070-8802-0306</span>)로 연락 주십시오.`,
  설치시스템: "설치호스트",
  설치할_에이전트의_운영체제를_선택합니다: "설치할 에이전트의 운영체제(OS)를 선택합니다.",
  성공: "성공",
  성공건: "성공건",
  세션: "세션",
  세션관리: "세션관리",
  세션종료_시간: "세션종료 시간",
  세션코드: "세션코드",
  소속그룹: "소속그룹",
  소속멤버: "소속멤버",
  소스_디비_최신: "소스 디비 (최신)",
  소스디비_값: "소스디비 값",
  소스디비_최신: "소스 디비 (최신)",
  소스보기: "소스보기",
  소스SQL: "소스SQL",
  소프트웨어_등록: "소프트웨어 등록",
  소프트웨어_매핑: "소프트웨어 매핑",
  소프트웨어_수정: "소프트웨어 수정",
  소프트웨어: "소프트웨어",
  소프트웨어관리: "소프트웨어관리",
  소프트웨어등록: "소프트웨어등록",
  소프트웨어명: "소프트웨어명",
  소프트웨어ID: "소프트웨어ID",
  속성_값_선택: "속성 값 선택",
  속성_선택: "속성 선택",
  속성: "속성",
  속성값_복수_입력시_사용: "속성값 복수 입력시 사용",
  솔라시스템관리: "솔라시스템관리",
  수_정: "수 정",
  수: "수",
  수동: "수동",
  수동차단: "수동차단",
  수요일: "수요일",
  수정_예시: "수정 예시",
  수정: "수정",
  수정일: "수정일",
  수정자_수정일: "수정자/수정일",
  수정자: "수정자",
  수정하기: "수정하기",
  수정할_번호_입력: "수정할 번호 입력",
  수정할_이름_입력: "수정할 이름 입력",
  수정할_회사명_입력: "수정할 회사명 입력",
  수집_서버: "수집 서버",
  수집: "수집",
  수치: "수치",
  순서: "순서",
  순차_발생: "순차 발생",
  숫자_6자리: "숫자 6자리",
  숫자_연속_6자리_이상: "숫자 연속 6자리 이상",
  숫자_영문_특문_조합_9자리:
    "숫자 (1 ~ 0), 영문 대소문자 (a ~ z, A ~ Z), 특수문자(!, @, #, $, % 등) 조합 9자리 이상",
  숫자_영어대소문자_특수문자_9자리_이상:
    "숫자, 영문 대소문자, 특수문자 조합 9자리 이상으로 등록해주세요.",
  숫자형: "숫자형",
  스코어: "스코어",
  스키마_비교: "스키마 비교",
  스토리지_등록: "스토리지 등록",
  스토리지_수정: "스토리지 수정",
  스토리지: "스토리지",
  스토리지관리: "스토리지관리",
  스토리지매핑_등록: "스토리지매핑 등록",
  스토리지매핑_수정: "스토리지매핑 수정",
  스토리지명: "스토리지명",
  스토리지사용정보: "스토리지사용정보",
  스토리지타입: "스토리지타입",
  스토리지항목_등록: "스토리지항목 등록",
  스토리지항목: "스토리지항목",
  스토리지항목관리: "스토리지항목관리",
  스토리지항목주소: "스토리지항목주소",
  승인: "승인",
  승인자: "승인자",
  시: "시",
  시간_설정: "시간 설정",
  시간: "시간",
  시간대: "시간대",
  시간대별_Login_분석: "시간대별 Login 분석",
  시간별_로그발생_현황: "시간별 로그발생 현황",
  시간별_로그발생: "시간별_로그발생",
  시간별_로그업로드_분포: "시간별 로그업로드 분포",
  시간별_로그업로드_현황: "시간별 로그업로드 현황",
  시간별_총_탐지발생_현황: "시간별 총 탐지발생 현황",
  시간별_탐지_현황_그래프: "시간별 탐지 현황 그래프",
  시간별_탐지로그발생_현황: "시간별 탐지로그 발생 현황",
  시간별_탐지로그발생: "시간별_탐지로그발생",
  시간선택: "시간선택",
  시간설정: "시간설정",
  시도: "시도",
  시도응답인증: "시도응답인증",
  시도횟수: "시도횟수",
  시스로그_업로드_합계: "시스로그 업로드 합계",
  시스로그_업로드: "시스로그 업로드",
  시스로그: "시스로그",
  시스로그미업로드시스템: "시스로그 미업로드 호스트",
  시스템_단일필터_등록: "호스트 단일필터 등록",
  시스템_단일필터_수정: "호스트 단일필터 수정",
  시스템_단일필터: "호스트 단일필터",
  시스템_단일필터추가: "호스트 단일필터추가",
  시스템_등록_필요: "호스트 등록 필요",
  시스템_등록: "호스트 등록",
  시스템_로그_취합은_자동_지원: "호스트 로그 취합은 자동 지원",
  시스템_방어필터: "호스트 방어필터",
  시스템_수정: "호스트 수정",
  시스템_시스템관리_호스트선택_호스트등록: "시스템 > 시스템관리 > 취합호스트 선택 > 호스트등록",
  시스템_업로드: "호스트 업로드",
  시스템_웹: "호스트/웹",
  시스템_입력: "호스트 입력",
  시스템_전체: "호스트 전체",
  시스템_전체로그: "호스트 전체로그",
  시스템_총_n개: "호스트 총 %개",
  시스템_필터탐지: "호스트 필터탐지",
  시스템: "호스트",
  시스템관리: "시스템관리",
  시스템등록: "시스템등록",
  시스템로그_모듈_설치: "호스트로그 모듈 설치",
  시스템로그_방어: "호스트로그 방어",
  시스템로그_분석: "호스트로그 분석",
  시스템로그_수집_경로: "호스트로그 수집 경로",
  시스템로그_수집: "호스트로그 수집",
  시스템로그_업로드_수: "호스트로그 업로드 수",
  시스템로그_업로드: "호스트로그 업로드",
  시스템로그: "호스트로그",
  시스템정보: "호스트정보",
  시스템추가: "호스트추가",
  시스템필터_추가: "<strong>호스트필터</strong>추가",
  시작: "시작",
  시작일: "시작일",
  식별: "식별",
  식별키_트리값: "식별키 트리값",
  신규: "신규",
  신규필터: "신규필터",
  실시간방어: "실시간방어",
  실시간탐지: "실시간탐지",
  실패: "실패",
  실패건: "실패건",
  실행: "실행",
  실행명령어: "실행명령어",
  심각도_설정: "심각도 설정",
  심각도: "심각도",
  심각도별: "심각도별",
  아이디_이메일_입력: "아이디(이메일) 입력",
  아이디_이메일: "아이디(이메일)",
  아이디_IP주소_날짜_검색: "아이디, IP주소, 날짜 검색",
  아이디: "아이디",
  아이디이메일: "아이디(이메일)",
  안내보기: "안내보기",
  알림_간격설정: "알림 간격설정",
  알림_그룹: "알림 그룹",
  알림_메일_설정: "알림(메일)설정",
  알림_시간설정: "알림 시간설정",
  알림_차단기간은_최소_1일부터_최대_3650일_이내로_설정가능합니다:
    "차단기간은 최소 1일부터 최대 3650일 이내로 설정가능합니다.",
  알림_트래픽제한_설정_값을_입력해주세요: "트래픽제한 설정 값을 입력해주세요",
  알림: "알림",
  알림받을_주소입력: "알림받을 주소입력",
  알림설정: "알림설정",
  알림필터_등록: "알림필터 등록",
  알림필터_수정: "알림필터 수정",
  알림필터: "알림필터",
  약관동의: "약관동의",
  어제: "어제",
  어제날짜: "어제날짜",
  언어_설정: "언어 설정",
  언어: "언어",
  업데이트_버전: "업데이트 버전",
  업데이트_현황: "업데이트 현황",
  업데이트_n_버전: "업데이트(%) 버전",
  업데이트: "업데이트",
  업데이트버전: "업데이트버전",
  업데이트일: "업데이트일",
  업데이트중: "업데이트중",
  업로드_중지: "업로드 중지",
  업로드: "업로드",
  업로드수: "업로드수",
  업로드용량: "업로드용량",
  업체_등록: "업체 등록",
  업체: "업체",
  업체별_탐지_분포: "업체별 탐지 분포",
  업체별_탐지_수: "업체별 탐지 수",
  업체설정: "업체설정",
  없음: "없음",
  에러_개인정보처리방침에동의해라: "개인정보 처리방침에 동의해 주세요.",
  에러_검색값을입력해라: "검색값을 입력해 주세요.",
  에러_검색대상을선택해라: "검색대상을 선택해 주세요.",
  에러_고객지원이용약관동의해주세요: "고객지원 이용약관에 동의해 주세요.",
  에러_관리자에게문의후로그인시도해주시기바랍니다:
    "관리자에게 문의 후 로그인 시도해 주시기 바랍니다.",
  에러_권한이없습니다_관리자에게문의하세요: "권한이 없습니다.\n관리자에게 문의하세요.",
  에러_기간설정: "기간 설정이 잘못 입력되었습니다.",
  에러_네트워크: "네트워크 에러입니다.",
  에러_다시로그인해주세요: "장시간 사용하지 않아 세션이 만료되었습니다.\n 다시 로그인해 주세요.",
  에러_다시시도해주세요: "다시 시도해 주세요.",
  에러_다운로드_수_확인: "다운로드 수가 잘못 입력되었습니다.\n확인해주세요.",
  에러_데이터가삭제되었습니다: "데이터가 삭제되었습니다.",
  에러_데이터가없습니다: "데이터가 없습니다.",
  에러_동일한_비밀번호_새_비밀번호_입력:
    "사용중인 비밀번호와 동일합니다.\n새로운 비밀번호를 입력해 주세요.",
  에러_등록된시스템없다: "등록된 호스트가 없습니다.",
  에러_등록된티켓사이트없음: "등록된 티켓사이트가 없습니다.",
  에러_등록이실패했다: "등록이 실패 하였습니다.",
  에러_등록중에러발생했습니다: "등록 중 에러가 발생했습니다.",
  에러_등록한필터가없다: "등록한 필터가 없습니다.",
  에러_로그인10회실패로로그인이제한됩니다: "로그인 10회 실패로 로그인이 제한됩니다.",
  에러_로그인허용IP주소_사용설정_불가능:
    "등록된 정보가 없어 로그인허용IP주소 사용설정이 불가능합니다.",
  에러_리소스명중복: "이미 등록된 리소스 코드명입니다.",
  에러_리소스코드중복: "이미 등록된 리소스 코드입니다.",
  에러_만료된정보다: "만료 된 정보입니다.",
  에러_모든값을입력해라: "모든 정보를 입력해 주세요.",
  에러_발생로그없습니다: "발생한 로그가 없습니다.",
  에러_방어_명령어를_입력하세요: "방어 명령어를 입력하세요.",
  에러_방어대상1개이상선택되야함확인후다시시도하세요:
    "방어대상이 1개 이상 선택되어야 설정이 가능합니다.\n확인 후 다시 시도해 주세요.",
  에러_백업루트를선택해주세요: "백업루트를 선택해 주세요.",
  에러_복원개수초과: "최대 10개 호스트 복원이 가능합니다.\n정보를 확인해 주세요.",
  에러_복원중복: "설정 정보에 복원 완료된 로그가 포함되어 있습니다.\n정보를 확인해주세요.",
  에러_복원횟수초과: "하루 10건의 복원이 가능합니다.\n(완료된 복원기준)",
  에러_비밀번호는_6자리_이상_입력하셔야_합니다: "비밀번호는 6자리 이상 입력하셔야 합니다.",
  에러_비밀번호를_입력해_주세요: "비밀번호를 입력해 주세요.",
  에러_비밀번호를입력해주세요: "비밀번호를 입력해 주세요.",
  에러_비밀번호일치하지않는다: "비밀번호가 일치하지 않습니다.",
  에러_비밀번호일치하지않습니다: "비밀번호가 일치하지 않습니다.",
  에러_빈값을입력하세요: "입력되지 않은 정보가 있습니다. 정보를 입력해 주세요.",
  에러_사용자가등록한상관필터가없다: "사용자가 등록한 상관분석필터가 없습니다.",
  에러_삭제가실패했다: "삭제가 실패 하였습니다.",
  에러_삭제중인_시스템: "삭제중인 호스트 및 로그가 있습니다.\n삭제 완료 후 시도해주세요.",
  에러_서비스리젼과스토리지타입선택: "서비스리젼과 스토리지타입을 선택해 주세요.",
  에러_서비스리젼과스토리지타입주키퍼명선택:
    "서비스 지역/스토리지 유형/주키퍼/Collection을 선택해 주세요.",
  에러_서비스명중복: "이미 등록된 서비스명입니다.",
  에러_선택한운영체제의시스템없다: "선택한 운영체제의 호스트가 없습니다.",
  에러_세자리수: "세 자리의 숫자를 입력해주세요.",
  에러_수정권한: "수정 권한이 없습니다.",
  에러_수정이실패했다: "수정이 실패 하였습니다.",
  에러_시간설정다시: "시간을 다시 설정해 주세요.",
  에러_시스템등록불가_관리자에게문의: "호스트 등록이 불가능합니다. 관리자에게 문의해주세요.",
  에러_아이디를입력해주세요: "아이디를 입력해 주세요.",
  에러_아이디및비밀번호를입력해라: "아이디와 비밀번호를 입력해 주세요.",
  에러_아이디정보가없다이미등록된아이디다: "아이디 정보가 없습니다. or 이미 등록된 아이디입니다.",
  에러_업로드경로가하나이상필요: "업로드 경로가 하나 이상 있어야 웹로그 수집 ON이 가능합니다.",
  에러_올바른이메일주소입력해라: "올바른 이메일 주소를 입력해 주세요.",
  에러_요청사유를입력하라: "요청사유를 입력해 주세요.",
  에러_웹로그업로드경로하나이상필요:
    "웹로그의 경우 업로드 경로가 하나 이상 있어야 수집 ON이 가능합니다.",
  에러_이름에특수문자입력못한다: "이름에 특수문자를 사용하실 수 없습니다.",
  에러_이름입력해라: "이름을 입력해 주세요.",
  에러_이메일입력해라: "이메일을 입력해 주세요.",
  에러_이미가입된이메일이다: "이미 가입된 아이디(이메일)입니다.",
  에러_이미등록된내부IP주소다: "이미 등록된 내부IP주소입니다.",
  에러_이미등록된목록그룹이다: "이미 등록된 목록그룹입니다.",
  에러_이미등록된태그입니다: "이미 등록된 태그입니다.",
  에러_이미등록된호스트명입니다: "이미 등록된 호스트명입니다.",
  에러_이미선택된서버그룹이다: "이미 선택된 서버그룹입니다.",
  에러_이미선택된항목입니다: "이미 선택된 항목입니다",
  에러_이용약관에동의해라: "이용약관에 동의해 주세요.",
  에러_자동등록방지를확인해라: "자동등록방지를 확인해 주세요.",
  에러_정보를입력해라: "정보를 입력해 주세요.",
  에러_중복된값: "중복된 값입니다.",
  에러_채널이없거나선택되지않았을경우:
    "채널이 없거나 선택되지 않았을 경우 \nKey,Value 값을 입력해야 합니다.",
  에러_최대n일입력가능: "최대 %일까지 입력 가능합니다.(최대 %년)",
  에러_컴플라이언스중복: "이미 등록된 컴플라이언스명 또는 ID입니다.",
  에러_코드중복: "이미 등록된 코드입니다.",
  에러_탐지필터_등록해_주세요: "탐지필터를 등록해 주세요.",
  에러_티켓시스템설정안했다: "티켓 연동 설정 후 사용 가능합니다.\n(관리 > 연동 > 티켓)",
  에러_필수값을입력해라: "필수값을 입력해 주세요.",
  에러_필수정보를입력해라: "필수정보를 입력해 주세요.",
  에러_회사명입력해라: "회사명을 입력해 주세요.",
  에러_IP주소입력후등록가능합니다: "IP주소 입력후 등록 가능합니다.",
  에러_n개_이상_선택: "%개 이상의 정보를 선택하셔야 합니다.",
  에러_n이후에이탈값확인가능: "%에 이탈값 확인이 가능합니다.",
  에러_OTP메일_번호를_확인해_주세요: "OTP메일 번호를 확인해주세요.",
  에러_PIN번호가_일치하지_않습니다: "PIN번호가 일치하지 않습니다.",
  에러_PIN번호를_확인해_주세요: "PIN번호를 확인해 주세요.",
  에이전트_다운로드: "에이전트 다운로드",
  에이전트_등록: "에이전트 등록",
  에이전트_삭제: "에이전트 삭제",
  에이전트_설정값: "에이전트 설정값",
  에이전트_설치_감사정책_안내:
    "에이전트 설치는 고급감사정책 설정을 수반하여 시스템 로깅에 대한 변경이 발생합니다.",
  에이전트_설치_동의_추가안내: "(해당 동의는 최초 1회만 진행합니다.)",
  에이전트_설치_동의: "에이전트 설치를 위한 서비스 이해 및 동의가 필요합니다.",
  에이전트_설치_오딧설정_안내:
    "에이전트 설치는 오딧 설정을 수반하여 시스템 로깅에 대한 변경이 발생합니다.",
  에이전트_설치: "에이전트 설치",
  에이전트_설치시간: "에이전트 설치시간",
  에이전트_실행: "에이전트 실행",
  에이전트_재실행: "에이전트 재실행",
  에이전트_중지: "에이전트 중지",
  에이전트: "에이전트",
  에이전트버전: "에이전트버전",
  에이전트상태: "에이전트상태",
  에이전트중지_알림: "에이전트중지 알림",
  에이전트중지: "에이전트중지",
  엔터프라이즈_탐지_분포: "엔터프라이즈 탐지 분포",
  연도월주차: "%년 %월 %주",
  연동: "연동",
  연동설정: "연동설정",
  영구: "영구",
  영어공격IP주소: "공격IP주소",
  예: "예",
  예외_설정: "예외 설정",
  예외: "예외",
  예외그룹_추가: "예외그룹 추가",
  예외설정_추가: "예외설정 추가",
  예외속성: "예외속성",
  예외적용: "예외적용",
  예외조건: "예외조건",
  오늘: "오늘",
  오늘날짜: "오늘날짜",
  오늘접속: "오늘접속",
  오래된순: "오래된순",
  오류: "오류",
  오류메시지: "오류메시지",
  오류발생: "오류발생",
  오류보기: "오류보기",
  오전: "오전",
  오후: "오후",
  옵션: "옵션",
  완료: "완료",
  완료시_네트워크_아이콘노출: `완료 시 <span class="p-lr-3"><i class="fa fa-circle c-red"></i></span> 아이콘이 노출됩니다.`,
  완료시_네트워크_아이콘미노출: `완료 시 <span class="p-lr-3"><i class="fa fa-circle c-red"></i></span> 아이콘이 사라집니다.`,
  완료시_호스트_아이콘노출: `완료 시 <span class="p-lr-3"><i class="fa fa-circle c-dark"></i></span> 아이콘이 노출됩니다.`,
  요소_값_선택: "요소 값 선택",
  요소_선택: "요소 선택",
  요소: "요소",
  요소수선택: "요소수선택",
  요일_설정: "요일 설정",
  요일: "요일",
  요일별_로그발생: "요일별_로그발생",
  요일별_로그업로드_분포: "요일별 로그업로드 분포",
  요일별_탐지_현황: "요일별 탐지 현황",
  요일별: "요일별",
  요청_완료: "요청되었습니다.",
  요청_URL: "요청 URL",
  요청: "요청",
  요청기간: "요청기간",
  요청로그_사이즈: "요청 사이즈",
  요청사이즈: "요청사이즈",
  요청시간: "요청시간",
  요청일: "요청일",
  요청자_이메일: "요청자/이메일",
  요청자: "요청자",
  용량_로그수_서버수: "용량/로그 수/서버 수",
  용량: "용량",
  우선순위_적용_공격유형_설정: "우선순위 적용 공격유형 설정",
  우선순위_적용필터: "우선순위 적용필터",
  우선순위: "우선순위",
  우측_상단의: "우측 상단의",
  운영방식: "운영방식",
  운영체제_그룹: "운영체제 그룹",
  운영체제_버전: "운영체제 버전",
  운영체제_선택: "운영체제 선택",
  운영체제: "운영체제",
  운영체제버전: "운영체제버전",
  운영체제별_설치방법_매뉴얼과_동영상_참조:
    "운영체제별 자세한 설치 방법은 매뉴얼과 동영상을 참조하여 주십시오.",
  워드클라우드: "워드클라우드",
  원격지_서버_웹로그_수신_모듈_설치: "원격지 서버 웹로그 수신 모듈 설치",
  원격지_서버_응용프로그램_전송_모듈_설치: "원격지 서버 응용프로그램 전송 모듈 설치",
  원격지_서버_SYSLOG_전송_설정: "원격지 서버 SYSLOG 전송 설정",
  원격지_장비_SYSLOG_전송_설정: "원격지 장비 SYSLOG 전송 설정",
  원본_로그: "원본 로그",
  원본_주소: "원본 주소",
  원본_포트_이름: "원본 포트 이름",
  원본_호스트_이름: "원본 호스트 이름",
  원본: "원본",
  원본로그보기: "원본로그보기",
  월: "월",
  월별_로그업로드_분포: "월별 로그업로드 분포",
  월별_총_탐지발생_현황: "월별 총 탐지발생 현황",
  월별_탐지_현황: "월별 탐지 현황",
  월별: "월별",
  월요일: "월요일",
  웹_개인정보숨김: "웹 개인정보숨김",
  웹_로그_취합은_자동_지원: "웹 로그 취합은 자동 지원",
  웹_서버: "웹 서버",
  웹_수정: "웹 수정",
  웹_업로드: "웹 업로드",
  웹_웹방화벽: "웹/웹방화벽",
  웹_전체로그: "웹 전체로그",
  웹_탐지_예외: "웹 탐지 예외",
  웹_필터_수정: "웹 필터 수정",
  웹_필터: "웹 필터",
  웹_필터탐지: "웹 필터탐지",
  웹: "웹",
  웹로그_모듈_설치: "웹로그 모듈 설치",
  웹로그_방어: "웹로그 방어",
  웹로그_설정_안내: "웹로그 설정 안내",
  웹로그_수집_경로: "웹로그 수집 경로",
  웹로그_수집: "웹로그 수집",
  웹로그_업로드_수: "웹로그 업로드 수",
  웹로그_업로드_합계: "웹로그 업로드 합계",
  웹로그_업로드: "웹로그 업로드",
  웹로그_예외: "웹로그 예외",
  웹로그_취합_설정_확인: "웹로그 취합 설정 확인",
  웹로그_취합_설정: "웹로그 취합 설정",
  웹로그: "웹로그",
  웹로그미업로드시스템: "웹로그 미업로드 호스트",
  웹로그예외: "웹로그예외",
  웹모듈_삭제: "웹모듈 삭제",
  웹모듈_삭제중: "웹모듈 삭제중",
  웹방화벽_수정: "웹방화벽 수정",
  웹방화벽_예외: "웹방화벽 예외",
  웹방화벽_예외설정: "웹방화벽 예외설정",
  웹방화벽_전체로그: "웹방화벽 전체로그",
  웹방화벽_필터: "웹방화벽 필터",
  웹방화벽_필터탐지: "웹방화벽 필터탐지",
  웹방화벽: "웹방화벽",
  웹서버_주소: "웹서버 주소",
  웹탐지_개인정보숨김: "웹탐지 개인정보숨김",
  웹탐지개인정보숨김: "웹탐지개인정보숨김",
  웹필터_관리: "웹필터 관리",
  웹필터_리스트: "웹필터 리스트",
  웹필터_분류: "웹필터 분류",
  웹필터_추가_텍스트: "웹필터 추가",
  웹필터_추가: "<strong>웹필터</strong>추가",
  웹필터관리: "웹필터관리",
  웹필터등록: "웹필터등록",
  웹필터리스트: "웹필터리스트",
  웹필터수정: "웹필터수정",
  웹필터추가: "웹필터추가",
  웹훅_선택_시_주소_자동_입력: "웹훅 선택 시 주소가 자동 입력됩니다.",
  웹훅_알림: "웹훅 알림",
  웹훅_Webhook: "웹훅(Webhook)",
  웹훅: "웹훅",
  웹훅지원_항목: [
    "구글챗(Google Chat)",
    "라인(Line)",
    "슬랙(Slack)",
    "잔디(Jandi)",
    "텔레그램(Telegram)",
  ],
  위험_IP주소: "위험 IP주소",
  위험도: "위험도",
  위험도별_로그발생_현황: "위험도별 로그발생 현황",
  위험도별_로그발생: "위험도별 로그발생",
  위험도별_설정: "위험도별 설정",
  유레카_비교: "유레카 비교",
  유료_: "유료%",
  유료_사용_기간: "유료 사용 기간",
  유료_PREMIUM: "유료(PREMIUM)",
  유료_STANDARD: "유료(STANDARD)",
  유의사항: "유의사항",
  유입경로: "유입경로",
  유출정보: "유출정보",
  유형_등록: "유형 등록",
  유형_발급_메일발송: "유형(발급/메일발송)",
  유형: "유형",
  유형선택: "유형선택",
  응답_본문: "응답 본문",
  응답: "응답",
  응답로그_사이즈: "응답 사이즈",
  응답본문_유출영역: "응답본문 유출영역",
  응답본문내용: "응답 본문 내용",
  응답사이즈: "응답사이즈",
  응용프로그램_단일필터_등록: "응용프로그램 단일필터 등록",
  응용프로그램_단일필터_수정: "응용프로그램 단일필터 수정",
  응용프로그램_단일필터: "응용프로그램 단일필터",
  응용프로그램_로그_업로드_수: "응용프로그램 로그 업로드 수",
  응용프로그램_로그_업로드: "응용프로그램 로그 업로드",
  응용프로그램_로그: "응용프로그램 로그",
  응용프로그램_사용자_수정: "응용프로그램(사용자) 수정",
  응용프로그램_사용자: "응용프로그램(사용자)",
  응용프로그램_사용자정의_로그: `응용프로그램 <br>사용자정의 로그</br>`,
  응용프로그램_사용자정의: "응용프로그램 사용자정의",
  응용프로그램_원본_수정: "응용프로그램(원본) 수정",
  응용프로그램_원본_업로드: "응용프로그램 원본 업로드",
  응용프로그램_원본: "응용프로그램 원본",
  응용프로그램_원본로그_수집_경로: "응용프로그램 원본로그 수집 경로",
  응용프로그램_원본로그_수집: "응용프로그램 원본로그 수집",
  응용프로그램_원본로그: `응용프로그램 <br>원본로그</br>`,
  응용프로그램_태그: "응용프로그램 태그",
  응용프로그램: "응용프로그램",
  의문문_네트워크_격리: "네트워크를 격리하시겠습니까?",
  의문문_네트워크_격리해제: "네트워크 격리 해제 하시겠습니까?",
  의문문_리스트에서_삭제하시겠습니까: "리스트에서 삭제하시겠습니까?",
  의문문_무료서비스사용중이세요: "무료 서비스 사용중이세요?",
  의문문_방화벽을OFF하쉴: "방화벽을 OFF하시겠습니까?",
  의문문_방화벽을ON하쉴: "방화벽을 ON하시겠습니까?",
  의문문_보안_수정: "% 하시겠습니까?",
  의문문_삭제명령어실행된다실행후엔ON불가능하다OFF할거냐:
    "삭제 명령어가 실행됩니다.\n 삭제 명령어 실행 후에는 ON 변경이 불가능합니다.\n OFF 처리 하시겠습니까?",
  의문문_삭제하시겠습니까_취소불가: "삭제하시겠습니까?\n삭제 시 바로 적용되며 취소되지 않습니다.",
  의문문_선택한n개필터삭제하시겠습니까:
    "선택한 %개의 필터를 삭제하시겠습니까?\n삭제하시면 삭제 이후 발생되는 로그는 탐지 되지 않으며 알림 발송도 중지됩니다.",
  의문문_설정검사_진행: "설정검사를 진행하시겠습니까?",
  의문문_수정하시겠습니까: "수정하시겠습니까?",
  의문문_수정한시간으로재설정된다적용하시겠습니까:
    "수정한 시간으로 재설정됩니다.\n적용하시겠습니까?",
  의문문_시스템로그_수정: "호스트로그의 분석을 %로 수정하시겠습니까?",
  의문문_실행하시겠습니까: "% 하시겠습니까?",
  의문문_차단IP주소등록하쉴: "차단IP주소를 등록하시겠습니까?",
  의문문_티켓발행하시겠습니까: "티켓을 발행하시겠습니까?",
  의문문_티켓테스트발행하시겠습니까: "입력된 정보로 티켓 발행 테스트를 하시겠습니까?",
  의문문_필터삭제:
    "선택한 필터를 삭제하시겠습니까?\n삭제하시면 삭제 이후 발생되는 로그는 탐지 되지 않으며 알림 발송도 중지됩니다.",
  의문문_필터상태를ONOFF하시겠습니까: "필터의 상태를 % 하시겠습니까?",
  의문문_호스트_종료: "호스트를 종료하시겠습니까?",
  의문문_IP주소삭제: "IP주소를 삭제하시겠습니까?",
  의문문_IP주소차단해제: "IP주소를 %하시겠습니까?",
  의문문_n_개의_시스템_복원: "[%]개의 호스트를 복원하시겠습니까?",
  이동: "이동",
  이름_입력: "이름 입력",
  이름_or_이메일_검색: "이름 or 이메일 검색",
  이름: "이름",
  이메일_입력: "이메일 입력",
  이메일: "이메일",
  이메일ID: "이메일(ID)",
  이미_등록된_업체입니다: "이미 등록된 업체입니다.",
  이미지: "이미지",
  이벤트: "이벤트",
  이벤트타입_관리: "이벤트타입 관리",
  이벤트타입_ID_제공_형태: "이벤트타입(ID) 제공 형태",
  이벤트타입_ID: "이벤트타입(ID)",
  이벤트타입: "이벤트타입",
  이벤트타입관리: "이벤트타입관리",
  이벤트타입등록: "이벤트타입등록",
  이벤트타입명: "이벤트타입명",
  이벤트타입선택: "이벤트타입선택",
  이벤트타입수정: "이벤트타입수정",
  이벤트ID: "이벤트ID",
  이상: "이상",
  이용서비스: "이용서비스",
  이용약관_체크: "이용약관에 체크해주세요.",
  이용약관: "이용약관",
  이전_로그보기: "이전 로그보기",
  이전: "이전",
  이전로그검색: "이전로그검색",
  이탈값: "이탈값",
  이하: "이하",
  이해_및_동의_안내: "이해했으며 동의합니다.",
  인스톨에이전트_설치_안내: "에이전트를 설치하고 서비스를 이용하세요.",
  인증: "인증",
  인증메일_재발송: "인증메일 재발송",
  인증메일을_재발송하시겠습니까: "인증메일을 재발송하시겠습니까?",
  인증메일이_발송되었습니다: "인증메일이 발송되었습니다.",
  인증상태: "인증상태",
  인증실패_로그인_제한: "인증실패 로그인 제한",
  인증실패_횟수: "인증실패 횟수",
  인코딩: "인코딩",
  일_며칠: "일",
  일: "일",
  일개월: "1개월",
  일반: "일반",
  일별_로그발생: "일별_로그발생",
  일별: "일별",
  일별발생_현황: "일별발생 현황",
  일요일: "일요일",
  일일_매트릭스: "일일 매트릭스",
  일정: "일정",
  일주: "1주",
  일치: "일치",
  임계치관리: "임계치관리",
  임계치등록: "임계치등록",
  임계치명: "임계치명",
  임계치수정: "임계치수정",
  임계치코드: "임계치코드",
  임시_비밀번호_이메일로_보내기: "임시 비밀번호 이메일로 보내기",
  임시비밀번호_메일발송: "임시비밀번호 메일발송",
  임시비밀번호_발급_히스토리: "임시비밀번호 발급 히스토리",
  임시비밀번호_발급: "임시비밀번호 발급",
  임시비밀번호: "임시비밀번호",
  임시비밀번호가_발송되었습니다: "임시비밀번호가 발송되었습니다.",
  임시비밀번호를_발급시_재사용불가:
    "임시비밀번호를 발급 받으면 기존의 비밀번호를 사용할 수 없습니다!!",
  입력: "입력",
  입력된_정보를_확인해주세요: "입력된 정보를 확인해주세요.",
  입력정보를_확인해주세요: "입력정보를 확인해 주세요.",
  있음: "있음",
  자동: "자동",
  자동등록방지: "자동등록방지",
  자동차단: "자동차단",
  자세한_정보_도움말_이용: "자세한 정보는 도움말을 이용해 주세요.",
  자세히보기: "자세히보기",
  작동: "작동",
  작성_시_공격유형_2depth: "작성 시 공격유형 2depth",
  작성일: "작성일",
  작성자_이메일: "작성자(이메일)",
  작성자: "작성자",
  잠금상태: "잠금상태",
  잠김: "잠김",
  장비_등록: "장비 등록",
  장비등록: "장비등록",
  재발송: "재발송",
  재설정: "재설정",
  재시도: "재시도",
  재시작: "재시작",
  재입력: "재입력",
  재전송공격: "재전송공격",
  재정렬: "재정렬",
  저장: "저장",
  적_용: "적 용",
  적용: "적용",
  적용된_정규식_노출: "적용된 정규식 노출",
  적용목록_관리: "적용목록 관리",
  적용목록관리: "적용목록관리",
  적용상태: "적용상태",
  적용서버: "적용서버",
  적용시간: "적용시간",
  적용시스템그룹: "적용호스트그룹",
  적용업체: "적용업체",
  적용일: "적용일",
  적용하기: "적용하기",
  전송_방식: "전송 방식",
  전송: "전송",
  전술_등록: "전술 등록",
  전술_매핑: "전술 매핑",
  전술_수정: "전술 수정",
  전술_탐지_TOP5: "전술 탐지 TOP5",
  전술: "전술",
  전술관리: "전술관리",
  전술등록: "전술등록",
  전술명: "전술명",
  전술ID: "전술ID",
  전월: "전월",
  전월발생: "전월발생",
  전일_그래프_제공: "전일 그래프 제공",
  전일: "전일",
  전일발생: "전일발생",
  전주: "전주",
  전주발생: "전주발생",
  전체: "전체",
  전체로그_바로가기: "전체로그 바로가기",
  전체로그_수집: "전체로그 수집",
  전체로그_저장: "전체로그 저장",
  전체로그: "전체로그",
  전체선택: "전체선택",
  전체차단동작: "전체 차단 동작",
  전화번호_휴대폰: "전화번호(휴대폰)",
  전환: "전환",
  점검_중_예외: "점검 중 예외",
  접근_제한: "접근 제한",
  접속_예외: "접속 예외",
  접속_횟수: "접속 횟수",
  접속_IP: "접속 IP",
  접속수: "접속수",
  접속일: "접속일",
  접속자: "접속자",
  접속파일_TOP5: "접속파일 TOP5",
  접속하기: "접속하기",
  접속IP: "접속IP",
  접속Path_TOP5: "접속Path TOP5",
  정규식: "정규식",
  정렬: "정렬",
  정렬코드: "정렬코드",
  정렬해제: "정렬해제",
  정보_마케팅_메일: "정보(마케팅)메일",
  정보_수집: "정보 수집",
  정보_입력: "정보 입력",
  정보: "정보",
  정보검색: "정보검색",
  정보를_선택하세요: "정보를 선택하세요.",
  정보메일_서비스: "정보메일 서비스",
  정보메일수신: "정보 메일 수신",
  정보수집: "정보수집",
  정보수집이실패하였습니다: "정보수집이 실패하였습니다.",
  정보수집후사용가능: "정보수집 후 사용가능합니다.",
  정보입력_다수복사붙여넣기가능: "정보입력(다수 복사 붙여넣기 가능)",
  정보입력: "정보입력",
  정상: "정상",
  정지: "정지",
  제거문자열: "제거문자열",
  제거유형: "제거유형",
  제공: "제공",
  제목: "제목",
  제약조건_인덱스_키: "제약조건(인덱스, 키)",
  제외: "제외",
  제외기간_설정: "제외기간 설정",
  제외설정: "제외설정",
  제한: "제한",
  조건: "조건",
  종료: "종료",
  종료일: "종료일",
  종합: "종합",
  주별_총_탐지발생_현황: "주별 총 탐지발생 현황",
  주별: "주별",
  주소: "주소",
  주요개체_선택: "주요개체 선택",
  주요개체: "주요개체",
  주요개체설정: "주요개체설정",
  주요정보: "주요정보",
  주의_개인정보_도용_노출:
    "동일한 아이디와 비밀번호를 여러 사이트에서 사용하는 경우 개인정보 도용에 노출될 수 있습니다.",
  주의사항: "주의사항",
  주체_계정_이름: "주체 계정 이름",
  주키퍼관리: "주키퍼관리",
  주키퍼등록: "주키퍼등록",
  주키퍼명: "주키퍼명",
  주키퍼선택: "주키퍼선택",
  주키퍼수정: "주키퍼수정",
  주키퍼주소: "주키퍼주소",
  준비중: "준비중",
  중간: "중간",
  중복된_정보가_존재합니다_확인바랍니다: "중복된 정보가 존재합니다.\n확인바랍니다.",
  중복체크: "중복체크",
  중지: "중지",
  중첩검색: "중첩검색",
  즉시방어: "즉시방어",
  즉시차단: "즉시 차단",
  지금_변경: "지금 변경하기",
  지역: "지역",
  지원단계: "지원단계",
  지원상태: "지원상태",
  지원시작: "지원시작",
  지원시작일: "지원시작일",
  지원종료: "지원종료",
  지원종료일: "지원종료일",
  직접_n: "직접[%]",
  직접: "직접",
  직접등록: "직접등록",
  직접입력: "직접입력",
  직책_입력: "직책 입력",
  직책: "직책",
  진행: "진행",
  진행사항: "진행사항",
  진행상태: "진행상태",
  진행상황: "진행상황",
  진행자: "진행자",
  진행중: "진행중",
  집계: "집계",
  차단_안내_문구: "차단 안내 문구",
  차단_안내: "차단 안내",
  차단_해지: "차단 해지",
  차단: "차단",
  차단기간: "차단기간",
  차단되었습니다: "차단되었습니다.",
  차단모드: "차단모드",
  차단실행중: "차단 실행 중",
  차단IP주소_등록: "차단IP주소 등록",
  차단IP주소: "차단IP주소",
  채널_관리: "채널 관리",
  채널_정렬_관리: "채널 정렬 관리",
  채널_ID: "채널(ID)",
  채널: "채널",
  채널관리: "채널관리",
  채널등록: "채널등록",
  채널명_EN: "채널명(EN)",
  채널명: "채널명",
  채널별_로그수: "채널별 로그수",
  채널수정: "채널수정",
  채널정렬: "채널정렬",
  채널ID_중복체크: "채널ID(중복체크)",
  채널ID: "채널ID",
  처리_결과: "처리 결과",
  처리상태: "처리상태",
  처리원인: "처리원인",
  처리일: "처리일",
  처음: "처음",
  초_내: "초 내",
  초: "초",
  초기화: "초기화",
  초단위_입력: "초단위_입력",
  초대메일발송일: "초대메일발송일",
  초대현황: "초대현황",
  총_로그_수: "총 로그 수",
  총_로그업로드_수: "총 로그업로드 수",
  총_로그업로드: "총 로그업로드",
  총_매핑_항목: "총 매핑 항목",
  총_발생로그: "총 발생로그",
  총_탐지: "총 탐지",
  총_탐지건수: "총 탐지건수",
  총_필터: "총 필터",
  총_n개: "총 %개",
  총: "총",
  총게시물: "총게시물",
  총접속: "총접속",
  최근_탐지_노출_리스트: "최근 탐지 노출 리스트",
  최근동기화시도일: "최근동기화시도일",
  최근업데이트: "최근업데이트",
  최근접속일: "최근접속일",
  최대_1_2분소요_실패시재시도:
    "최대 1~2분 소요될 수 있습니다. 새로고침으로 결과 확인할 수 있으며 실패 시 재시도 해주세요.",
  최대_16자: "*최대 16자",
  최대_요청: "최대 요청",
  최대_응답: "최대 응답",
  최대_n개_검색가능: "최대 %개 검색이 가능합니다.",
  최대_n개: "최대 %개",
  최대: "최대",
  최대값: "최대값",
  최대n년: "최대 %년",
  최소_길이_8자리: "최소 길이 8자리, 문자 조합 규칙 없음",
  최소: "최소",
  최소값: "최소값",
  최신버전_다운로드_링크: "최신버전 다운로드 링크",
  최신버전_패치버전: "최신버전 (패치버전)",
  최신버전: "최신버전",
  최신순: "최신순",
  최신업데이트일: "최신업데이트일",
  최종검사: "최종검사",
  최종적용: "최종적용",
  최종접속일: "최종접속일",
  추가_정보입력: "추가 정보입력",
  추가: "추가",
  추가저장: "추가저장",
  추가저장기간: "추가저장기간",
  추천: "추천",
  추천필터_등록: "추천필터 등록",
  추천필터: "추천필터",
  추천필터등록: "추천필터등록",
  추천필터등록필터메뉴얼보기: "추천필터/등록필터 메뉴얼보기",
  추천필터등록하러가기: "추천필터 등록하러 가기",
  추천필터수정: "추천필터수정",
  추출로그: "추출로그",
  추출수: "추출수",
  추출순: "추출순",
  추출일: "추출일",
  추출IP주소: "추출IP주소",
  출력: "출력",
  취_소: "취 소",
  취소: "취소",
  취약경로: "취약경로",
  취약항목: "취약항목",
  취합_동일로그수: "취합 동일로그수",
  취합로그: "취합로그",
  취합로그별_서버수: "취합로그별 서버수",
  취합중: "취합중",
  치환: "치환",
  치환대상: "치환대상",
  치환할_값: "치환할 값",
  커널: "커널",
  커서: "커서",
  커서마크_수정하시겠습니까:
    "OFF 할 경우, 검색의 '다음' 페이지 속도가 느려집니다.\n 수정하시겠습니까?",
  컬럼_위치_날짜_앞: "컬럼 위치: 날짜 앞",
  컬럼: "컬럼",
  컬럼명: "컬럼명",
  컬렉션관리: "컬렉션관리",
  컬렉션등록: "컬렉션등록",
  컬렉션명: "컬렉션명",
  컬렉션수정: "컬렉션수정",
  컴플라이언스_등록: "컴플라이언스 등록",
  컴플라이언스_리스트: "컴플라이언스 리스트",
  컴플라이언스_매핑: "컴플라이언스 매핑",
  컴플라이언스_설정: "컴플라이언스 설정",
  컴플라이언스_정보: "컴플라이언스 정보",
  컴플라이언스: "컴플라이언스",
  컴플라이언스명_변경: "컴플라이언스명 변경",
  컴플라이언스명: "컴플라이언스명",
  컴플라이언스상세: "컴플라이언스상세",
  컴플라이언스ID: "컴플라이언스ID",
  코드_및_항목_설명: "코드 및 항목 설명",
  코드_불러오기: "코드 불러오기",
  코드_선택: "코드 선택",
  코드_입력: "코드 입력",
  코드_코드명_코드설명: "코드/코드명/코드설명",
  코드_코드명: "코드/코드명",
  코드: "코드",
  코드그룹: "코드그룹",
  코드그룹등록: "코드그룹등록",
  코드그룹명: "코드그룹명",
  코드그룹선택: "코드그룹선택",
  코드그룹설명: "코드그룹설명",
  코드그룹수정: "코드그룹수정",
  코드명: "코드명",
  코드설명: "코드설명",
  코드수정: "코드수정",
  코드순: "코드순",
  코드정보: "코드정보",
  코드정보입력_개발자입력: "코드정보입력(개발자입력)",
  코드추가: "코드추가",
  쿠키: "쿠키",
  쿼리_요청본문: "쿼리 || 요청본문",
  쿼리: "쿼리",
  크리덴셜_스터핑: "크리덴셜 스터핑",
  키: "키",
  키워드_매핑: "키워드 매핑",
  키워드: "키워드",
  키워드저장크기: "키워드저장크기",
  타겟_디비_패치대상: "타겟 디비 (패치 대상)",
  타겟디비_값: "타겟디비 값",
  타겟디비_패치대상: "타겟 디비 (패치 대상)",
  타이틀_시스템: "시스템",
  타입: "타입",
  타입별: "타입별",
  탐지_단계: "탐지 단계",
  탐지_방어: "탐지/방어",
  탐지_분포: "탐지 분포",
  탐지_양: "탐지 양",
  탐지_예외: "탐지 예외",
  탐지_우선순위_설정: "탐지 우선순위 설정",
  탐지_우선순위_적용: "탐지 우선순위 적용",
  탐지_위험도별_현황: "탐지/위험도별 현황",
  탐지_필터: "탐지 필터",
  탐지_TOP5: "탐지 TOP5",
  탐지: "탐지",
  탐지간_인터벌_시간: "탐지간 인터벌 시간",
  탐지갯수: "탐지갯수",
  탐지노출: "탐지노출",
  탐지로그_바로가기: "탐지로그 바로가기",
  탐지로그_발생_시스템: "탐지로그 발생 호스트",
  탐지로그_시스템: "탐지로그 호스트",
  탐지로그: "탐지로그",
  탐지모드: "탐지모드",
  탐지서비스: "탐지서비스",
  탐지수_기준: "탐지수 기준",
  탐지수: "탐지수",
  탐지시간: "탐지시간",
  탐지알림: "탐지알림",
  탐지일: "탐지일",
  탐지조건: "탐지조건",
  탐지키워드_TOP5: "탐지키워드 TOP5",
  탐지타입: "탐지타입",
  탐지필터_분포: "탐지필터 분포",
  탐지필터_TOP5: "탐지필터 TOP5",
  탐지필터: "탐지필터",
  태그_관리: "태그관리",
  태그_선택: "태그 선택",
  태그: "태그",
  테마설정: "테마설정",
  테이블: "테이블",
  템플릿_변수_입력: "템플릿 영역 내 변수에 알맞는 값을 넣어 치환해주세요.",
  템플릿_정보: "템플릿 정보",
  템플릿view: "템플릿view",
  토: "토",
  토요일: "토요일",
  통계: "통계",
  통계동기화: "통계동기화",
  통계제외: "통계제외",
  통계제외일_n일: "통계제외일: %일",
  통합코드관리: "통합코드관리",
  통합코드그룹_상세: "통합코드그룹 상세",
  트래픽제한: "트래픽제한",
  트리거: "트리거",
  특정_key_설정: "특정 key 설정",
  티켓_발행_테스트: "티켓 발행 테스트",
  티켓_발행: "티켓 발행",
  티켓_발행완료: "티켓 발행완료",
  티켓_연동: "티켓 연동",
  티켓: "티켓",
  티켓관리: "티켓관리",
  티켓버전_등록: "티켓버전 등록",
  티켓버전_수정: "티켓버전 수정",
  티켓사이트_고유아이디: "티켓사이트 고유아이디",
  티켓사이트_관리: "티켓사이트 관리",
  티켓사이트_선택: "티켓사이트 선택",
  티켓사이트: "티켓사이트",
  티켓사이트명: "티켓사이트명",
  파라미터_고정값: "파라미터 고정값",
  파라미터_노출명: "파라미터 노출명",
  파라미터_설명: "파라미터 설명",
  파라미터_설정: "파라미터 설정",
  파라미터_전송_템플릿: "파라미터 전송 템플릿",
  파라미터: "파라미터",
  파라미터명: "파라미터명",
  파라미터유형: "파라미터유형",
  파일: "파일",
  파일업로드: "파일업로드",
  팔로우: "팔로우",
  패턴_관리: "패턴 관리",
  패턴_추가: "패턴 추가",
  패턴: "패턴",
  패턴명: "패턴명",
  패턴선택: "패턴선택",
  퍼센트_입력: "% 입력",
  퍼센트게이지: "%게이지",
  페이지_상단_메뉴에서_OS별_선택: "페이지 상단 메뉴에서 OS별 선택",
  평균: "평균",
  평판DB: "평판DB",
  포맷: "포맷",
  포트_프로토콜: "포트/프로토콜",
  포트: "Port",
  포트번호_유지: "포트번호 유지",
  포함: "포함",
  포함제외: "포함제외",
  프로그램_이름: "프로그램 이름",
  프로그램명: "프로그램명",
  프로세스_ID: "프로세스 ID",
  프로토콜: "프로토콜",
  프록시_설정: "프록시 설정",
  플랫폼_노출명: "플랫폼 노출명",
  플랫폼_등록: "플랫폼 등록",
  플랫폼_매핑: "플랫폼 매핑",
  플랫폼_수정: "플랫폼 수정",
  플랫폼_코드: "플랫폼 코드",
  플랫폼: "플랫폼",
  플랫폼관리: "플랫폼관리",
  플랫폼등록: "플랫폼등록",
  필수여부: "필수여부",
  필요시_경로_입력: "필요시 경로 입력",
  필터_관리: "필터 관리",
  필터_등록_필요: "필터 등록 필요",
  필터_등록: "필터 등록",
  필터_매핑: "필터 매핑",
  필터_비교: "필터 비교",
  필터_선택: "필터 선택",
  필터_소스: "필터 소스",
  필터_이름: "필터 이름",
  필터_추가: "필터 추가",
  필터: "필터",
  필터관리: "필터관리",
  필터등록: "필터등록",
  필터를_검색하세요: "필터를 검색하세요.",
  필터를_선택하세요: "필터를 선택하세요.",
  필터리스트_관리: "필터리스트 관리",
  필터명: "필터명",
  필터명순: "필터명순",
  필터별_로그발생_현황: "필터별 로그발생 현황",
  필터별_로그발생: "필터별_로그발생",
  필터별_탐지_수: "필터별 탐지 수",
  필터별_탐지_카운트: "필터별 탐지 카운트",
  필터분류_선택: "필터분류 선택",
  필터분류: "필터분류",
  필터분류선택: "필터분류선택",
  필터상태: "필터상태",
  필터설명: "필터설명",
  필터수정: "필터수정",
  필터위험도별_설정: "필터위험도별 설정",
  필터위험도별: "필터위험도별",
  필터유형: "필터유형",
  필터유형별: "필터유형별",
  필터키: "필터키",
  필터키입력: "필터키입력",
  필터키OR매핑코드: "필터키 or 매핑코드",
  필터탐지: "필터탐지",
  하단다음4라인주석해제: "하단의 다음 4라인 주석 해제",
  하단에서_수정: "하단에서 수정",
  하위_필터: "하위 필터",
  하위요소: "하위요소",
  한개의_필터_등록: "1개의 필터 등록",
  합계: "합계",
  항목_설명: "항목/설명",
  항목_추가: "항목 추가",
  항목추가: "항목추가",
  해시: "해시",
  해제: "해제",
  해지: "해지",
  해킹그룹_등록: "해킹그룹 등록",
  해킹그룹_매핑: "해킹그룹 매핑",
  해킹그룹_수정: "해킹그룹 수정",
  해킹그룹: "해킹그룹",
  해킹그룹관리: "해킹그룹관리",
  해킹그룹등록: "해킹그룹등록",
  해킹그룹명: "해킹그룹명",
  해킹그룹탐지: "해킹그룹탐지",
  해킹그룹ID: "해킹그룹ID",
  행동유형: "유형",
  허용: "허용",
  허용IP주소: "허용IP주소",
  현재_권한: "현재 권한",
  현재_비밀번호: "현재 비밀번호",
  현재_PIN번호_입력: "현재 PIN번호 입력",
  호스트_사용: "호스트 사용",
  호스트_이름: "호스트 이름",
  호스트_종료: "호스트 종료",
  호스트: "호스트",
  호스트명_입력: "호스트명 입력",
  호스트명: "호스트명",
  호스트보안_단일필터_등록: "호스트보안 단일필터 등록",
  호스트보안_단일필터_수정: "호스트보안 단일필터 수정",
  호스트보안_단일필터: "호스트보안 단일필터",
  호스트보안_단일필터추가: "호스트보안 단일필터추가",
  호스트보안_방어필터: "호스트보안 방어필터",
  호스트보안_수정: "호스트보안 수정",
  호스트보안: "호스트보안",
  호스트보안로그_분석: "호스트보안로그 분석",
  호스트보안로그_수집: "호스트보안로그 수집",
  호스트보안로그_업로드_수: "호스트보안로그 업로드 수",
  호스트보안로그_업로드: "호스트보안로그 업로드",
  호스트보안필터_추가: "<strong>호스트보안필터</strong>추가",
  홈페이지_위변조_보안탐지: "홈페이지 위변조 보안탐지",
  홈페이지_위변조_보안필터_수정: "홈페이지 위변조 보안필터 수정",
  홈페이지_위변조_탐지: "홈페이지 위변조 탐지",
  홈페이지_위변조: "홈페이지 위변조",
  화: "화",
  화면_설정: "화면 설정",
  화요일: "화요일",
  확_인: "확 인",
  확률: "확률",
  확인: "확인",
  확인하기: "확인하기",
  확인했습니다: "확인했습니다.",
  환경_설정: "환경 설정",
  회: "회",
  회사: "회사",
  회사명_입력: "회사명 입력",
  회사명: "회사명",
  회사별_관리: "회사별 관리",
  회사별관리: "회사별관리",
  회사별관리수정: "회사별관리수정",
  회사소개: "회사소개",
  회사이름: "회사이름",
  회원_관리: "회원 관리",
  회원가입: "회원가입",
  회원고유키: "회원고유키",
  회원관리: "회원관리",
  횟수: "횟수",
  횟수입력: "횟수입력",
  휴대폰: "휴대폰",
  휴대폰번호_입력: "휴대폰번호 입력",
  휴대폰번호: "휴대폰번호",
  히스토리: "히스토리",
  히트맵_정보: "히트맵 정보",
  히트맵: "히트맵",
  힙사용률: "힙사용률",
  abc순: "ABC순",
  Amazon_Linux_AMI_2017_2018_Linux_2_AMI_지원: "Amazon Linux AMI 2017/2018, Linux 2 AMI 지원",
  API_버전: "API 버전",
  API_인증키: "API 인증키",
  Audit_로그패키지개별설치: "Audit 로그 패키지 개별 설치",
  Audit_rule_관리: "Audit rule 관리",
  Audit_rule_등록: "Audit rule 등록",
  Audit_rule_수정: "Audit rule 수정",
  AUDIT_RULE명: "AUDIT RULE명",
  Auditrule관리: "Auditrule관리",
  Byte_부터: "Byte 부터",
  CentOS_7_Stream_8_지원: "CentOS 7, Stream 8 지원",
  Command_로그_수집_중지: "Command 로그 수집 중지",
  Config_위치_확인: "Config 위치 확인",
  DATA_위치: "DATA 위치",
  Data_name_불러오기: "Data name 불러오기",
  Dataname_관리: "Data name 관리",
  dataname: "Data name",
  Dataname등록: "Data name 등록",
  Dataname수정: "Data name 수정",
  DB_매핑코드: "DB 매핑코드",
  Default적용: "Default적용",
  DoS: "DoS",
  Event_Log_IIS포함: "Event Log (IIS포함)",
  Event_TYPE: "<strong>Event</strong>TYPE",
  GUID: "GUID",
  HASH_전_로그보기: "HASH 전 로그보기",
  ID관리: "ID관리",
  ID등록: "ID등록",
  ID수: "ID수",
  ID수정: "ID수정",
  IP검사: "IP검사",
  IP주소_등록: "IP주소 등록",
  IP주소_사용자태그: "IP주소 사용자태그",
  IP주소_입력: "IP주소 입력",
  IP주소_추출: "IP주소 추출",
  IP주소_추출로그: "IP주소 추출로그",
  IP주소_태그: "IP주소 태그",
  IP주소: "IP주소",
  IP주소그룹: "IP주소그룹",
  IP주소추가: "IP주소추가",
  ipv6는_지원하지않습니다: "IPv6는 지원하지 않습니다.",
  KEY: "KEY",
  Linux_Syslog탭: "Linux > Syslog 탭",
  Login_분석: "Login 분석",
  Login_분포: "Login 분포",
  Login_성공_실패: "Login 성공/실패",
  Login_추출로그: "Login 추출로그",
  MAC주소_사용자태그: "MAC주소 사용자태그",
  MAC주소: "MAC주소",
  Method별_로그발생: "Method별 로그발생",
  MIME_타입: "MIME 타입",
  ML_예외설정_추가: "ML 예외설정 추가",
  ML분석: "ML분석",
  ML예외_설정: "ML예외 설정",
  ML예외n건: "ML예외%건",
  ML탐지: "ML탐지",
  msg정렬_등록: "msg정렬 등록",
  msg정렬_수정: "msg정렬 수정",
  msg정렬관리: "msg정렬관리",
  MSS관리: "MSS관리",
  mysql_conf_다운로드_rsyslog_사용: "mysql conf 다운로드 rsyslog 사용",
  mysql_error_위치_확인: "mysql error 위치 확인",
  n_공격가능확률: "% 공격가능확률",
  n_데몬_재시작: "% 데몬 재시작",
  n_사용시_설치필요: "% 사용 시 설치 필요",
  n_수정: "% 수정",
  n_이상_지원: "% 이상 지원",
  n_이상일때_설치필요: "% 이상일 때 설치 필요",
  n_지원: "% 지원",
  n가_등록됩니다: "%가 아래와 같이 등록됩니다.",
  n개_등록제한: "%개까지 등록가능합니다.",
  n개_모두_선택: "%개 모두 선택",
  n개: "%개",
  n건_등록: "%건 등록",
  n건: "%건",
  n년: "%년",
  n명: "%명",
  n분_내: "%분 내",
  n분_전: "%분 전",
  n시: "%시",
  n시간_내: "%시간 내",
  n시간_전: "%시간 전",
  n월: "%월",
  n이상: "% 이상",
  n일_뒤: "%일 뒤",
  n일_전: "%일 전",
  n일: "%일",
  n주: "%주",
  NO: "NO",
  Ocean_대상IP주소관리: "Ocean: 대상IP주소 관리",
  OFF: "OFF",
  ON_설정_시_서비스_노출되며_탐지_시작: "ON 설정 시 서비스 노출되며 탐지 시작",
  ON_설정_시_탐지노출: "ON 설정 시 탐지노출",
  ON_설정시_로그인이_제한됩니다: "ON 설정 시 로그인이 제한됩니다.",
  ON: "ON",
  OS_채널: "OS/채널",
  OS: "OS",
  OS별_로그발생_현황: "OS별 로그발생 현황",
  OS선택: "OS선택",
  OTP메일_번호_입력: "OTP메일 번호 입력",
  OTP메일: "OTP메일",
  OWASP: "OWASP",
  Path: "Path",
  PATH: "PATH",
  PATH를_입력해주세요: "PATH를 입력해 주세요.",
  PATTERN: "PATTERN",
  Permission: "Permission",
  PERMISSION: "PERMISSION",
  PERMISSION을_입력해주세요: "PERMISSION을 입력해 주세요.",
  PIN번호_생성_규칙_관리: "PIN번호 생성 규칙 관리",
  PIN번호_설정_완료: "PIN번호가 설정되었습니다.",
  PIN번호_설정: "PIN번호 설정",
  PIN번호_입력: "PIN번호 입력",
  PIN번호_확인: "PIN번호 확인",
  PIN번호_OFF_설정: "PIN번호 OFF 설정",
  PIN번호: "PIN번호",
  PLURA_해킹_대응_서비스시작: "PLURA 해킹 대응 서비스가 시작되었습니다.",
  PLURA_Agent_UI에서_설정된_것_확인: "PLURA Agent UI에서 설정된 것 확인",
  Port_입력: "Port 입력",
  PostgreSQL및Syslog재시작: "PostgreSQL 및 Syslog 재시작",
  Powershell_관리: "Powershell 관리",
  Powershell_등록: "Powershell 등록",
  Powershell_rule: "Powershell rule",
  POWERSHELL_RULE명을_입력해주세요: "POWERSHELL RULE명을 입력해 주세요.",
  Powershellrule명: "Powershell rule 명",
  Priority_관리: "Priority 관리",
  Priority_등록: "Priority 등록",
  Priority_수정: "Priority 수정",
  PRIORITY: "PRIORITY",
  PRIORITY명: "PRIORITY명",
  QnA_관리: "Q&A 관리",
  QnA_등록: "Q&A 등록",
  QnA_상세: "Q&A 상세",
  QnA_수정: "Q&A 수정",
  QnA: "Q&A",
  Redhat_7_8_지원: "Redhat 7, 8 지원",
  SQUID_결과_값: "SQUID 결과 값",
  Status별_로그발생_현황: "Status별 로그발생 현황",
  status별_로그발생: "status별 로그발생",
  status별_설정: "status별 설정",
  status별: "status별",
  Syslog_재시작: "Syslog 재시작",
  Syslog_전송_설정: "Syslog 전송 설정",
  Syslog_포맷: "Syslog 포맷",
  SyslogAudit_로그패키지통합설치: "Syslog+Audit 로그 패키지 통합 설치",
  Sysmon_다운로드: "Sysmon 다운로드",
  Sysmon_설정: "Sysmon 설정",
  Ubuntu_16_04_18_04_20_04_지원: "Ubuntu 16.04, 18.04, 20.04 지원",
  URL_스캔: "URL 스캔",
  URL_추출로그: "URL 추출로그",
  URL경로: "URL(경로)",
  WAF_모듈_설치: "WAF 모듈 설치",
  Windows_Server_2012_이상: "Windows Server 2016 이상",
  XML_값: "XML 값",
};
export default languageKo;
