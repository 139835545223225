import React, { Fragment, useState, useEffect } from "react";
import { Observer, observer } from "mobx-react";
import { Input } from "@qubit-utill/src/components/atoms";

interface propsType {
  options: Array<any>;
  isOptionListDisplay: boolean;
  searchValue: string;
  isUseSelectedValueOsIcon: boolean;
  onChange: Function;
  onSelect: Function;
  onFocus: Function;
  searchPlaceholder?: string;
  initValue: Function;
}

const AutoComplete: React.FC<propsType> = ({
  options,
  isOptionListDisplay,
  searchValue,
  onSelect,
  onChange,
  onFocus,
  searchPlaceholder = "Search...",
  initValue,
  isUseSelectedValueOsIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTypeOs, setSelectedTypeOs] = useState("");
  const [isUseOsIcon, setIsUseOsIcon] = useState(true);

  const toggleIsOpen = (e, flag: boolean) => {
    e.stopPropagation();
    setIsOpen(flag);
  };

  const selectOption = async (data: { name: string; value: string; typeos?: string }, e) => {
    toggleIsOpen(e, false);
    data.typeos && setSelectedTypeOs(data.typeos);
    onSelect && onSelect(data);
  };

  const _renderAutoCompleteList = () => {
    return (
      <ul className="select2-results border-top">
        {options.map(data => (
          <li key={data.name} className="select2-result f-12">
            <div className="select2-result-label" onClick={e => selectOption(data, e)}>
              {data.typeos && <i className={`icon_os_${data.typeos}_s`} />}
              <span className="p-l-3">{data.name}</span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const onClickInit = () => {
    initValue();
    setIsOpen(false);
  };

  const _onFocus = () => {
    onFocus();
    setIsOpen(isOptionListDisplay);
  };

  useEffect(() => {
    setIsOpen(isOptionListDisplay);
  }, [isOptionListDisplay]);

  useEffect(() => {
    setIsUseOsIcon(isUseSelectedValueOsIcon && selectedTypeOs !== "" && searchValue !== "");
  });

  return (
    <Fragment>
      <div
        className={isOpen ? "select2-drop-mask" : ""}
        style={{ display: "block" }}
        onClick={e => toggleIsOpen(e, false)}
      />
      <div className="select2-drop select2-drop-active form-white w_400px">
        <div className="append-icon">
          {isUseOsIcon && (
            <i
              style={{
                left: 7,
                top: 10,
              }}
              className={`icon_os_${selectedTypeOs}_s`}
            />
          )}
          {isUseOsIcon && <span className="p-r-10" />}
          <Input.Text
            noSize
            noColor
            className="form-control form-white border_white"
            style={isUseOsIcon ? { width: "97%" } : { width: "100%" }}
            value={searchValue}
            onChange={onChange}
            onFocus={_onFocus}
            placeholder={searchPlaceholder}
          />
          <i className="fa fa-close" onClick={onClickInit} />
        </div>
        {isOpen && <Observer render={_renderAutoCompleteList} />}
      </div>
    </Fragment>
  );
};

export default observer(AutoComplete);
