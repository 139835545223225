import axiosModules from "@src/utils/config/axiosSetting";

export default {
  // 언어 > 언어 목록
  getLanguageList: (): Promise<any> => {
    return axiosModules.get("/language-list");
  },
  // 언어 > 언어 설정
  createLanguageInfo: (value: string): Promise<any> => {
    return axiosModules.post(`/language/${value}`);
  },
};
