import React from "react";
import { observer } from "mobx-react";
import { Row, Col } from "reactstrap";

import { Footer } from "@src/utils/components/Layout";
import BlogButton from "@src/common/components/atoms/Button/BlogButton";

interface PropsTypes {
  title?: string;
  isUseHelp?: boolean;
  titles?: any;
  UISET?: { title: string };
  urlCode?: string;
  timePickerBoard?: any;
  titleColMd?: string | number;
  titleColMdOffset?: string;
  contentColMd?: string | number;
  contentColMdOffset?: string;
}

const ContentLayout: React.FC<PropsTypes> = ({
  title,
  children,
  isUseHelp,
  UISET = { title: "" },
  urlCode = "",
  titleColMd = "8",
  titleColMdOffset = "2",
  contentColMd = "8",
  contentColMdOffset = "2",
}) => {
  const _title = UISET.title || title;

  return (
    <div className="page-content width-100p">
      <Row className="p-5">
        <Col md={titleColMd} className={`col-md-offset-${titleColMdOffset} p-t-30 f-12`}>
          <div className="c-black f-24 dis-inline-b">
            <span className="bold">{_title}</span>
          </div>
          {isUseHelp && <BlogButton code={urlCode} />}
        </Col>
      </Row>
      <Row className="p-5">
        <Col md={contentColMd} className={`col-md-offset-${contentColMdOffset}`}>
          <div className="panel">
            <div className="panel-content min_h650 p-40">{children}</div>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default observer(ContentLayout);
