const languageKo = {
  가능: "가능",
  가입인증이_완료되었습니다: "가입인증이 완료되었습니다.",
  가입인증처리: "가입인증처리",
  가입인증처리_하시겠습니까: "가입인증처리 하시겠습니까?",
  가입일: "가입일",
  개인정보: "개인정보",
  개인정보_수집_이용: "개인정보 수집/이용",
  개인정보_처리방침: "개인정보 처리방침",
  갱신: "갱신",
  경고: "경고",
  계정생성: "계정생성",
  관리: "관리",
  권한: "권한",
  그래프: "그래프",
  그룹고유키: "그룹고유키",
  나의_정보: "나의 정보",
  님: "님",
  단위: "단위",
  대시보드: "대시보드",
  더보기: "더보기",
  도메인: "도메인",
  도메인_관리: "도메인 관리",
  도메인_등록: "도메인 등록",
  도메인_만료: "도메인 만료",
  도메인_수정: "도메인 수정",
  도메인_확인_버튼을_클릭해주세요: "도메인 확인 버튼을 클릭해주세요.",
  도움말: "도움말",
  동의: "동의",
  등록일: "등록일",
  라이선스: "라이선스",
  라이선스_기간: "라이선스 기간",
  라이선스_코드: "라이선스 코드",
  라이선스_코드_관리: "라이선스 코드 관리",
  라이선스_코드_생성: "라이선스 코드 생성",
  라이선스_코드_생성되었습니다: "라이선스 코드 생성되었습니다.",
  라이선스_코드_히스토리: "라이선스 코드 히스토리",
  로그아웃: "로그아웃",
  로그인: "로그인",
  마지막_점검: "마지막 점검",
  만료: "만료",
  만료일: "만료일",
  메세지_라이선스_만료안내: "라이선스가 만료되었습니다. 신규 라이선스 코드를 입력하세요.",
  메세지_로그인해주세요: "로그인을 해주세요.",
  메세지_만료_n일전: "만료 %일전",
  메세지_만료_n일전입니다: "만료 %일전 입니다.",
  메세지_변경한비밀번호로다시로그인해라: "변경한 비밀번호로 다시 로그인해 주세요.",
  메세지_변경한PIN번호로다시로그인해주세요: "변경한 PIN번호로 다시 로그인해 주세요.",
  메세지_삭제완료: "삭제 되었습니다.",
  메세지_삭제하쉴: "삭제하시겠습니까?",
  메세지_수정완료: "수정 되었습니다.",
  메세지_신규라이선스코드_입력안내: "신규 라이선스 코드 입력 시 갱신되며 이전 라이선스 코드는 만료 처리됩니다.",
  메세지_인증메일_버튼을_클릭하시면_회원가입이_완료됩니다: "인증메일 버튼을 클릭하시면 회원가입이 완료됩니다.",
  메세지_인증메일_재발송: "인증메일이 재발송되었습니다.\n새로 발송된 인증메일을 확인하세요.",
  메세지_입력하신이메일로인증메일발송되며인증되야회원가입완료이다:
    "입력하신 이메일로 인증메일이 발송되며 인증 완료 시 회원가입 됩니다.",
  메세지_초대하였습니다: "초대하였습니다.",
  메세지_초대할사람이메일입력하라: "초대할 사람의 이메일을 입력해 주세요.",
  메세지_핀번호를_입력하세요: "PIN번호를 입력하세요.",
  메세지_항목선택: "항목을 선택해 주세요.",
  메세지_해킹의심경고: "해킹의심경고",
  메세지_회원가입완료되었다: "회원가입이 완료되었습니다.",
  메세지_회원가입한_이메일로_인증메일이_발송되었습니다: "회원가입한 이메일로 인증메일이 발송되었습니다.",
  메세지_n개선택했습니다: "%개가 선택되었습니다.",
  메타: "메타",
  멤버: "멤버",
  멤버수: "멤버수",
  미사용: "미사용",
  바로가기: "바로가기",
  발생: "발생",
  번: "번",
  범위: "범위",
  불가능: "불가능",
  비밀번호: "비밀번호",
  비밀번호_변경: "비밀번호 변경",
  비밀번호_입력: "비밀번호 입력",
  비밀번호_재입력: "비밀번호 재입력",
  사용: "사용",
  삭제: "삭제",
  삭제_가능한_항목이_없습니다: "삭제 가능한 항목이 없습니다.",
  상태: "상태",
  상태값: "상태값",
  새_비밀번호: "새 비밀번호",
  새_비밀번호_확인: "새 비밀번호 확인",
  새_PIN번호: "새 PIN번호",
  새_PIN번호_확인: "새 PIN번호 확인",
  새로고침: "새로고침",
  새창보기: "새창보기",
  생성: "생성",
  생성일: "생성일",
  생성자: "생성자",
  선택_취소: "선택 취소",
  속도: "속도",
  수정: "수정",
  수정할_회사명_입력: "수정할 회사명 입력",
  시도: "시도",
  아이디_이메일: "아이디(이메일)",
  아이디_이메일_입력: "아이디(이메일) 입력",
  알림: "알림",
  에러_네트워크: "네트워크 에러입니다.",
  에러_데이터가없습니다: "데이터가 없습니다.",
  에러_도메인검색_n시도횟수초과: "하루 최대 %회 조회할 수 있습니다.\n내일 다시 이용해 주세요.",
  에러_라이선스코드를_입력해_주세요: "라이선스 코드를 입력해 주세요.",
  에러_비밀번호를_입력해_주세요: "비밀번호를 입력해 주세요",
  에러_비밀번호를입력해주세요: "비밀번호를 입력해 주세요.",
  에러_비밀번호일치하지않는다: "비밀번호가 일치하지 않습니다.",
  에러_빈값을입력하세요: "입력되지 않은 정보가 있습니다. 정보를 입력해 주세요.",
  에러_아이디를입력해주세요: "아이디를 입력해 주세요.",
  에러_이메일입력해라: "이메일을 입력해 주세요.",
  에러_이용약관에동의해라: "이용약관에 동의해 주세요.",
  에러_정보를입력해라: "정보를 입력해 주세요.",
  에러_회사명입력해라: "회사명을 입력해 주세요.",
  에러_PIN번호가_일치하지_않습니다: "PIN번호가 일치하지 않습니다.",
  오늘접속: "오늘접속",
  완료일: "완료일",
  원인: "원인",
  웹사이트_위변조: "웹사이트 위변조",
  유효기간: "유효기간",
  응답시간: "응답시간",
  응답크기: "응답크기",
  의문문_수정하시겠습니까: "수정하시겠습니까?",
  이메일: "이메일",
  이메일_입력: "이메일 입력",
  이용약관: "이용약관",
  인증메일_재발송: "인증메일 재발송",
  인증메일을_재발송하시겠습니까: "인증메일을 재발송하시겠습니까?",
  인증메일이_발송되었습니다: "인증메일이 발송되었습니다.",
  인증상태: "인증상태",
  인증서: "인증서",
  인증서_만료: "인증서 만료",
  일_전: "일 전",
  입력되지_않은_정보_다시_시도: "입력되지 않은 정보가 있습니다.\n확인 후 다시 시도해 주세요.",
  재발송: "재발송",
  점검: "점검",
  접속일: "접속일",
  직접입력: "직접입력",
  찾기: "찾기",
  체험: "체험",
  초기화: "초기화",
  초대: "초대",
  총: "총",
  총게시물: "총게시물",
  총접속: "총접속",
  최근업데이트: "최근업데이트",
  최근접속일: "최근접속일",
  최대: "최대",
  최소: "최소",
  취소: "취소",
  취소하시겠습니까_목록으로_이동됩니다: "취소하시겠습니까?\n(확인 클릭 시 저장되지 않으며 목록으로 이동됩니다.)",
  코드: "코드",
  타이틀: "타이틀",
  태그: "태그",
  현재_비밀번호: "현재 비밀번호",
  현재_PIN번호_입력: "현재 PIN번호 입력",
  확인: "확인",
  회사: "회사",
  회사명: "회사명",
  회사별_관리: "회사별 관리",
  회사소개: "회사소개",
  회원_관리: "회원 관리",
  회원가입: "회원가입",
  회원고유키: "회원고유키",
  DNS_스푸핑: "DNS 스푸핑",
  DNS_br_스푸핑: "DNS<br/>스푸핑",
  IP주소: "IP주소",
  n_개_항목_삭제_하시겠습니까: "%개 항목 삭제하시겠습니까?",
  n개_모두_선택: "%개 모두 선택",
  n개_항목: "%개 항목",
  n일: "%일",
  n일_전: "%일 전",
  PIN번호: "PIN번호",
  PIN번호_변경: "PIN번호 변경",
  PIN번호_입력: "PIN번호 입력",
  PIN번호_재입력: "PIN번호 재입력",
  PIN번호_찾기: "PIN번호 찾기",
  SSL_만료: "SSL 만료",
};
export default languageKo;
