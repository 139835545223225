import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const Up = ({ size, onClick, color, className }) => {
  return (
    <Button size={size} color={color} onClick={onClick} className={className}>
      <i className="fa fa-caret-up" />
    </Button>
  )
}

Up.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string
}

Up.defaultProps = {
  size: "sm",
  color: "dark",
  className: ""
}

export default Up
