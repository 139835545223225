import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const PlusType = ({ size, color, className, children, onClick, disabled, isBack, fontSize }) => {
  const iSize = { fontSize }
  return (
    <Button size={size} color={color} onClick={onClick} className={className} disabled={disabled}>
      {isBack && <i className={`fa fa-plus ${children ? "p-r-5" : ""}`} />}
      {children}
      {!isBack && <i className="fa fa-plus" style={iSize} />}
    </Button>
  )
}

PlusType.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isBack: PropTypes.bool,
  fontSize: PropTypes.number
}

PlusType.defaultProps = {
  size: "sm",
  color: "dark",
  disabled: false,
  isBack: false,
  fontSize: 12
}

export default PlusType
