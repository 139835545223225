import last from "lodash-es/last";
import { DEFAULTLANG } from "../common_constants";
import dateUtil from "./dateUtil";
import arrayUtils from "./arrayUtils";

const Common_Utils = {
  lang: DEFAULTLANG,
  setCookie: function(name: string, value: any) {
    document.cookie = name + "=" + escape(value) + ";";
  },

  getObjectLength: function(object: object) {
    return Object.keys(object).length;
  },

  isEmpty: function(value: any) {
    if (
      (value !== null && typeof value === "object" && !Object.keys(value).length) ||
      (value !== null && typeof value === "number" && !String(value)) ||
      value === "" ||
      value === null ||
      value === undefined
    ) {
      return true;
    } else {
      return false;
    }
  },
  checkCookie: function(name: string) {
    var cookie = document.cookie;
    var req = "(^|;) ?" + name + "=([^;]*)(;|$)";
    return cookie.match(req);
  },
  //return object by url queryString
  parserQueryString: function() {
    var json = {};
    var checkstr = "?";
    var idx = window.location.href.indexOf(checkstr);
    if (idx > 0) {
      var arr = window.location.href.substr(idx + checkstr.length).split("&");
      for (var index = 0; index < arr.length; index++) {
        var temp = arr[index].split("=");
        if (temp.length === 1) {
          json[temp[0]] = "";
        } else if (temp.length === 2) {
          var queryvalue = decodeURIComponent(temp[1]).replace(/\+/g, " ");
          queryvalue = queryvalue.split("#")[0];
          json[temp[0]] = queryvalue;
        } else {
          continue;
        }
        //null처리
      }
    }
    return json;
  },

  /**
   * params를 QueryString으로 반환 해준다.
   */
  requestBuildQueryString: function(params: any) {
    var queryString: string[] = [];
    for (var property in params)
      if (params.hasOwnProperty(property)) {
        if (params[property] !== undefined) {
          queryString.push(
            `${encodeURIComponent(property)}=${encodeURIComponent(params[property])}`
          );
        }
      }
    return queryString.join("&");
  },

  currentUrl: function() {
    var location;

    //window.location.origin 이 ie11부터 있네
    if (window.location.origin) {
      location = window.location.origin + window.location.pathname;
    } else {
      location = window.location.href;
      location = location.split("?");
      location = location[0];
    }
    return location;
  },

  substringBefore: function(str: string, substr: string) {
    str.substring(0, str.indexOf(substr));
    return str;
  },
  substringAfter: function(str: string, substr: string) {
    str.substring(str.indexOf(substr), str.length);
    return str;
  },

  validCheck(response: { returnCode: any }, returnCodes: []) {
    let valid = false;
    if (response && response.returnCode && returnCodes) {
      valid = Boolean(returnCodes.find(returnCode => response.returnCode === returnCode));
    }

    return valid;
  },

  setClassProperty(params: object, _this: any) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        _this[key] = params[key];
      }
    }
  },

  constructClassList(list: any[], classObj: any) {
    try {
      if (list) {
        return list.map(data => new classObj(data));
      }
      return [];
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  constructClassListResetOrder(list: any[], classObj: any, orderKey: string) {
    try {
      return list.map((data, index) => {
        data[orderKey] = index;
        return new classObj(data);
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  },

  //배열 특정 원소 제거 하기
  removeArrElemenet(arrOriginal: [], elementToRemove: any) {
    return arrOriginal.filter(el => {
      return el !== elementToRemove;
    });
  },
  //배열 특정 원소 제거 하기
  removeArrAnoterArr(arrOriginal: [], arrToRemove: any, elementName: string) {
    let newArr = [];

    newArr = arrToRemove.reduce((accumulator: any, elementToRemove: any) => {
      if (elementName) {
        elementToRemove = elementToRemove[elementName];
      }
      return accumulator.filter((el: any) => {
        return el !== elementToRemove;
      });
    }, arrOriginal);
    return newArr;
  },
  //배열 특정 원소 제거 하기
  removeArrAnoterObject(arrOriginal: [], objectToRemove: any, elementName: string) {
    return arrOriginal.filter((el: any) => {
      if (objectToRemove[el] !== undefined && objectToRemove[el] !== null) {
        return el !== objectToRemove[el][elementName];
      } else {
        return true;
      }
    });
  },
  setArrByIndex(arrOriginal: [], keyName: string, validFunc: Function) {
    let newObj = {};
    arrOriginal.forEach(element => {
      if ((this.isNull(validFunc) && validFunc(element)) || !this.isNull(validFunc)) {
        newObj[element[keyName]] = element;
      }
    });

    return newObj;
  },
  //일부를 포함하는지
  isIncludeArr(parentArr: any[], childArr: any[]) {
    return childArr.some(element => {
      if (parentArr.indexOf(String(element)) < 0) {
        return false;
      }
      return true;
    });
  },
  //전체를 포함하는지
  isAllIncludeArr(childArr: [], parentArr: any[]) {
    let isAllInclude = true;
    childArr.forEach(element => {
      if (parentArr.indexOf(Number(element)) < 0) {
        isAllInclude = false;
      }
    });
    return isAllInclude;
  },
  isNull(element: any) {
    return element !== undefined && element !== null;
  },
  isCheckBool: (isUse: string) => {
    return isUse === "1" ? true : false;
  },
  BoolToString: (isFlag: boolean) => {
    return isFlag ? "1" : "0";
  },
  dateFormat: (date: string) => {
    var returnDate = "-";

    if (date != null) {
      returnDate = date.split(".")[0];
    }

    return returnDate;
  },

  getMiliseconds: function(minute: number) {
    return minute * 60000;
  },

  getSeconds: function(minute: number) {
    return minute * 60;
  },

  //오브젝트로 이루어진 배열을 특정 오브젝트가 포함하는지 확인하는 함수
  isObjectIncludeArr(obj: object, arr: [], key: string) {
    if (obj) {
      let isAllInclude = true;
      arr.forEach(element => {
        if (!obj[element[key]]) {
          isAllInclude = false;
        }
      });
      return isAllInclude;
    }
    return;
  },
  pad2: function(n: number) {
    return (n < 10 ? "0" : "") + n;
  },

  getOffset: function(page: number, limit: number) {
    return (page - 1) * limit;
  },
  onlyNumber: (value: any) => {
    //숫자만
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      return true;
    }
    return false;
  },
  getCurrentPath() {
    let newPath = window.location.pathname.split("/");

    const findIndex = newPath.findIndex(
      el => el === "edit" || el === "regist" || el === "page" || el === "detail"
    );

    if (findIndex > 0) {
      newPath = newPath.slice(0, findIndex);
    } else if (!isNaN(Number(last(newPath)))) {
      newPath.pop();
      newPath.pop();
    }

    return newPath;
  },
  getCurrentPathUrl() {
    return this.getCurrentPath().join("/");
  },
  getCurrentPathName() {
    return this.getCurrentPath().join("");
  },
  getPathName(path: string) {
    return path.split("/").join("");
  },
  getPage(offset: number, limit: number) {
    return offset / limit + 1;
  },
  getSelectedOptionDate: function(value: string, callback: Function) {
    var startTime = "00:00:00";
    var endTime = "23:59:59";

    var date = new Date();
    var today = dateUtil.getDateByForm(date, "-");
    var returnDate = today;

    if (value === "week") {
      var week = date;
      week.setDate(week.getDate() - 6);
      returnDate = dateUtil.getDateByForm(week, "-");
    } else if (value === "month") {
      var month = date;
      month.setMonth(month.getMonth() - 1);
      returnDate = dateUtil.getDateByForm(month, "-");
    } else if (value === "total") {
      var total = date;
      total.setMonth(total.getMonth() - 3);
      returnDate = dateUtil.getDateByForm(total, "-");
    }

    callback && callback(`${returnDate} ${startTime}`, `${today} ${endTime}`);
    return [`${returnDate} ${startTime}`, `${today} ${endTime}`];
  },
  numberWithComma: function(n: string | number) {
    if (n) {
      return Number(n).toLocaleString();
    }
    return n;
  },

  getByteLength: function(str: any) {
    return new Blob([str]).size;
  },

  validEmail: function(email: string) {
    let re = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  },

  getParameterByName: function(name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  csvDownload: function(csv: any, fileName: string) {
    const link = document.createElement("a");
    link.download = `${fileName || "chart"}.csv`;
    link.href = URL.createObjectURL(
      new Blob(["\uFEFF" + csv], { type: "text/csv; charset=UTF-8" })
    );
    link.click();
  },
  getDownloadFileName: function(menu: string, service: string, middle: string, date: string) {
    return `${menu}${service ? "_" + service : ""}${middle ? "_" + middle : ""}${
      date ? "_" + date : ""
    }`;
  },
  getParsingLocalItem(localStorageName: string): any | null {
    const value = localStorage.getItem(localStorageName);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  },

  getCSVText(text) {
    const _text = String(text).replace(/\n/g, " ");
    return isNaN(Number(_text))
      ? _text.indexOf(",") >= 0
        ? `"${_text}"`
        : _text
      : `"${Common_Utils.numberWithComma(_text)}"`;
  },
  makeCsvRowData(info: object, keyList: string[][]): string {
    let csv = "";
    keyList.forEach(keys => {
      if (typeof keys === "string") {
        csv += `${this.getCSVText(info[keys])},`;
      } else {
        keys.forEach((str, index) => {
          csv += `${this.getCSVText(info[str])}${keys.length === index + 1 ? "," : "/"}`; //한 컬럼에 여러값있을때
        });
      }
    });
    csv = csv.slice(0, csv.lastIndexOf(",")); //마지막 콤마 빼기위함
    csv += "\r\n";
    return csv;
  },
  makeCSV(head: string[], dataList: any[], etcText?: string, notUseNo?: boolean, isDesc?: boolean) {
    const _head = etcText ? arrayUtils.replaceText(head, "etcText", etcText) : head;
    let csv = `${notUseNo ? "" : "NO,"}${_head.toString()}\r\n`;
    dataList.forEach((info, index) => {
      csv += `${notUseNo ? "" : (isDesc ? dataList.length - index : index + 1) + ","}${
        info.csvData
      }`;
    });
    return csv;
  },
  getCountNumberFormat(count: string): any | null {
    return !this.isEmpty(count) ? this.numberWithComma(count) : "-";
  },
  getTrFiltersText(key: string, list: any[]): string {
    let str = "";
    list &&
      list.forEach((data, index, arr) => {
        str += `${data[key] !== null ? data[key] : "-"}${index + 1 !== arr.length ? "/" : ""}`;
      });
    return str;
  },
  getParseBytes(unit: string, bytes: any) {
    if (bytes === null) return bytes;
    switch (unit) {
      case "KB":
        return Number((bytes / 1024).toFixed(2));
      case "MB":
        return Number((bytes / 1024 / 1024).toFixed(2));
      case "GB":
        return Number((bytes / 1024 / 1024 / 1024).toFixed(2));
      default:
        return bytes;
    }
  },
  getFormatBytes(bytes: number, isUseComma: boolean = false, fixedNumber: number = 2) {
    let unit = "bytes";
    let bytesStr = String(bytes);

    if (bytes >= 1024 ** 3) {
      unit = "GB";
      bytesStr = (bytes / 1024 ** 3).toFixed(fixedNumber);
    } else if (bytes >= 1024 ** 2) {
      unit = "MB";
      bytesStr = (bytes / 1024 ** 2).toFixed(fixedNumber);
    } else if (bytes >= 1024) {
      unit = "KB";
      bytesStr = (bytes / 1024).toFixed(fixedNumber);
    }

    return (isUseComma ? this.numberWithComma(bytesStr) : bytesStr) + ` ${unit}`;
  },
  getSecondToMinuteSecond(second: number) {
    let hour = Math.floor(second / 3600);
    let min = Math.floor((second - hour * 3600) / 60);
    let sec = second - hour * 3600 - min * 60;

    return (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
  },

  getMinuteToHour(min: number) {
    return Math.floor(min / 60);
  },

  isInputNumberLimitCheck(startNum: number, endNum: number, value: number) {
    return this.onlyNumber(value) && (value >= startNum && value <= endNum);
  },
  textOverflow(value: string, sliceCount: number) {
    return `${value.slice(0, sliceCount)}${value.length > sliceCount ? " ..." : ""}`;
  },
  getPlusMinusRenderFlag(index: number, length: number) {
    return {
      plus: index + 1 === length,
      minus: length !== 1,
    };
  },
  setBreachInformation(data: Array<{ value: string }>) {
    let _setBreachInformation: Array<{}> = [];
    if (data) {
      data.filter(
        ({ value }, index) => value && _setBreachInformation.push(index === 0 ? value : ` ${value}`)
      );
    }
    return _setBreachInformation.length > 0 && _setBreachInformation.toString();
  },
  isDataWithDashBar(data: any) {
    return data || "-";
  },
  goToInstallAgentsWithLicence(booleanValue: boolean, URI: string) {
    if (booleanValue) {
      return URI;
    } else {
      return "INSTALL_WINDOWS";
    }
  },
  getBlockFirstBlank(value: string) {
    return value.replace(/^\s*/, "");
  },
  setSearchOption(dataObject: Array<{ key: string; value: any; code: string }>) {
    const optionParams = {};
    dataObject.filter(({ key, value, code }) => {
      value && (optionParams[key] = `${code ? `${code}:` : ""}${value}`);
    });
    return optionParams;
  },
  setSearchOptionToArr(dataList: Array<[string, string, string]>) {
    const optionParams = {};
    dataList.filter(data => {
      data[1] && (optionParams[data[0]] = `${data[2] ? `${data[2]}:` : ""}${data[1]}`);
    });
    return optionParams;
  },
  inspectValue(originValue: string, inspectedValue: string = "-", changeValue: string = "") {
    return originValue === inspectedValue ? changeValue : originValue;
  },
  setObjectInfoWithDashBar(object: object, toCSV: object) {
    let info = {};
    info = { ...toCSV };
    for (const property in object) {
      info[property] = Common_Utils.isDataWithDashBar(object[property]);
    }
    return info;
  },
  arrayLengthCheck(data: object, arrayKeyName: string) {
    return data && data[arrayKeyName] && data[arrayKeyName].length > 0;
  },
  getArray(data: object, arrayKeyName: string) {
    return this.arrayLengthCheck(data, arrayKeyName) && data[arrayKeyName];
  },
  setCsvFileName({ title, category, date, ...params }: any) {
    let startDate = "";
    let endDate = "";
    const _category = category ? `${category}_` : "";
    const comparedText = (key: string, text: string) => key.toLowerCase().includes(text);
    for (let key in params) {
      if (comparedText(key, "date")) {
        comparedText(key, "start") && (startDate = params[key]);
        comparedText(key, "end") && (endDate = params[key]);
      }
    }
    const _date = date ? date : dateUtil.getRangeString(startDate, endDate);
    return `${title}_${_category}${_date}`;
  },
  setArrayObjToStringForCSV(arrayData: Array<any>, keyName: string) {
    let value = "";
    arrayData.map((data: any, index: number) =>
      index === 0 ? (value = data[keyName]) : (value = `${data[keyName]}, ${value}`)
    );
    return value;
  },
  setOnlyText(value: string) {
    return value.replace(/\s/g, "");
  },

  setValueForMultiplePathHost(JSON_Data: string, keyName: string) {
    const returnData: Array<any> = [];
    try {
      // JSON array 처리
      const _JSON_Data = JSON.parse(JSON_Data);
      returnData.push(..._JSON_Data);
    } catch {
      // JSON string 처리
      const objectData: any = {};
      objectData[keyName] = JSON_Data;
      returnData.push(objectData);
    }
    return returnData;
  },
};
export default Common_Utils;
