import React from "react";
import { CheckBox } from "../CheckBox";
import { Observer, observer } from "mobx-react";
import { ThFieldsConfig, SORTTYPE } from "../../../store/";
import LicenseCheckSwitch from "../../organisms/LicenseCheck/LicenseCheckSwitch";
import { TableFieldModelParamType } from "../../../model/TableFieldModel";

interface PropsTypes {
  toggleAllCheck?: () => void;
  colorTheme: string;
  isUseCheck?: boolean;
  isAllCheck: boolean;
  isUseSetting: boolean;
  useIndex: boolean;
  thFields: ThFieldsConfig;
  rowThFields?: ThFieldsConfig;
  thClassName?: string;
  sortIconColor?: string;
  noClassName?: string;
  noWidthClassName?: string;
  settingThemeColor?: string;
  settingRowSpan?: number;
  useRow?: boolean;
}

const SortICon = ({
  type,
  isActive,
  className,
  onClick,
  sortIconColor,
}: {
  type: string;
  isActive: boolean;
  className: string;
  onClick: () => void;
  sortIconColor: string;
}) => {
  return (
    <span className="dis-block h-10">
      <a className={`${sortIconColor} cursor-pointer`} onClick={onClick}>
        <i className={`fa fa-sort-${type} ${isActive && "c-orange"} ${className}`}></i>
      </a>
    </span>
  );
};

const SortStatus: React.FC<{
  sortType: SORTTYPE;
  onClick: any;
  isActive: boolean;
  sortIconColor: string;
}> = ({ sortType, onClick, isActive, sortIconColor }) => {
  return (
    <span className="table_column_sort">
      <SortICon
        type={"up"}
        isActive={isActive && sortType === SORTTYPE.UP}
        className={"verti_bottom"}
        onClick={() => onClick(SORTTYPE.UP)}
        sortIconColor={sortIconColor}
      ></SortICon>
      <SortICon
        type={"down"}
        isActive={isActive && sortType === SORTTYPE.DOWN}
        className={"verti_top"}
        onClick={() => onClick(SORTTYPE.DOWN)}
        sortIconColor={sortIconColor}
      ></SortICon>
    </span>
  );
};

const ThBuilder: React.FC<PropsTypes> = ({
  toggleAllCheck,
  colorTheme,
  isUseCheck,
  isAllCheck,
  isUseSetting,
  thFields,
  useIndex,
  thClassName = "h-40",
  sortIconColor = "c-white",
  noWidthClassName = "width_45",
  noClassName = "t-center",
  settingThemeColor = "",
  settingRowSpan = 1,
  useRow = false,
  rowThFields,
}) => {
  const _renderFields: any = (list: Array<TableFieldModelParamType>) => {
    return list.map(
      ({
        displayName,
        colspan,
        rowSpan,
        isActive,
        useSort,
        useSortClassName,
        useSortBrClassName,
        columnName,
        licenseCode,
        className,
        customTheme,
      }) => {
        const _colorTheme = customTheme ? customTheme : colorTheme;
        const brIndex =
          displayName && typeof displayName === "string" && displayName.search("<br/>");
        return (
          isActive && (
            <LicenseCheckSwitch licenseCode={licenseCode} key={columnName || displayName}>
              <th colSpan={colspan || 1} rowSpan={rowSpan || 1} className={_colorTheme}>
                <div className={className}>
                  <span className={`table_column_area ${useSort ? useSortClassName : ""}`}>
                    {brIndex > 0
                      ? displayName.split("<br/>").map((line, index) => {
                          return (
                            <span key={index} className={useSortBrClassName}>
                              {line}
                              <br />
                            </span>
                          );
                        })
                      : displayName}
                  </span>
                  {useSort && (
                    <SortStatus
                      sortType={thFields.sortType}
                      onClick={sortType => thFields.setSelectedSort(columnName, sortType)}
                      isActive={thFields.selectedSort === columnName}
                      sortIconColor={sortIconColor}
                    />
                  )}
                </div>
              </th>
            </LicenseCheckSwitch>
          )
        );
      }
    );
  };

  return (
    <thead>
      <tr className={`${colorTheme} ${thClassName}`}>
        {isUseCheck && (
          <th className={colorTheme}>
            <div className="width_45 t-center">
              <CheckBox
                type={"flat"}
                color={"red"}
                onToggle={toggleAllCheck}
                isChecked={isAllCheck}
              />
            </div>
          </th>
        )}
        {useIndex && (
          <th className={`${colorTheme} ${noWidthClassName}`}>
            <div className={noClassName}>NO</div>
          </th>
        )}
        <Observer render={() => _renderFields(thFields.list)} />
        {isUseSetting && (
          <th
            rowSpan={settingRowSpan}
            className={settingThemeColor ? settingThemeColor : colorTheme}
          />
        )}
      </tr>
      {useRow && rowThFields && (
        <tr className={`${colorTheme} ${thClassName}`}>
          <Observer render={() => _renderFields(rowThFields.list)} />
        </tr>
      )}
    </thead>
  );
};

export default observer(ThBuilder);
