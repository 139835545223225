import { observable, action } from "mobx";
import { startOfToday, endOfToday } from "date-fns";

import { Common_Utils, dateUtil } from "@qubit-utill/src/utils";
import { LINECOUNT, formatConstants } from "@qubit-utill/src/common_constants";

import { ListFilterModel } from "@qubit-utill/src/model";

class SearchListFilterStore {
  constructor(root) {
    this.root = root;
  }
  root;

  @observable
  selectList = [];

  @observable
  labelList = [];

  @observable
  sessionSelectedList = [];

  @action
  clearSearchList = () => {
    this.selectList = [];
    this.labelList = [];
    this.isUseCursorMark = null;
  };

  @observable
  isUseCursorMark = null;

  @action
  setInfo = (key, value) => {
    this[key] = value;
  };

  @action
  getSearchMenuList = (api, params) => async _searchkey => {
    try {
      const SEARCHKEY = _searchkey || Common_Utils.getCurrentPathName();
      const setParams = { ...params };

      this.isUseCursorMark !== null && (setParams["isUseCursorMark"] = this.isUseCursorMark);

      const { info } = await api(SEARCHKEY, setParams);

      this.selectList = Common_Utils.constructClassList(info.selectList, ListFilterModel);
      this.labelList = Common_Utils.constructClassList(info.labelList, ListFilterModel);

      let newParams = {
        offset: 0,
        limit: LINECOUNT,
      };
      this.isUseCursorMark !== null && (newParams["isUseCursorMark"] = this.isUseCursorMark);

      this.selectList.forEach(info => {
        if (
          info.componentType === "combineDatepicker" ||
          info.componentType === "noTimeDatepicker"
        ) {
          newParams[info.datepickerMinDateParameter] = dateUtil.format(
            startOfToday(),
            info.componentType === "noTimeDatepicker"
              ? formatConstants.DATEFORMAT
              : formatConstants.DATEFORMATWITHTIME
          );
          newParams[info.datepickerMaxDateParameter] = dateUtil.format(
            endOfToday(),
            info.componentType === "noTimeDatepicker"
              ? formatConstants.DATEFORMAT
              : formatConstants.DATEFORMATWITHTIME
          );
        } else {
          const defaultInfo =
            info.selectEntryList &&
            info.selectEntryList.find(entryInfo => Common_Utils.isCheckBool(entryInfo.isDefault));

          newParams[info.searchMenuSelectCode] = defaultInfo
            ? defaultInfo.selectEntryEntryValue
            : "";
        }
      });

      this.labelList.forEach(info => (newParams[info.searchMenuSelectCode] = ""));

      if (
        this.root.SearchWordStore &&
        this.root.SearchWordStore.searchWordList[SEARCHKEY] &&
        this.root.SearchWordStore.searchWordList[SEARCHKEY].length > 0
      ) {
        this.root.SearchWordStore.searchWordList[SEARCHKEY].forEach(data => {
          if (data.isApply) {
            if (newParams[data.searchOption]) {
              newParams[data.searchOption] =
                typeof newParams[data.searchOption] === "string"
                  ? [newParams[data.searchOption], data.searchTextParam]
                  : [...newParams[data.searchOption], data.searchTextParam];
            } else {
              newParams[data.searchOption] = data.searchTextParam;
            }
          }
        });
      }

      return newParams;
    } catch (e) {
      console.log(e);
      this.selectList = [];
      this.labelList = [];
    }
  };
}

export default SearchListFilterStore;
