import { TABLE, TD, SPAN } from "@src/constants/tableStyleCode/tableStyleCode";
import { default as path } from "@src/constants/path";
declare global {
  interface Window {
    GlobalPathMap: any;
  }
}

const { APIURL, CDNURL, COOKIENAME, DEFAULTLANG, COOKIELANG } = path;

const LINECOUNT = 20;
const LANGCOOKIENAME = "LANG";
const LANG = "ko";
const STYLE = { TABLE, TD, SPAN };

export { default as getURI } from "./uriset";
export { default as UISET } from "./uiset/index";
export { default as path } from "./path";
export { LINECOUNT, APIURL, CDNURL, COOKIENAME, DEFAULTLANG, COOKIELANG, LANG, LANGCOOKIENAME };
export { STYLE };
export { default as linkUrlCode } from "./linkUrlCode";
export { default as licenseCode } from "./licenseCode";
