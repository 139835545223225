import axiosModules from "@src/utils/config/axiosSetting";

export default {
  // DOMAIN > 도메인 갱신 검색
  getRenewalDomainInfo: (param) => axiosModules.get("/domain/renewal", param) as Promise<any>,

  // DOMAIN > 도메인 정보 조회
  getDomainInfo: (param) => axiosModules.get("/domain", param) as Promise<any>,

  // DOMAIN > 도메인 삭제
  deleteDomain: (param) => axiosModules.del("/domain", param),

  // DOMAIN > 도메인 등록 및 수정
  updateDomain: (param) => axiosModules.put("/domain", param),
};
