import languageSet from "@src/utils/lang/languageSet";
import getURI from "@src/constants/uriset";
import { DomainSetContainer, DomainGraphContainer } from "@src/pages";

export default {
  DOMAIN_REGIST: {
    text: languageSet("도메인_등록"),
    link: getURI("DOMAIN_REGIST"),
    title: languageSet("도메인_등록"),
    component: DomainSetContainer,
  },
  DOMAIN_EDIT: {
    text: languageSet("도메인_수정"),
    link: `${getURI("DOMAIN_EDIT")}/:id`,
    isEdit: true,
    title: languageSet("도메인_수정"),
    component: DomainSetContainer,
  },
  DOMAIN_SPEED: {
    text: `${languageSet("속도")} ${languageSet("그래프")}`,
    link: getURI("DOMAIN_SPEED"),
    title: `${languageSet("속도")} ${languageSet("그래프")}`,
    graphType: "speed",
    component: DomainGraphContainer,
  },
  DOMAIN_SIZE: {
    text: `${languageSet("응답크기")} ${languageSet("그래프")}`,
    link: getURI("DOMAIN_SIZE"),
    title: `${languageSet("응답크기")} ${languageSet("그래프")}`,
    graphType: "size",
    component: DomainGraphContainer,
  },
};
