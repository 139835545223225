import axiosModules from "@src/utils/config/axiosSetting";

export default {
  // 초대 > 회원 초대
  createInvitation: (data): Promise<any> => {
    return axiosModules.post("/invitation/invite", data);
  },

  // 초대 > 초대 확인
  getInvitationCheckInfo: (code: string): Promise<any> => {
    return axiosModules.get(`/invitation/check/${code}`);
  },

  // 초대 > 초대 가입
  createInvitationMember: (data): Promise<any> => {
    return axiosModules.post("/invitation", data);
  },
};
