import { observable, action, toJS } from "mobx";

import { UserException } from "@qubit-utill/src";

import { companyApis } from "@src/apis";
import LicenseCodeModel from "@src/pages/LicenseCode/models/LicenseCodeModel";

class LicenseCodeStore {
  constructor(root: any) {
    this.root = root;
  }
  root: any;

  @observable
  info: LicenseCodeModel = new LicenseCodeModel(null);

  @observable
  licenseCodeList: Array<{ code: string; value: string }> = [];

  /**
   * 로그인 계정 라이선스 확인
   */
  @action
  getLicenseInfo = async () => {
    try {
      const { info } = await companyApis.getCompanyLicenseInfo();
      await this.getLicenseCodeList();
      this.info = new LicenseCodeModel(info);
      this.getIsOverLicense("Gold", "isOverGold");
      this.getIsOverLicense("Platinum", "isOverPlatinum");
    } catch (error) {
      this.info = new LicenseCodeModel(null);
      this.info.setInfo("expired", true);
    }
  };

  /**
   * 라이선스 정보 수정
   * @param licenseCode
   */
  @action
  updateCompanyLicense = async (licenseCode: string) => {
    try {
      await companyApis.updateCompanyLicense({ licenseCode });
      await this.getLicenseInfo();
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };

  @action
  getLicenseCodeList = async () => {
    try {
      const { list } = await companyApis.getLicenseCodeList();
      this.licenseCodeList = toJS(list);
    } catch (err) {
      this.licenseCodeList = [];
    }
  };

  @action
  getIsOverLicense = (licenseCode, key) => {
    let checkIndex = 0;
    let licenseIndex = 0;
    this.licenseCodeList.length > 0 &&
      this.licenseCodeList.forEach(({ value }, index) => {
        value === licenseCode && (checkIndex = index);
        value === this.info.licenseGrade && (licenseIndex = index);
      });
    this.info._setInfo(key)(checkIndex <= licenseIndex);
  };
}

export default LicenseCodeStore;
