import React from "react";
import { observer } from "mobx-react";

import { useInjectStore } from "@qubit-utill/src";
import { CommonModal } from "..";

const ModalContainer = () => {
  const { ModalStore } = useInjectStore();
  const { modals } = ModalStore;

  let _modals = [];
  for (const uuidKey in modals) {
    if (modals.hasOwnProperty(uuidKey)) {
      if (modals[uuidKey].isUseCustomModal) {
        let CustomModal = modals[uuidKey].children;
        _modals.push(<CustomModal uuidKey={uuidKey} key={uuidKey} {...modals[uuidKey]} />);
      } else {
        _modals.push(<CommonModal uuidKey={uuidKey} key={uuidKey} {...modals[uuidKey]} />);
      }
    }
  }
  return _modals;
};

export default observer(ModalContainer);
