import React, { Component } from "react";
import { Pagination, PaginationItem, Row, Col } from "reactstrap";
import PropsTypes from "prop-types";
import last from "lodash-es/last";
import { Observer, observer } from "mobx-react";
import { observable } from "mobx";
import customHistory from "../../../../History";

@observer
export default class CustomPagination extends Component {
  @observable
  page = 1;

  pageLimit = 10;

  static defaultProps = {
    totalCount: 0,
    LINECOUNT: 0,
    className: "m-t-10",
  };

  static PropsTypes = {
    totalCount: PropsTypes.number,
    LINECOUNT: PropsTypes.number,
    changePage: PropsTypes.func,
    isUseUrl: PropsTypes.boolan,
  };

  onClickPage = newPage => {
    const { changePage, isUseUrl, isUsePageHistory } = this.props;

    this.page = newPage;
    changePage(newPage);

    if (isUseUrl && isUsePageHistory) {
      let newPath = customHistory.location.pathname.split("/");

      if (!isNaN(Number(last(newPath)))) {
        newPath.pop();
        newPath = newPath.join("/");
      } else {
        newPath = newPath.join("/") + "/page";
      }

      customHistory.push(`${newPath}/${newPage}`);
    }
  };

  _renderPage = () => {
    const { totalCount, LINECOUNT, page } = this.props;

    const PAGELIMIT = this.pageLimit;
    const PAGE = page || this.page;
    let limit = LINECOUNT;

    let first = Math.floor((PAGE - 1) / PAGELIMIT) * PAGELIMIT + 1;
    let prev = first - PAGELIMIT;
    let after = first + PAGELIMIT;

    let pageItemCount = Math.floor(totalCount / limit);
    if (totalCount % limit !== 0) {
      pageItemCount++;
    }

    let pageItem = [];
    for (let i = first; i < after; i++) {
      if (i > pageItemCount) {
        break;
      }
      pageItem.push(
        <PaginationItem
          key={"pagenation" + i}
          className={Number(PAGE) === i ? "paginate_button active" : "paginate_button"}
        >
          <a style={{ cursor: "pointer" }} onClick={() => this.onClickPage(i)}>
            {i}
          </a>
        </PaginationItem>
      );
    }

    return (
      <Pagination>
        {PAGE > PAGELIMIT && (
          <PaginationItem>
            <a style={{ cursor: "pointer" }} onClick={() => this.onClickPage(prev)}>
              <i className="fa fa-angle-left" />
            </a>
          </PaginationItem>
        )}
        {pageItem}
        {after <= pageItemCount && (
          <PaginationItem>
            <a style={{ cursor: "pointer" }} onClick={() => this.onClickPage(after)}>
              <i className="fa fa-angle-right" />
            </a>
          </PaginationItem>
        )}
      </Pagination>
    );
  };

  render() {
    return (
      <Row className={this.props.className}>
        <Col md="12" className="pagination2">
          <div className="dataTables_paginate paging_simple_numbers">
            <Observer render={this._renderPage} />
          </div>
        </Col>
      </Row>
    );
  }
}
