import React from "react"

const StringFormat = props => {
  const formatting = props => {
    if (props && typeof props === "string") {
      return props.split("\n").map((line, index) => {
        return (
          <span key={index}>
            {line}
            <br />
          </span>
        )
      })
    } else {
      return props
    }
  }
  return formatting(props)
}

StringFormat.propTypes = {}

export default StringFormat
