import { observable, action } from "mobx";

class LoadingStore {
  @observable
  root: any;

  @observable
  isLoading = true;

  @action
  Loading = () => {
    this.isLoading = true;
  };

  @action
  completed = () => {
    this.isLoading = false;
  };
}

export default new LoadingStore();
