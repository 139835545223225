import React from "react";
import { Button } from "reactstrap";

interface PropsTypes {
  text?: string;
  color: string;
  size?: string;
  onClick: Function;
  className?: string;
  style?: object;
  disabled?: string;
  isRounded?: boolean;
}

const Rounded: React.FC<PropsTypes> = ({
  text,
  color,
  size,
  onClick,
  children,
  className = "",
  style,
  disabled,
  isRounded = true,
}) => {
  return (
    <Button
      color={color}
      size={size}
      className={`${isRounded && "btn-rounded"} ${className}`}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {text || children}
    </Button>
  );
};

export default Rounded;
