import { observable, computed } from "mobx";
import { Common_Utils } from "@qubit-utill/src/utils";
import FilterEntryModel from "./FilterEntryModel";
import SetterModel from "../SetterModel";

export default class ListFilterModel extends SetterModel {
  constructor(param) {
    super(param);
    if (param.selectEntryList) {
      this.selectEntryList = Common_Utils.constructClassList(
        param.selectEntryList,
        FilterEntryModel
      );
    }
  }
  @computed
  get isUseImageFlag() {
    return Common_Utils.isCheckBool(this.isUseImage);
  }

  @computed
  get isUseTitleFlag() {
    return Common_Utils.isCheckBool(this.isUseTitle);
  }

  @computed
  get isUseSelectEntryFilterFlag() {
    return Common_Utils.isCheckBool(this.isUseSelectEntryFilter);
  }

  @observable
  searchMenuSelectOrderNo = 1;
  @observable
  searchMenuSelectName = "";
  @observable
  searchMenuSelectCode = "";
  @observable
  isUseImage = "0";
  @observable
  isSingleSelect = "";
  @observable
  searchMenuSelectCodeLink = null;
  @observable
  selectEntryList = [];
  @observable
  isUseTitle = "0";
  @observable
  isUseSelectEntryFilter = "0";
}
