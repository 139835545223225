import { computed, observable } from "mobx";

import { SetterModel, stringUtils, Common_Utils } from "@qubit-utill/src";

interface NameValueObject {
  name: string;
  value: string;
}

class SystemLogModel extends SetterModel {
  @computed
  get msgEventData() {
    return this.previewMessage ? `${this.previewMessage.slice(0, 100)}${this.previewMessage.length > 100 ? " ..." : ""}` : "-";
  }

  @computed
  get msgEventDataForCSV() {
    return this.formatLogOrigin && this.typeos === "windows"
      ? stringUtils.replaceAll(this.windowsPreviewMessage, `\n`, "")
      : stringUtils.replaceAll(this.logMessage, `\n`, "");
  }

  @computed
  get windowsPreviewMessage() {
    const dataName = this.formatLogOrigin.indexOf("UserData") >= 0 ? "UserData" : "EventData";
    const tagStrig = this.formatLogOrigin.split(`<${dataName}`)[1];
    if (tagStrig) {
      const subedTagString = tagStrig.substring(tagStrig.indexOf("\n"), tagStrig.length);
      const result = subedTagString.split(`</${dataName}>`)[0];
      return result.trim();
    }
    return "";
  }

  @computed
  get previewMessage() {
    return this.formatLogOrigin && this.typeos === "windows" ? this.windowsPreviewMessage : this.logMessage;
  }

  @computed
  get logOriginType() {
    return this.typeos !== "windows" ? "json" : "xml";
  }

  @computed
  get isUseAlign() {
    return this.programMessageTemplate !== null;
  }

  @computed
  get flagNotWindowSyslog() {
    return this.filterChannelName && this.filterChannelName.toLowerCase() === "syslog" && this.typeos !== "windows";
  }

  @computed
  get priority() {
    return this.filterPriorityName ? `${Common_Utils.textOverflow(this.filterPriorityName, 8)}(${this.logPriorityId})` : "-";
  }

  @computed
  get eventType() {
    return !this.flagNotWindowSyslog && this.filterEventName
      ? `${Common_Utils.textOverflow(this.filterEventName, 8)}(${this.filterEventKey})`
      : "-";
  }

  @computed
  get logType() {
    return this.flagNotWindowSyslog ? this.priority : this.eventType;
  }

  @computed
  get importantObjectDetailText() {
    return this.importantObjectDetail ? this.importantObjectDetail.map(({ name, value }) => `${name}=${value}`) : [];
  }

  @computed
  get importantObjectText() {
    return (this.importantObject && Common_Utils.textOverflow(this.importantObject, 100)) || "-";
  }

  @computed
  get importantTextForCSV() {
    let returnString = "";
    returnString = this.importantObject ? this.importantObject : "-";
    this.importantObjectDetail &&
      this.importantObjectDetail.forEach(({ name, value }, index) => (returnString += `/${index + 1}. ${name}=${value}`));
    return returnString;
  }

  @observable
  importantObject = "";

  @observable
  viewTemplate: NameValueObject[] = [];

  @observable
  importantObjectDetail: NameValueObject[] = [];

  @observable
  logPriorityId = "";

  @observable
  filterEventKey = "";

  @observable
  filterPriorityName = "";

  @observable
  filterEventName = "";

  @observable
  isActiveAlign: boolean = false;

  @observable
  filterChannelName = "";

  @observable
  formatLogOrigin = "";

  @observable
  typeos = "";

  @observable
  logMessage = "";

  @observable
  programMessageTemplate: NameValueObject[] = [];
}

export default SystemLogModel;
