import languageko from "./language-ko";
import languageen from "./language-en";
import languageja from "./language-ja";

const valueForArray = (arrayText, fnc) => fnc(arrayText);

function getLanguage() {
  const settingLang = window.GlobalPathMap ? window.GlobalPathMap.COOKIELANG : "ko";

  let language;
  switch (settingLang) {
    case "ko":
      language = languageko;
      break;
    case "en":
      language = languageen;
      break;
    case "ja":
      language = languageja;
      break;
    default:
      language = languageko;
      break;
  }
  return language;
}

const languageSet = (key, ...value) => {
  try {
    let language = getLanguage();

    if (language[key]) {
      if (typeof language[key] === "function") {
        return language[key](value[0]);
      } else if (Array.isArray(language[key])) {
        return valueForArray(language[key], value[0]);
      } else if (value !== null && value !== undefined) {
        return value.reduce((acc, cur) => {
          acc = acc.replace("%", cur);
          return acc;
        }, language[key]);
      } else {
        return language[key];
      }
    } else {
      process.env.NODE_ENV !== "production" && console.log(`${key}  를 등록해주삼.`);
      return key;
    }
  } catch (e) {
    console.log("languageSet", e);
    return key;
  }
};

export default languageSet;
