import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "reactstrap";
import WithDropdown from "../../../hoc/WithDropdown";
import { observer } from "mobx-react";

const DropdownWithChilren = ({
  dropdownOpen,
  toggleDropdown,
  children,
  className,
  direction = "down",
  isUp = false,
}) => {
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className={className}
      direction={direction}
      dropup={isUp}
    >
      {children}
    </Dropdown>
  );
};

DropdownWithChilren.propTypes = {
  dropdownOpen: PropTypes.bool,
  toggleDropdown: PropTypes.func,
};

export default WithDropdown(observer(DropdownWithChilren));
