import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const Down = ({ onClick, color, className }) => {
  return (
    <Button size="sm" color={color} onClick={onClick} className={className}>
      <i className="fa fa-caret-down" />
    </Button>
  )
}

Down.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string
}

Down.defaultProps = {
  color: "dark",
  className: ""
}

export default Down
