import React from "react"
import PropTypes from "prop-types"
import Danger from "./Danger"
import { languageSet } from "@qubit-utill/src";

const DeleteType = ({ className, onClick }) => {
  return <Danger title={languageSet("삭제")} className={className} onClick={onClick} />
}

DeleteType.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default DeleteType
