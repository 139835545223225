import { computed, observable } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

interface NameValueObject {
  name: string;
  value: string;
}

class NetworkLogModel extends SetterModel {
  @computed
  get logOriginType() {
    return this.typeos !== "windows" ? "json" : "xml";
  }

  @computed
  get priority() {
    return this.filterPriorityName ? `${Common_Utils.textOverflow(this.filterPriorityName, 8)}(${this.logPriorityId})` : "-";
  }

  @computed
  get isUseAlign() {
    return this.programMessageTemplate !== null;
  }

  @computed
  get msg() {
    return this.logMessage ? `${this.logMessage.slice(0, 100)}${this.logMessage.length > 100 ? " ..." : ""}` : "-";
  }

  @computed
  get serverGroupName() {
    return this.serverGroup ? this.serverGroup : "-";
  }

  @observable
  typeos = "";
  @observable
  filterPriorityName = "";
  @observable
  logPriorityId = "";
  @observable
  programMessageTemplate: NameValueObject[] = [];
  @observable
  viewTemplate: NameValueObject[] = [];
  @observable
  logMessage = "";
  @observable
  serverGroup = "";
}

export default NetworkLogModel;
