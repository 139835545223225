import { observable } from "mobx"
import SetterModel from "../SetterModel"

export default class FilterEntryModel extends SetterModel {
  @observable
  selectEntryImageUri = null
  @observable
  selectEntryEntryValueLink = null
  @observable
  selectEntryEntryValue = ""
  @observable
  selectEntryDisplayName = ""
  @observable
  isDefault = "1"
  @observable
  isSelected = false
}
