import React, { Component } from "react"
import PropTypes from "prop-types"
import { observable } from "mobx"
import { observer } from "mobx-react"

const WithDropdown = WrappedComponent =>
  observer(
    class extends Component {
      @observable
      dropdownOpen = false

      toggleDropdown = e => {
        e && e.preventDefault()
        this.dropdownOpen = !this.dropdownOpen
      }
      onMouseOver = e => {
        e && e.preventDefault()
        this.dropdownOpen = true
      }
      onMouseLeave = e => {
        e && e.preventDefault()
        this.dropdownOpen = false
      }
      render() {
        return (
          <WrappedComponent
            {...this.props}
            dropdownOpen={this.dropdownOpen}
            toggleDropdown={this.toggleDropdown}
            onMouseOver={this.onMouseOver}
            onMouseLeave={this.onMouseLeave}
          />
        )
      }
    }
  )

WithDropdown.propTypes = {
  WrappedComponent: PropTypes.func
}

export default WithDropdown
