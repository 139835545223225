import React, { useMemo } from "react";
import { ButtonGroup } from "reactstrap";
import { CheckBox } from "@qubit-utill/src/components/atoms";

const ListFilterCheckBox = ({ info, selectedValue, selectHandler }) => {
  const trueValue = useMemo(() => info.selectEntryList[0].selectEntryEntryValue, []);
  const falseValue = useMemo(() => info.selectEntryList[1].selectEntryEntryValue, []);
  const isChecked = useMemo(() => selectedValue === trueValue, [selectedValue]);

  const _checkHandler = () => {
    selectHandler(isChecked ? falseValue : trueValue);
  };

  return (
    <ButtonGroup className="dis-inline-b m-r-10 btn-group">
      <span className="dis-inline-b p-t-2">
        <CheckBox color="grey" isChecked={isChecked} onToggle={_checkHandler} />
      </span>
      {info.searchMenuSelectName}
    </ButtonGroup>
  );
};

export default ListFilterCheckBox;
