const { APIURL, PURPLECOWURL, REPOURL, SIEMURL, ADMINURL, COOKIELANG } = window.GlobalPathMap || {
  APIURL: "",
  PURPLECOWURL: "",
  REPOURL: "",
  SIEMURL: "",
  ADMINURL: "",
};

export default {
  PURPLECOWURL,
  CDNURL: PURPLECOWURL + "/web/assets/images",
  APIURL,
  REPOURL,
  SIEMURL,
  ADMINURL,
  QUBITCORPURL: "https://w.qubitsec.com/" + COOKIELANG,
  PLURAIO: "plura.io",
};
