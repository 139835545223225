import { set, observable } from "mobx";

class UserModel {
  constructor(params?: any) {
    set(this, params);
  }

  @observable
  companyName;

  @observable
  email;
}

export default UserModel;
