import { UISET } from "@src/constants";

const routes = [
  {
    ...UISET.INDEX_KO,
  },
  {
    ...UISET.INDEX_JA,
  },
  {
    ...UISET.INDEX_EN,
  },
  {
    ...UISET.LICENSECODE,
  },
  {
    ...UISET.DASHBOARD,
  },
  {
    ...UISET.DOMAIN_REGIST,
  },
  {
    ...UISET.DOMAIN_EDIT,
  },
  {
    ...UISET.DOMAIN_SPEED,
  },
  {
    ...UISET.DOMAIN_SIZE,
  },
  {
    ...UISET.MANAGEMENT,
  },
  {
    ...UISET.USER_INFO,
  },
  {
    ...UISET.USER_EDITPASSWORD,
  },
  {
    ...UISET.USER_EDITPIN,
  },
];

export default routes;
