import languageSet from "@src/utils/lang/languageSet";
import getURI from "@src/constants/uriset";
import { ManagementContainer } from "@src/pages";

export default {
  MANAGEMENT: {
    text: languageSet("관리"),
    link: getURI("MANAGEMENT"),
    title: languageSet("관리"),
    component: ManagementContainer,
  },
};
