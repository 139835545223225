import { action, observable } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

export const ACCOUNT_REGEX_KEY = {
  PASSWORD: "PASSWORD",
  PIN: "PIN",
} as const;
export type ACCOUNT_REGEX_TYPE = typeof ACCOUNT_REGEX_KEY[keyof typeof ACCOUNT_REGEX_KEY];

class UserPasswordRuleModel extends SetterModel {
  constructor(list: any) {
    super();
    if (list) {
      this.passwordRuleList = list;
      this.returnMessage = "";
    }
  }

  @observable
  passwordRuleList = [];

  @observable
  returnMessage: string = "";

  /**
   * Boolean return 없이 message로 판단
   *
   * @param value
   * @param rule
   * @param type
   */
  @action
  validPassword = (value: any, ruleList: Array<any>, type: string) => {
    this.returnMessage = "";

    if (Common_Utils.isEmpty(value)) return;

    for (let data of ruleList) {
      if (data.name !== type) continue;

      const regex = data.value.replace(/\\\\/g, "\\"); //자바 정규식 치환
      if (!new RegExp(regex).test(value)) {
        this.returnMessage = data.message;
        return;
      }
    }
  };
}

export default UserPasswordRuleModel;
