import { ThFieldsType } from "../type/TableConfig";
import { TableFieldModel } from "../model";
import { computed, action, observable } from "mobx";
import { TableFieldModelType, TableFieldModelParamType } from "../model/TableFieldModel";

export enum SORTTYPE {
  UP,
  DOWN,
}

class ThFieldsConfig implements ThFieldsType {
  constructor({
    localStorageName = "",
    list,
  }: {
    localStorageName?: string;
    list?: TableFieldModelParamType[];
  }) {
    this.localStorageName = localStorageName;
    if (list && list.length > 0) {
      this.setList(list);
    }
  }
  localStorageName: string = "";

  @observable
  list: TableFieldModel[] = [];

  //사용중인 필드 리스트
  @computed
  get activeField() {
    return this.list.filter(({ isActive }) => isActive);
  }

  @action
  setOriginField() {
    this.list.forEach(info => {
      info.setIsActive(info.isOriginActive);
    });
  }

  @computed
  get activeFieldObject() {
    return this.activeField.reduce((acc, cur) => {
      acc[cur.columnName] = cur;
      return acc;
    }, {});
  }

  //커스텀 가능 필드 리스트
  @computed
  get customFields() {
    return this.list.filter(({ useCustom }) => useCustom);
  }

  @computed
  get customFieldObject() {
    return this.customFields.reduce((acc, cur) => {
      acc[cur.columnName] = cur;
      return acc;
    }, {});
  }

  @action
  setList = (newList: TableFieldModelParamType[]) => {
    this.list = newList.map(element => new TableFieldModel(element));
  };

  @action
  setLocalFields = (customFieldObject: { [key: string]: TableFieldModelType }) => {
    const newList = [...this.list];
    newList.forEach(({ columnName, setIsActive }) => {
      const localItem = customFieldObject[columnName];
      if (localItem) {
        setIsActive(localItem.isActive);
      }
    });
    this.list = newList;
  };

  @observable
  selectedSort: string = "";

  @action
  setSelectedSort = (sort: string, sortType: SORTTYPE) => {
    this.selectedSort = sort;
    this.sortType = sortType;
  };

  @observable
  sortType: SORTTYPE = SORTTYPE.UP;
}

export default ThFieldsConfig;
