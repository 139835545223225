import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { observer, PropTypes as Mobx_PropTypes, Observer } from "mobx-react";
import { computed } from "mobx";

import SearchFilterName from "./SearchFilterName";
import { DropdownWithChilren } from "../DropDown";
import DropGroup from "./DropGroup";

@observer
class ListFilterDropdown extends Component {
  _renderItems = () => {
    const { info, items, selectHandler, selectedValue } = this.props;
    const { isUseImageFlag } = info;
    return (
      items &&
      items.map(
        (
          {
            selectEntryEntryValue,
            selectEntryDisplayName,
            selectEntryImageUri,
            selectEntryImageClass,
            depth,
            uiId,
          },
          index
        ) => {
          return (
            <DropdownItem
              key={uiId}
              tag="li"
              className="cursor_p"
              onClick={() => selectHandler(selectEntryEntryValue)}
            >
              <a className="p-tb-3">
                {selectedValue === selectEntryEntryValue && (
                  <span className="check_w">
                    <i className="fa fa-check" />
                  </span>
                )}
                <SearchFilterName
                  depth={depth}
                  isUseImageFlag={isUseImageFlag}
                  selectEntryDisplayName={selectEntryDisplayName}
                  selectEntryImageUri={selectEntryImageUri}
                  selectEntryImageClass={selectEntryImageClass}
                />
              </a>
            </DropdownItem>
          );
        }
      )
    );
  };

  @computed
  get displayName() {
    const { items, selectedValue, info } = this.props;
    const selectedEntry =
      items && items.find(entryInfo => entryInfo.selectEntryEntryValue === selectedValue);

    return selectedEntry ? (
      <SearchFilterName {...selectedEntry} isUseImageFlag={info.isUseImageFlag} />
    ) : null;
  }

  render() {
    const { optionClear, info, defaultValue, dropdownClassName = "", arrowClassName } = this.props;
    return (
      <DropGroup dropdownClassName={dropdownClassName}>
        <DropdownWithChilren className="m-r-10">
          <DropdownToggle tag="div">
            <a className="c-dark" style={{ cursor: "pointer" }}>
              <span>{this.displayName || defaultValue.name}</span>
              <i className={`fa fa-angle-down p-l-10 ${arrowClassName}`} />
            </a>
          </DropdownToggle>
          <DropdownMenu tag="ul" className="f-12 drop-menu-lg">
            {info.isUseTitleFlag && (
              <DropdownItem tag="li" className="cursor_p" onClick={optionClear}>
                <a className="p-tb-3">
                  {!this.displayName && (
                    <span className="check_w">
                      <i className="fa fa-check" />
                    </span>
                  )}
                  {defaultValue.name}
                </a>
              </DropdownItem>
            )}
            <Observer render={this._renderItems} />
          </DropdownMenu>
        </DropdownWithChilren>
      </DropGroup>
    );
  }
}

ListFilterDropdown.propTypes = {
  defaultValue: PropTypes.object,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: Mobx_PropTypes.arrayOrObservableArray.isRequired,
  rigthClass: PropTypes.string,
};

ListFilterDropdown.defualtProps = {
  items: [],
  rigthClass: "",
  dropdownClassName: "",
  arrowClassName: "",
};

export default ListFilterDropdown;
