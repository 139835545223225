import { observable, computed } from "mobx";

import { SetterModel } from "@qubit-utill/src";

import { languageSet } from "@src/utils";

const preFixWord = (paramWord) => {
  const word = [
    {
      wordKey: "dosFilterName",
      wordValue: languageSet("필터명"),
    },
  ];
  const findWord = word.find((word) => word["wordKey"] === paramWord);
  return findWord && findWord["wordValue"];
};

class SearchWordModel extends SetterModel {
  constructor(params, flag = false, callbackFn?) {
    super(params);
    this.isFix = flag;
    this.searchOptionName = preFixWord(params.searchOption) || params.searchOptionName || "";

    if (params) {
      if (params.resourceMetaSearchField) {
        const word = params.resourceMetaSearchField[0];
        const indexNumber = word.indexOf("[") + 1;
        const resourceMetaSearchField = word.substring(indexNumber, indexNumber + 1);
        callbackFn && callbackFn(resourceMetaSearchField);
      }
    }
  }

  @computed
  get searchTextParam() {
    const searchKeyword = this.isUseAutoComplete ? this.searchValue : this.searchText;
    return this.assign ? `${this.assign}:${searchKeyword}` : searchKeyword;
  }

  @observable
  isFix = false;

  @observable
  searchOption = "";

  @observable
  searchOptionName = "";

  @observable
  searchText = "";

  @observable
  searchValue = "";

  @observable
  assign = "";

  @observable
  isApply = true;

  @observable
  resourceMetaSearchField = [];

  @observable
  isUseAutoComplete = false;
}
export default SearchWordModel;
