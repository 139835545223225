import React from "react";
import { observer } from "mobx-react";

import { useInjectStore } from "@qubit-utill/src";

const Loading: React.FC = () => {
  const { LoadingStore } = useInjectStore();
  return (
    <div className={"loader-overlay " + (!LoadingStore.isLoading ? "loaded" : "")}>
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};

export default observer(Loading);
