import { Common_Utils } from "."

export default {
  deleteNullPropety(object) {
    const newObject = { ...object }
    for (const key in newObject) {
      if (newObject.hasOwnProperty(key)) {
        const element = newObject[key]
        Common_Utils.isEmpty(element) && delete newObject[key]
      }
    }
    return newObject
  }
}
