import Minus from "./Minus";
import Down from "./Down";
import Up from "./Up";
import Rounded from "./Rounded";
import Add from "./Add";
import Embossed from "./Embossed";
import Scroll from "./Scroll";
import PlusType from "./PlusType";
import DeleteType from "./DeleteType";
import EditType from "./EditType";
import Danger from "./Danger";
import Copy from "./Copy";
import DownLoad from "./DownLoad";
import Help from "./Help";

export default {
  Copy,
  DownLoad,
  Rounded,
  PlusType,
  Minus,
  Down,
  Up,
  Add,
  Embossed,
  Scroll,
  DeleteType,
  EditType,
  Danger,
  Help,
};
