import React from "react";
import { DropdownToggle, DropdownMenu } from "reactstrap";
import { observer, Observer } from "mobx-react";
import { subDays } from "date-fns";

import DropGroup from "./DropGroup";
import DatePicker from "../../atoms/Input/DatePicker";
import { DropdownWithChilren } from "../DropDown";
import { formatConstants } from "../../../common_constants";
import { ListFilterWithDate } from "../ListFilter";
import { languageSet } from "@qubit-utill/src";
import { dateUtil } from "@qubit-utill/src/utils";

@observer
class ListFilterWithDateNotTime extends ListFilterWithDate {
  _setListParams = (startDate?: any, endDate?: any) => {
    const { setListParams, info, setListNewParams, isUsePagination } = this.props;
    const timeFormatStartDate = dateUtil.format(
      new Date(startDate || this.startDate),
      formatConstants.DATEFORMAT
    );
    const timeFormatEndDate = dateUtil.format(
      new Date(endDate || this.endDate),
      formatConstants.DATEFORMAT
    );
    setListParams(info.datepickerMinDateParameter, timeFormatStartDate);
    setListNewParams(info.datepickerMaxDateParameter, timeFormatEndDate, true, isUsePagination);
  };

  render() {
    const { info, dropdownClassName, arrowClassName } = this.props;

    return (
      <DropGroup dropdownClassName={dropdownClassName}>
        <DropdownWithChilren>
          <DropdownToggle tag="div" disabled={info.datepickerMinDate === 0}>
            <a className="c-dark">
              <span>{this.timeText}</span>
              <i className={`fa fa-angle-down p-l-10 ${arrowClassName}`} />
            </a>
          </DropdownToggle>
          <DropdownMenu tag="ul" className="f-12 drop-menu-lg">
            <Observer render={this._renderItemsHandler} />
            <li className="divider" />
            <div className="form-group m-b-0 text-center">
              <label className="form-label">
                <i className="fa fa-angle-left p-r-5" />
                {languageSet("기간설정")}
                <i className="fa fa-angle-right p-l-5" />
              </label>
              <div className="form-group">
                <DatePicker
                  size="sm"
                  value={this.startDate}
                  minDate={subDays(new Date(), info.datepickerMinDate)}
                  maxDate={new Date()}
                  onClick={this.onChangeDate("startDate")}
                />
              </div>

              <div className="form-group">
                <DatePicker
                  size="sm"
                  value={this.endDate}
                  minDate={subDays(new Date(), info.datepickerMinDate)}
                  maxDate={new Date()}
                  onClick={this.onChangeDate("endDate")}
                />
              </div>
              <span className="dis-block t-center m-b-5">
                <a className="cursor_p" onClick={this.onClickApply}>
                  <span className="label bg-black f-11 c-white">{languageSet("적용")}</span>
                </a>
              </span>
            </div>
          </DropdownMenu>
        </DropdownWithChilren>
      </DropGroup>
    );
  }
}

export default ListFilterWithDateNotTime;
