import axiosModules from "@src/utils/config/axiosSetting";

import { COOKIELANG } from "@src/constants";

export default {
  getCodeList: (codeGroup: string) => {
    return axiosModules.get(`/code/bycodegroup?codeGroup=${codeGroup}`, {
      lang: COOKIELANG,
    }) as Promise<any>;
  },

  getCodeListByArr: (codeGroup) => {
    return axiosModules.get("/code/bycodegroups", {
      codeGroup,
      lang: COOKIELANG,
    }) as Promise<any>;
  },

  getTimezones: () => {
    return axiosModules.get("/code/timezones");
  },

  getSearchMenus: (params) => {
    return axiosModules.get("/code/searchmenus", {
      ...params,
      lang: COOKIELANG,
    }) as Promise<any>;
  },

  getLinkUrl: () => axiosModules.get(`/code/linkurl`) as Promise<any>,
};
