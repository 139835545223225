import { computed, observable } from "mobx";
import { SetterModel, Common_Utils, formatUtils } from "@qubit-utill/src";

class WebLogModel extends SetterModel {
  @computed
  get queryPostBodyForPopover() {
    const _data = this.logRequestBody || this.logQuery;
    const data = _data && _data.replace(/\n|%0a|%0d/gi, " ");
    const decodeString = formatUtils.tryDecode(data);
    return Common_Utils.isDataWithDashBar(decodeString);
  }

  @computed
  get queryPostBody() {
    const _data = (this.logRequestBody && this.logRequestBodyText) || this.logQueryText;
    const data = _data && _data.replace(/\n|%0a|%0d/gi, " ");
    const decodeString = formatUtils.tryDecode(data);
    return decodeString;
  }

  @computed
  get paramMapParseArray() {
    if (this.paramMap) {
      const keyList = Object.keys(this.paramMap);
      return keyList.map((key) => ({ name: key, value: this.paramMap[key].toString() }));
    }
    return null;
  }
  @computed
  get attackNation() {
    return (
      (this.remoteIsoCountryCode && this.remoteIsoCountryCode.toLowerCase()) ||
      (this.countryIsoCode && this.countryIsoCode.toLowerCase()) ||
      ""
    );
  }

  @computed
  get attackNationForCSV() {
    return formatUtils.IsNullFormat(!this.attackNation ? null : this.attackNation);
  }

  @computed
  get getLogResponseContentLength() {
    return formatUtils.IsNullFormat(Common_Utils.numberWithComma(this.logResponseContentLength));
  }

  @computed
  get getLogRequestContentLength() {
    return formatUtils.IsNullFormat(Common_Utils.numberWithComma(this.logRequestContentLength));
  }

  @computed
  get getLogConcurrentLogCount() {
    return Common_Utils.getCountNumberFormat(this.logConcurrentLogCount);
  }

  @computed
  get isNewMod() {
    return this.logModPluraVersion !== null;
  }

  @computed
  get isNotNullContentLength() {
    return this.isNewMod && this.logRequestContentLength !== null;
  }

  @computed
  get isNotNullLogResponseContentLength() {
    return this.isNewMod && this.logResponseContentLength !== null;
  }

  @computed
  get logQueryText() {
    return this.logQuery ? Common_Utils.textOverflow(this.logQuery, 100) : "-";
  }

  @computed
  get logRequestBodyText() {
    if (this.logRequestBody) return this.logRequestBody;
    return this.logRequestBody ? `${this.logRequestBody.slice(0, 100)}${this.logRequestBody.length > 100 ? "..." : ""}` : "-";
  }

  // todo : #5432#재전송 공격 테스트 완료시 삭제
  @computed
  get useReplayAttack() {
    if (this.isReplayAttack) {
      return this.logMethod === "GET" || (this.logMethod === "POST" && this.logRequestContentType.indexOf("form") > 0) || false;
    } else {
      return false;
    }
  }

  @computed
  get replayAttackIp() {
    return (this.logHost && this.logHost.split(":")[0]) || this.hostIp;
  }

  @observable
  logQuery: string = "";

  @observable
  logModPluraVersion: string = "";

  @observable
  remoteIsoCountryCode: string = "";

  @observable
  countryIsoCode: string = "";

  @observable
  logResponseContentLength: number = 0;

  @observable
  logRequestContentLength: number = 0;

  @observable
  logConcurrentLogCount: number = 0;

  @observable
  paramMap = {};

  logOriginType: string = "json";

  @observable
  isReplayAttack: boolean = false;

  @observable
  logMethod: string = "";

  @observable
  protocol: string = "https";

  @observable
  port: string = "443";

  @observable
  logRequestContentType: string = "";

  @observable
  logRequestBody: string = "";

  @observable
  hostIp: string = "";

  @observable
  logHost: string = "";
}

export default WebLogModel;
