import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class SelectOptionModel extends SetterModel {
  constructor(param) {
    super(param);
    this.name = param.name;
    this.value = param;
    this.code = param.code;
  }

  @observable
  name: string = "";

  @observable
  code: string = "";

  @observable
  value: any = {};
}

export default SelectOptionModel;
