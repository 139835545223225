import React from "react"
import PropTypes from "prop-types"
import { observer } from "mobx-react"

const PartialLoading = ({ minHeight, height }) => {
  return (
    <div
      className={"loader-overlay"}
      style={{
        position: "relative",
        width: "100%",
        height: height || "100%",
        zIndex: 200,
        background: "#fff",
        minHeight
      }}
    >
      <div className="spinner">
        <div className="bounce1" style={{ backgroundColor: "#000" }} />
        <div className="bounce2" style={{ backgroundColor: "#000" }} />
        <div className="bounce3" style={{ backgroundColor: "#000" }} />
      </div>
    </div>
  )
}

PartialLoading.propTypes = {
  isLoading: PropTypes.bool
}

export default observer(PartialLoading)
