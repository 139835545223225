import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

export default class UserPasswordChangeModel extends SetterModel {
  @computed
  get isUsePasswordChangeFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isNeedPasswordChangeFlag() {
    return Common_Utils.isCheckBool(this.isPasswordChange);
  }

  @observable
  passwordChangeDay: number = 0;

  @observable
  passwordSkipDay: number = 0;

  // null을 기본값으로 선언해서 null이 아닐 때(값이 할당될 때)만 비밀번호 변경 안내 페이지 렌더링 해줌
  // 로그인 후 대시보드로 가지 않고 그 전에 비밀번호 변경 안내 페이지로 가게 하려고
  @observable
  isUse: string | null = null;

  @observable
  isPasswordChange: string = "0";
}
