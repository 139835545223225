import { observable, action } from "mobx";

class LoadingStore {
  // constructor(root: any) {
  //   this.root = root;
  // }

  @observable
  root: any;

  @observable
  isLoading = true;

  @action
  Loading = () => {
    this.isLoading = true;
  };

  @action
  completed = () => {
    this.isLoading = false;
  };
}

export default new LoadingStore();
