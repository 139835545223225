import React from "react";
import { observer } from "mobx-react";

import { useBlogLink } from "@qubit-utill/src";

interface PropTypes {
  url: string;
  useLink?: boolean;
  size?: number;
}

const Help: React.FC<PropTypes> = ({ url, useLink, size = 20 }) => {
  const { _linkToUrl } = useBlogLink();
  return (
    <a className={`${useLink ? "cursor_p" : "cursor_d"}`} onClick={useLink && _linkToUrl(url)}>
      <span className={`fa fa-question-circle f-${size} c-red m-r-0`}></span>
    </a>
  );
};

export default observer(Help);
