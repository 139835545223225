import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import ja from "i18n-iso-countries/langs/ja.json";
import ko from "./konation";

countries.registerLocale(en);
countries.registerLocale(ja);
countries.registerLocale(ko);

export default {
  firstLetterUpper(str: string) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  },
  replaceAll(str: string, searchStr: string, replaceStr: string) {
    return str ? str.split(searchStr).join(replaceStr) : str;
  },
  getNationFullName(nationName: string, lang: string) {
    const result = countries.getName(nationName, lang);
    if (result) {
      return result;
    }
    return nationName;
  },
  // 하나의 텍스트를 구분자로 하여 새로운 형태의 텍스트로 변경하는 함수
  getSeparateText(text: string, delimiter: string, chType: string) {
    // text : 텍스트 전체,
    // delimiter : 구분자
    // chType : 변경하는 타입
    let aheadText = text.slice(0, text.indexOf(delimiter));
    let behindText = text.slice(text.indexOf(delimiter));
    return [aheadText, chType, behindText];
  },
  // 공통된 값을 제외한 나머지 텍스트를 구분자로 하여 하나의 배열로 변경하는 함수
  setSeparateText(textArray: string[], delimiter: string, chType: string) {
    // textArray : 텍스트의 배열
    // delimiter : 구분자
    // chType : 변경하는 타입
    let box: any = [];
    // 최초의 값은 전체를 넣는다.
    textArray.map(element => {
      if (element && element.includes(delimiter)) {
        const data = this.getSeparateText(element, delimiter, chType);
        if (box.length === 0) {
          data.map(_element => box.push(_element));
          // 두번째의 값부터는 공통된 값을 제외한 구분자와 나머지 텍스트를 넣는다.
        } else {
          data.map((_element, index) => index !== 0 && box.push(_element));
        }
      } else {
        box.push(element);
      }
    });
    return box;
  },
  separateText(text: string, delimiter: string) {
    // text : 텍스트 전체,
    // delimiter : 구분자
    let aheadText = text.slice(0, text.indexOf(delimiter));
    let behindText = text.slice(text.indexOf(delimiter));
    return [aheadText, behindText];
  },
};
