import { observable, action, computed } from "mobx";
import { createBrowserHistory } from "history";

import { Common_Utils } from "@qubit-utill/src";
import { LINECOUNT } from "@qubit-utill/src/common_constants";

export default class ListCondition {
  @observable
  isLoading = false;

  @observable
  isSubLoading = false;

  @observable
  totalCount = 0;

  @observable
  offset = 0;

  @observable
  params: { [key: string]: any } = {
    size: String(LINECOUNT),
    page: 1,
    sort: "DESC",
  };

  @action
  setParams = (params: { [key: string]: any }) => {
    this.params = params;
  };

  @action
  getList: Function = () => {};

  @observable
  initParamsList: any[] = ["size"];

  @action
  setSelectList = async (list: Array<object>) => {
    list.map((info: any) => {
      if (info.componentType === "combineDatepicker") {
        this.initParamsList.push(info.datepickerMaxDateParameter);
        this.initParamsList.push(info.datepickerMinDateParameter);
      } else {
        this.initParamsList.push(info.searchMenuSelectCode);
      }
      return this.initParamsList;
    });
  };

  @action
  clearParams = () => {
    this.offset = 0;
    this.params = {
      size: String(LINECOUNT),
      page: 1,
      sort: "DESC",
    };
  };

  @action
  setListParams = (elementName: string, value: any, isGetData?: boolean) => {
    this.params = { ...this.params, [elementName]: value };
    isGetData && this.getList();
  };

  @action
  setListNewParams = (elementName: string, value: any, isGetData: boolean, usePagination: boolean = true) => {
    this.params[elementName] = value;
    this.params.page = 1;
    const customHistory = createBrowserHistory();
    usePagination && customHistory.push(`${Common_Utils.getCurrentPathUrl()}/page/1`);
    isGetData && this.getList();
  };

  @action
  setListParamsWithGetData = (elementName: string, value: any) => {
    this.params[elementName] = value;
    this.getList();
  };

  @action
  deleteListParams = (key: string) => {
    delete this.params[key];
  };

  @action
  deleteListParamsWithGetData = (key: string) => {
    delete this.params[key];
    this.getList();
  };

  @action
  changePage = (page: number) => {
    this.params.page = page || 1;
    this.offset = Common_Utils.getOffset(this.params.page, this.params.size);
    this.getList();
  };

  @action
  setIsLoading = (flag: boolean) => {
    this.isLoading = flag;
  };

  @computed
  get currentPage() {
    return Common_Utils.getPage(this.params.page, this.params.size);
  }

  @action
  setInitPage = () => {
    this.isClickButtonFlag = false;
    this.getList();
  };

  @action
  setPrevPage = async () => {
    this.getList();
  };

  @action
  setNextPage = () => {
    this.isClickButtonFlag = true;
    this.getList();
  };

  @observable
  isClickButtonFlag = false;
}
