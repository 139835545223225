import React from "react";

import { TooltipWrapper, Common_Utils } from "@qubit-utill/src";

import { languageSet } from "@src/utils";
import { getURI, COOKIELANG } from "@src/constants";
import { BLOGLINK } from "@src/constants/code";

const BlogButton: React.FC<{ code?: string }> = ({ code = "" }) => {
  //link db관리 안하고 수동으로
  const linkUrlList: Array<{ linkUrlCode: string; linkUrl: string }> = [
    { linkUrlCode: getURI("DASHBOARD"), linkUrl: "/fn/comm/dashboard" },
    { linkUrlCode: getURI("DOMAIN_REGIST"), linkUrl: "/fn/comm/domain" },
    { linkUrlCode: getURI("DOMAIN_EDIT"), linkUrl: "/fn/comm/domain" },
    { linkUrlCode: getURI("DOMAIN_SPEED"), linkUrl: "/fn/comm/dashboard" },
    { linkUrlCode: getURI("DOMAIN_SIZE"), linkUrl: "/fn/comm/dashboard" },
    { linkUrlCode: getURI("USER_INFO"), linkUrl: "/fn/comm/my/info" },
    { linkUrlCode: getURI("USER_EDITPASSWORD"), linkUrl: "/fn/comm/my/pw" },
    { linkUrlCode: getURI("USER_EDITPIN"), linkUrl: "/fn/comm/my/pin" },
    { linkUrlCode: getURI("MANAGEMENT"), linkUrl: "/fn/comm/mgmt" },
    { linkUrlCode: getURI("LICENSECODE"), linkUrl: "/fn/comm/mgmt" },
  ];

  const _linkToUrl = () => {
    const uriCode = Common_Utils.getPathName(code || Common_Utils.getCurrentPathName());

    return linkUrlList.map((data) => {
      if (Common_Utils.getPathName(data.linkUrlCode) === uriCode) {
        const url = BLOGLINK + COOKIELANG + data.linkUrl;
        window.open(url);
      }
    });
  };

  return (
    <TooltipWrapper tooltipText={languageSet("도움말")}>
      {(targetRef: any) => (
        <span ref={targetRef} className="f-right cursor_p">
          <a onClick={_linkToUrl}>
            <span className="fa fa-question-circle f-20 c-red m-r-0"></span>
          </a>
        </span>
      )}
    </TooltipWrapper>
  );
};

export default BlogButton;
