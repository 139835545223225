import axiosModules from "@src/utils/config/axiosSetting";

export default {
  // DASHBOARD > 도메인 목록 조회
  getDashboardList: (param?) => axiosModules.get("/dashboard/domain/list", param) as Promise<any>,

  // DASHBOARD > 도메인 Summary 조회
  getDashboardSummaryList: () => axiosModules.get("/dashboard/domain/summary") as Promise<any>,

  // DASHBOARD > 인증서 만료일 갱신
  getSslExpiryInfo: (param) => axiosModules.get("/dashboard/ssl/renewal", param) as Promise<any>,

  // DASHBOARD > DNS 만료일 갱신
  getDnsExpiryInfo: (param) => axiosModules.get("/dashboard/dns/renewal", param) as Promise<any>,

  // DASHBOARD > SSL 인증서 조회
  getSslInfo: (param) => axiosModules.get("/dashboard/ssl/info", param) as Promise<any>,

  // DASHBOARD > DNS 인증서 조회
  getDnsInfo: (param) => axiosModules.get("/dashboard/dns/info", param) as Promise<any>,

  // DASHBOARD > Speed 그래프 조회
  getSpeedGraphList: (param?) => axiosModules.get("/dashboard/speed-graph", param) as Promise<any>,

  // DASHBOARD > Size 그래프 조회
  getSizeGraphList: (param?) => axiosModules.get("/dashboard/size-graph", param) as Promise<any>,

  // DASHBOARD > DNS spoofing 조회
  getSpoofingList: (param) => axiosModules.get("/dashboard/spoofing", param) as Promise<any>,

  // DASHBOARD > ping 조회
  getPingInfo: (param) => axiosModules.get("/dashboard/ping", param) as Promise<any>,

  // DASHBOARD > Title 조회
  getForgeryTitleInfo: (param) => axiosModules.get("/dashboard/title", param) as Promise<any>,

  // DASHBOARD > Meta 조회
  getForgeryMetaInfo: (param) => axiosModules.get("/dashboard/meta", param) as Promise<any>,

  // DASHBOARD > 취약점 점검 항목 리스트 조회
  getVwcList: () => axiosModules.get("/dashboard/vwc/list") as Promise<any>,

  // DASHBOARD > 취약점 점검 정보 api ( 보안 헤더 점검 ~ 정보 유출 점검 : 공통으로 사용 )
  getVwcInfo: (param, url) => axiosModules.get(`/dashboard${url}`, param) as Promise<any>,
};
