const getURI = function(key) {
  const links = {
    INDEX: "/",
    INDEX_KO: "/ko",
    INDEX_EN: "/en",
    INDEX_JA: "/ja",
    LOGIN: "/login",
    SIGNUP: "/signup",
    SIGNUPCERT: "/signupcert",
    LICENSECODE: "/code",
    FINDPASSWORDPIN: "/findpw",
    CHANGEPASSWORD: "/changepw",
    CHANGEPIN: "/changepin",
    DASHBOARD: "/dashboard",
    DOMAIN_REGIST: "/domain/new",
    DOMAIN_EDIT: "/domain/edit",
    DOMAIN_SPEED: "/domain/speed",
    DOMAIN_SIZE: "/domain/size",
    USER_INFO: "/user/info",
    USER_EDITPASSWORD: "/user/editpw",
    USER_EDITPIN: "/user/editpin",
    MANAGEMENT: "/mgmt",
    TERMS: "/terms",
    TERMS_PERSONAL: "/terms/privacy",
    TERMS_USE: "/terms/ofservice",
  };

  if (links[key]) {
    return links[key];
  } else {
    return key;
  }
};

export default getURI;
