import React from "react";
import { Tooltip } from "reactstrap";
import { observer } from "mobx-react";
import { useIsToggle } from "@qubit-utill/src";
import "../../atoms/TableInfos/NationAndIp.css";

interface PropsTypes {
  children: (targetRef: any) => void;
  tooltipText: string;
  isUse?: boolean;
  placement?: "top" | "bottom" | "right" | "left" | "auto";
}
const TooltipWrapper: React.FC<PropsTypes> = ({
  tooltipText,
  children,
  isUse = true,
  placement = "top",
}) => {
  const { isToggle, setIsToggle } = useIsToggle();
  const [ready, setReady] = React.useState(false);
  const targetRef = React.useRef(null);
  React.useEffect(() => {
    if (targetRef.current) {
      setReady(isUse);
    }
  }, [targetRef.current]);

  return (
    <>
      {children(targetRef)}
      {ready && (
        <Tooltip
          hideArrow
          delay={{ show: 400, hide: 0 }}
          placement={placement}
          isOpen={isToggle}
          target={targetRef.current || ""}
          toggle={setIsToggle}
        >
          <span className="bold f-11"> {tooltipText}</span>
        </Tooltip>
      )}
    </>
  );
};

export default observer(TooltipWrapper);
