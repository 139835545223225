import React, { Component } from "react";
import PropTypes from "prop-types";
import { Observer, inject, observer } from "mobx-react";
import { ButtonGroup } from "reactstrap";

import {
  ListFilterMultiDropdown,
  ListFilterDropdown,
  ListFilterWithDate,
  ListFilterCheckBox,
  ListFilterWithDateNotTime,
} from "../ListFilter";
import { Common_Utils } from "../../../utils";
import { CheckBox } from "@qubit-utill/src/components/atoms";
import { languageSet } from "@qubit-utill/src";
import { ModalStore } from "@qubit-utill/src/store";

@inject("CodeStore")
@inject(({ SearchListFilterStore }) => ({ selectList: SearchListFilterStore.selectList }))
@observer
class SearchListFilter extends Component {
  _selectHandler = info => value => {
    const { store, isUsePagination } = this.props;

    const SEARCHKEY = Common_Utils.getCurrentPathName();

    //검색조건 라인수(limit) 일때만 로컬 스토리지에 저장한다.
    if ("limit" === info.searchMenuSelectCode) {
      localStorage.setItem(`${SEARCHKEY}limit`, value);
    }
    store.setListNewParams(info.searchMenuSelectCode, value, true, isUsePagination);
  };

  _selectMultiHandler = info => () => {
    const { store, selectList, isUsePagination } = this.props;
    const selectedList = info.selectEntryList.filter(({ isSelected }) => isSelected);

    const { CodeStore } = this.props;
    const searchLimitCount = CodeStore.getDefaultCode("searchLimitCount");

    if (info.searchMenuSelectCodeLink) {
      let otherSearchList = [];
      const otherSearchMenu = selectList.find(
        ({ searchMenuSelectCode }) => info.searchMenuSelectCodeLink === searchMenuSelectCode
      );

      if (otherSearchMenu) {
        otherSearchMenu.selectEntryList.forEach(selectEntry => {
          const isMatch = selectedList.find(
            element => element.selectEntryEntryValueLink === selectEntry.selectEntryEntryValueLink
          );
          selectEntry.setInfo("isSelected", Boolean(isMatch));
          isMatch && otherSearchList.push(selectEntry);
        });

        if (selectedList.length > searchLimitCount) {
          return ModalStore.openAlertModal({
            message: languageSet("최대_n개_검색가능", searchLimitCount),
          });
        } else {
          store.setListParams(
            otherSearchMenu.searchMenuSelectCode,
            otherSearchList.length > 0
              ? otherSearchList.map(({ selectEntryEntryValue }) => selectEntryEntryValue)
              : []
          );
        }
      }
    }

    if (selectedList.length > searchLimitCount) {
      return ModalStore.openAlertModal({
        message: languageSet("최대_n개_검색가능", searchLimitCount),
      });
    } else {
      store.setListNewParams(
        info.searchMenuSelectCode,
        selectedList.map(({ selectEntryEntryValue }) => selectEntryEntryValue),
        true,
        isUsePagination
      );
    }
  };

  defaultClear = info => () => {
    const { store, isUsePagination } = this.props;
    store.setListNewParams(info.searchMenuSelectCode, null, true, isUsePagination);
  };

  _renderSelectList = () => {
    const { selectList, isUsePagination, dropdownClassName, arrowClassName } = this.props;
    const { setListParams, params, setListNewParams } = this.props.store;
    if (selectList) {
      return selectList.map(info => {
        if (info.componentType === "combineDatepicker") {
          return (
            <ListFilterWithDate
              key={info.uiId}
              info={info}
              dropdownClassName={dropdownClassName}
              arrowClassName={arrowClassName}
              listParams={params}
              setListParams={setListParams}
              setListNewParams={setListNewParams}
              isUsePagination={isUsePagination}
            />
          );
        } else if (info.componentType === "noTimeDatepicker") {
          return (
            <ListFilterWithDateNotTime
              key={info.uiId}
              info={info}
              dropdownClassName={dropdownClassName}
              arrowClassName={arrowClassName}
              listParams={params}
              setListParams={setListParams}
              setListNewParams={setListNewParams}
              isUsePagination={isUsePagination}
            />
          );
        } else if (info.componentType === "checkbox") {
          return (
            <ListFilterCheckBox
              key={info.uiId}
              info={info}
              selectedValue={params[info.searchMenuSelectCode]}
              selectHandler={this._selectHandler(info)}
            />
          );
        } else if (info.isSingleSelect === "1") {
          return (
            <ListFilterDropdown
              key={info.uiId}
              info={info}
              defaultValue={{
                name: info.searchMenuSelectName,
                value: info.searchMenuSelectCode,
              }}
              dropdownClassName={dropdownClassName}
              arrowClassName={arrowClassName}
              optionClear={this.defaultClear(info)}
              items={info.selectEntryList}
              selectHandler={this._selectHandler(info)}
              selectedValue={params[info.searchMenuSelectCode]}
            />
          );
        } else {
          return (
            <ListFilterMultiDropdown
              key={info.uiId}
              info={info}
              defaultValue={{
                name: info.searchMenuSelectName,
                value: info.searchMenuSelectCode,
              }}
              dropdownClassName={dropdownClassName}
              arrowClassName={arrowClassName}
              optionClear={this.defaultClear(info)}
              items={info.selectEntryList}
              selectHandler={this._selectMultiHandler(info)}
              selectedValue={params[info.searchMenuSelectCode]}
            />
          );
        }
      });
    }
    return null;
  };

  render() {
    return (
      <>
        <Observer render={this._renderSelectList} />
        <div className="dis-inline-b f-16 btn-group">
          <a
            onClick={this.props.store.getList}
            className={`cursor_p ${this.props.reloadClassName}`}
          >
            <i className="icon-reload"></i>
          </a>
        </div>
      </>
    );
  }
}

SearchListFilter.propTypes = {
  setListParams: PropTypes.func,
  listParams: PropTypes.object,
  getList: PropTypes.func,
  reloadClassName: PropTypes.string,
};

SearchListFilter.defaultProps = {
  reloadClassName: "c-dark",
};

export default SearchListFilter;
