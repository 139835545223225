import { observable, action } from "mobx"

class PopoverStore {
  @observable
  isFade = false

  @action
  fadeIn = () => {
    this.isFade = true
    setTimeout(this.fadeOut, 2000)
  }

  @action
  fadeOut = () => {
    this.isFade = false
  }
}
export default new PopoverStore()
