import { observable, action } from "mobx";

import { UserException } from "@qubit-utill/src";
import { withLoading } from "@qubit-utill/src/decorator";

import { accountApis, companyApis } from "@src/apis";
import UserModel from "@src/pages/UserInfo/models/UserModel";
import { ACCOUNT_REGEX_KEY, ACCOUNT_REGEX_TYPE } from "@src/utils/model/UserPasswordRuleModel";

class UserInfoStore {
  constructor(root: any) {
    this.root = root;
  }
  root: any;

  @observable
  isLoading: boolean = false;

  @observable
  info: UserModel = new UserModel();

  @withLoading("isLoading")
  @action
  getUserInfo = async () => {
    try {
      const { info: userInfo } = await accountApis.getAccountInfo();
      this.info = new UserModel(userInfo);
    } catch (e) {
      this.info = new UserModel();
    }
  };

  @action
  updateCompanyName = async (companyName: string) => {
    try {
      await companyApis.updateCompanyName({ companyName });
      this.info.companyName = companyName;
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };

  @action
  updateUserInfo = async (params: { type: ACCOUNT_REGEX_TYPE; current: string; value: string }) => {
    try {
      let data: any = params.type === ACCOUNT_REGEX_KEY.PASSWORD ? { password: params.value } : { pin: params.value };
      data = { current: params.current, ...data };
      await accountApis.updateAccount(data);
    } catch (error) {
      throw new UserException(error.data.message);
    }
  };
}

export default UserInfoStore;
