export { default as path } from "./path";
export { default as getURI } from "./uriset";
export { default as formatConstants } from "./formatConstants";

const {
  DEFAULTLANG,
  COOKIENAME,
  COOKIELANG,
  ONPREMISE,
  ISUSECSRF,
  CSRFALLOWSERVERNAMES,
  CSRFALLOWMETHODS,
  APICOOKIENAME,
  PLURAIO,
  IS_ACTIVELIVE,
} =
  window.GlobalPathMap ||
  ({
    APIURL: "",
    PURPLECOWURL: "",
    REPOURL: "",
    SIEMURL: "",
    ADMINURL: "",
    COOKIENAME: "",
    DEFAULTLANG: "",
    COOKIELANG: "",
    ONPREMISE: "",
    ISUSECSRF: "false",
    CSRFALLOWSERVERNAMES: "pauth",
    CSRFALLOWMETHODS: "POST,PUT,PATCH,DELETE",
    PLURAIO: "",
    IS_ACTIVELIVE: false,
  } as Window["GlobalPathMap"]);
const LINECOUNT = 20;
const LANGCOOKIENAME = "LANG";

export {
  LINECOUNT,
  DEFAULTLANG,
  LANGCOOKIENAME,
  COOKIENAME,
  COOKIELANG,
  ONPREMISE,
  ISUSECSRF,
  CSRFALLOWSERVERNAMES,
  CSRFALLOWMETHODS,
  APICOOKIENAME,
  PLURAIO,
  IS_ACTIVELIVE,
};
