import languageSet from "@src/utils/lang/languageSet";
import getURI from "@src/constants/uriset";
import { UserInfoContainer, UserInfoEditContainer } from "@src/pages";

export default {
  USER_INFO: {
    text: languageSet("나의_정보"),
    link: getURI("USER_INFO"),
    title: languageSet("나의_정보"),
    component: UserInfoContainer,
  },
  USER_EDITPASSWORD: {
    text: languageSet("비밀번호_변경"),
    link: getURI("USER_EDITPASSWORD"),
    title: languageSet("비밀번호_변경"),
    isPw: true,
    component: UserInfoEditContainer,
  },
  USER_EDITPIN: {
    text: languageSet("PIN번호_변경"),
    link: getURI("USER_EDITPIN"),
    title: languageSet("PIN번호_변경"),
    isPw: false,
    component: UserInfoEditContainer,
  },
};
