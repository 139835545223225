import React, { Component, Fragment } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider, observer } from "mobx-react";

import { customHistory, PopoverStore, CustomPopover, ModalContainer, Loading, asyncComponent } from "@qubit-utill/src";

import stores, { RootStore } from "@src/stores";
import { getURI, COOKIELANG } from "@src/constants";
import indexlang from "@src/constants/uiset/indexlang";
import routes from "@src/routes";
import { DefaultLayout } from "@src/utils";
import {
  TopBar,
  LoginContainer,
  SignUpContainer,
  SignUpCertContainer,
  FindPwPinContainer,
  FindChangePwContainer,
  FindChangePinContainer,
  LicenseCodeContainer,
} from "@src/pages";

const TermsContainer = asyncComponent(() => import("@src/pages/Terms/TermsContainer"));

@observer
class App extends Component {
  constructor(props) {
    super(props);

    RootStore.CookieStore.checkIsLogin();
    RootStore.CookieStore.checkLang();
    RootStore.LoadingStore.Loading();
  }

  async componentDidMount() {
    try {
      await RootStore.CookieStore.getLanguageList();

      if (RootStore.CookieStore.isLogin) {
        await RootStore.getInitUserInfo();
      }
    } catch (error) {
    } finally {
      RootStore.LoadingStore.completed();
    }
  }

  render() {
    //라이선스 정보
    const { info: licenseInfo } = RootStore.LicenseCodeStore;
    const expired = licenseInfo.expired;

    const _renderIndexContainer = (lang) => {
      switch (lang) {
        case "en":
          return getURI("INDEX_EN");
        case "ja":
          return getURI("INDEX_JA");
        default:
          return getURI("INDEX_KO");
      }
    };

    return (
      <Provider {...stores} {...RootStore} RootStore={RootStore}>
        <Fragment>
          <Router history={customHistory}>
            <Switch>
              <Route path={getURI("TERMS")} render={() => <TermsContainer />} />
              <Route
                exact
                path={getURI("/")}
                render={() =>
                  !RootStore.CookieStore.isLogin ? (
                    <Redirect to={_renderIndexContainer(COOKIELANG)} />
                  ) : (
                    <Redirect to={getURI("DASHBOARD")} />
                  )
                }
              />
              {/** indexes */}
              {Object.keys(indexlang).map((key, index) => {
                const path = indexlang[key].link;
                const Component = indexlang[key].component;

                return (
                  <Route
                    key={index}
                    exact
                    path={path}
                    render={() => (!RootStore.CookieStore.isLogin ? <Component /> : <Redirect to={getURI("DASHBOARD")} />)}
                  />
                );
              })}

              <Route
                path={getURI("LOGIN")}
                render={() => (!RootStore.CookieStore.isLogin ? <LoginContainer /> : <Redirect to={getURI("DASHBOARD")} />)}
              />

              <Route
                path={getURI("SIGNUP")}
                render={() => (!RootStore.CookieStore.isLogin ? <SignUpContainer /> : <Redirect to={getURI("DASHBOARD")} />)}
              />

              <Route
                path={getURI("SIGNUPCERT")}
                render={() => (!RootStore.CookieStore.isLogin ? <SignUpCertContainer /> : <Redirect to={getURI("DASHBOARD")} />)}
              />

              <Route
                path={getURI("FINDPASSWORDPIN")}
                render={() => (!RootStore.CookieStore.isLogin ? <FindPwPinContainer /> : <Redirect to={getURI("DASHBOARD")} />)}
              />

              <Route
                path={getURI("CHANGEPASSWORD")}
                render={() =>
                  !RootStore.CookieStore.isLogin ? <FindChangePwContainer /> : <Redirect to={getURI("DASHBOARD")} />
                }
              />

              <Route
                path={getURI("CHANGEPIN")}
                render={() =>
                  !RootStore.CookieStore.isLogin ? <FindChangePinContainer /> : <Redirect to={getURI("DASHBOARD")} />
                }
              />

              <Route
                path={getURI("LICENSECODE")}
                render={() =>
                  !RootStore.CookieStore.isLogin ? (
                    <Redirect to={getURI("LOGIN")} />
                  ) : expired ? (
                    <LicenseCodeContainer />
                  ) : (
                    <Redirect to={getURI("DASHBOARD")} />
                  )
                }
              />

              <Route
                path={getURI("INDEX")}
                render={() =>
                  !RootStore.CookieStore.isLogin ? (
                    <Redirect to={getURI("LOGIN")} />
                  ) : expired ? (
                    <Redirect to={getURI("LICENSECODE")} />
                  ) : (
                    <DefaultLayout routes={routes} TopBar={TopBar} defaultLink={getURI("DASHBOARD")} />
                  )
                }
              />
            </Switch>
          </Router>
          <Loading />
          <ModalContainer />
          {PopoverStore.isFade && <CustomPopover />}
        </Fragment>
      </Provider>
    );
  }
}

export default App;
