import React from "react";
import useInjectStore from "./useInjectStore";
import { getURI } from "@src/constants";
import customHistory from "../../History";

function useLicenceCheck(licenseCode?: string) {
  const { RoleLicenseStore } = useInjectStore();

  React.useEffect(() => {
    RoleLicenseStore.checkChangeUserLicense();
  }, []);

  const checkHasLicense = React.useMemo(() => {
    if (licenseCode) {
      if (Array.isArray(licenseCode)) {
        return licenseCode.some(code => RoleLicenseStore.licenseCodeList[code]);
      }
      return RoleLicenseStore.licenseCodeList[licenseCode];
    }
    return false;
  }, [RoleLicenseStore, RoleLicenseStore.licenseCodeList]);

  const isRender = React.useMemo(() => RoleLicenseStore.isCallApiDone && checkHasLicense, [
    checkHasLicense,
    RoleLicenseStore.isCallApiDone,
  ]);

  const isRedirect = React.useMemo(() => {
    if (checkHasLicense && checkHasLicense.licenseActivityOption) {
      return checkHasLicense.licenseActivityOption === "redirectPaymentInfo";
    }
    return false;
  }, [checkHasLicense]);

  const goToFREETOPREMIUM = () => {
    customHistory.push(getURI("ERROR_FREETOPREMIUM"));
  };
  return { isRender, isRedirect, goToFREETOPREMIUM };
}

export default useLicenceCheck;
