import React, { Component, Fragment } from "react"
import { observable, action } from "mobx"
import { observer } from "mobx-react"
import PropTypes from "prop-types"

@observer
class Time extends Component {
  static propTypes = {
    value: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func
  }

  static defaultProps = {
    value: "",
    size: "sm",
    color: "white",
    onKeyDown: () => {}
  }

  @observable
  text = ""

  @action
  setText = e => {
    const { onChange } = this.props
    this.text = e.target.value
    onChange && onChange(e.target.value)
  }

  componentDidMount() {
    this.text = this.props.value
  }

  render() {
    const { placeholder, className, size, readOnly, onKeyDown, noSize } = this.props
    return (
      <Fragment>
        <input
          className={`form-control ${!noSize ? `input-${size}` : ""} ${className}`}
          type="time"
          value={this.text}
          onChange={this.setText}
          placeholder={placeholder}
          readOnly={readOnly}
          onKeyDown={onKeyDown}
          step={10}
        />
        <i className="icon-clock" />
      </Fragment>
    )
  }
}

export default Time
