import { observable, action } from "mobx";

export default class GeoIpModel {
  @observable
  searchCountryText: string = "";

  @action
  setInfo(elementName: string, value: any) {
    this[elementName] = value;
  }
}
