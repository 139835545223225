export default {
  RISKCODECOLOR: {
    "1": "gray",
    "2": "yellow",
    "3": "orange",
    "4": "red",
  },
  PERCENTRISKCOLOR: {
    "0": "dark",
    "1": "gray",
    "2": "blue",
    "3": "yellow",
    "4": "orange",
    "5": "red",
    "6": "red",
  },
  PERCENTRISKCODECOLOR: {
    "10": "dark",
    "20": "gray",
    "30": "blue",
    "40": "yellow",
    "50": "orange",
    "60": "red",
  },
  CHART_RISKCODECOLOR: {
    "1": "#82848A",
    "2": "#ffdd93",
    "3": "#f9a10c",
    "4": "#dc3545",
  },
  CHART_PERCENTRISKCOLOR: {
    "0": "#2B2E33",
    "1": "#82848A",
    "2": "#4584D1",
    "3": "#ffdd93",
    "4": "#f9a10c",
    "5": "#dc3545",
  },
  CHART_PERCENTRISKCODECOLOR: {
    "10": "#2B2E33",
    "20": "#82848A",
    "30": "#4584D1",
    "40": "#ffdd93",
    "50": "#f9a10c",
    "60": "#dc3545",
  },
  POPOVER_MODIFIERS: {
    preventOverflow: {
      enabled: false,
    },
    hide: {
      enabled: false,
    },
  },
  CHART_WEBSTATUS_COLOR: {
    "100": "#7cb5ec",
    "200": "#5ED488",
    "300": "#3391D9",
    "400": "#DC3545",
    "500": "#F9A10C",
  },
  SUCCESS_FAIL_COLOR: {
    SUCCESS: "#5ED488",
    FAIL: "#F9A10C",
  },
  CHART_COLOR: [
    "#7CB5EC",
    "#434348",
    "#90ED7D",
    "#F7A35C",
    "#8085E9",
    "#F15C80",
    "#E4D354",
    "#2B908F",
    "#33B2DF",
    "#546E7A",
    "#D4526E",
    "#13D8AA",
    "#FEB019",
    "#775DD0",
    "#4CAF50",
    "#FF4560",
    "#A5978B",
    "#F9CE1D",
    "#FF9800",
    "#3F51B5",
  ],
};
