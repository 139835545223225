import useInjectStore from "./useInjectStore";
import { Common_Utils } from "@qubit-utill/src";

// mobx 상태 가져오는 hook 공통으로 사용함
function useBlogLink() {
  const { CodeStore } = useInjectStore();
  const linkUrlList = CodeStore.linkUrlList;

  const linkToUrl = (_code?: string) => {
    const uriCode = typeof _code === "string" ? _code : Common_Utils.getCurrentPathName();
    if (linkUrlList[uriCode]) {
      if (linkUrlList[uriCode].isBlank === "1") {
        window.open(linkUrlList[uriCode].linkUrl);
      } else {
        window.location.href = linkUrlList[uriCode].linkUrl;
      }
    }
  };

  const _linkToUrl = (code: string) => () => linkToUrl(code);

  return { linkToUrl, _linkToUrl };
}

export default useBlogLink;
