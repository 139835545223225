import { action, set, observable } from "mobx";
import uuid from "uuid/v1";

import { Common_Utils } from "@qubit-utill/src";

import { dateFormatUtils } from "@src/utils";

class SetterModel {
  constructor(params?: any) {
    set(this, params);
    if (params) {
      this.createDate = dateFormatUtils.defaultDateTimeToLocalTime(params.createDate);
      this.updateDate = dateFormatUtils.defaultDateTimeToLocalTime(params.updateDate);
    }
    this.uiId = uuid();
  }

  @observable
  createDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  uiId = "";

  @action
  validText = (elementName: string) => {
    return this[elementName] === "";
  };

  @action
  setInfo(elementName: string, value: any) {
    this[elementName] = value;
  }

  @action
  _setInfo = (elementName: string) => (value: any) => {
    this.setInfo(elementName, value);
  };

  @action
  toggleIsFlag = (elementName: string) => {
    this[elementName] = this[elementName] === "1" ? "0" : "1";
  };

  @action
  _toggleIsFlag = (elementName: string) => () => {
    this[elementName] = this[elementName] === "1" ? "0" : "1";
  };

  @action
  toggleFlag = (elementName: string) => {
    this[elementName] = !this[elementName];
  };

  @action
  _toggleFlag = (elementName: string) => () => {
    this.toggleFlag(elementName);
  };

  @action
  updateInfo = (model: any) => {
    set(this, model);
  };

  @action
  setInfoOnlyNumber = (elementName: string, value: any) => {
    if (Common_Utils.onlyNumber(value)) {
      this.setInfo(elementName, value);
    }
  };

  @action
  _setInfoOnlyNumber = (elementName: string) => (value: any) => {
    if (Common_Utils.onlyNumber(value)) {
      this._setInfo(elementName)(value);
    }
  };

  @action
  _setInfoWithValue = (elementName: string, value: any) => () => {
    this._setInfo(elementName)(value);
  };

  @action
  _setInfoOnlyText = (elementName: string, value: any) => {
    this._setInfo(elementName)(Common_Utils.setOnlyText(value));
  };

  @action
  _setInfoAtOnce = (params: Array<[string, any]>) => {
    params?.length > 0 && params.forEach((info) => this._setInfo(info[0])(info[1]));
  };
}

export default SetterModel;
