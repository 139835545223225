import makeDecorator from "./makeDecorator";

const defineLoading = function(target, loadingProp, method) {
  return async (...args) => {
    try {
      target[loadingProp] = false;
      await method.apply(target, args);
    } finally {
      target[loadingProp] = true;
    }
  };
};

export default makeDecorator(defineLoading);
