import { observable, action } from "mobx";

export interface TableFieldModelParamType {
  displayName: string;
  columnName?: string;
  useCustom?: boolean;
  useSort?: boolean;
  isActive?: boolean;
  colspan?: number | null;
  rowSpan?: number | null;
  licenseCode?: string;
  className?: string;
  textAlign?: string;
  isOriginActive?: boolean;
  colorTheme?: string;
  useSortClassName?: string;
  useSortBrClassName?: string;
  customTheme?: string;
}

export interface TableFieldModelType {
  displayName: string;
  columnName: string;
  useCustom?: boolean;
  useSort?: boolean;
  isActive: boolean;
  colspan?: number | null;
  rowSpan?: number | null;
  licenseCode?: string;
  className?: string;
  textAlign?: string;
  isOriginActive?: boolean;
  useSortClassName?: string;
}

class TableFieldModel implements TableFieldModelType {
  constructor({
    displayName,
    columnName = "",
    useCustom = false,
    useSort = false,
    colspan = null,
    rowSpan = null,
    isActive = true,
    className = "",
    textAlign = "",
    licenseCode,
    isOriginActive = true,
    useSortClassName = "",
    useSortBrClassName = "",
    customTheme = "",
  }: TableFieldModelParamType) {
    this.displayName = displayName;
    this.useCustom = useCustom;
    this.useSort = useSort;
    this.colspan = colspan;
    this.rowSpan = rowSpan;
    this.columnName = columnName;
    this.isActive = isActive;
    this.licenseCode = licenseCode;
    this.className = className;
    this.textAlign = textAlign;
    this.isOriginActive = isOriginActive;
    this.useSortClassName = useSortClassName;
    this.useSortBrClassName = useSortBrClassName;
    this.customTheme = customTheme;
  }
  @observable
  displayName: string;
  @observable
  columnName: string;
  @observable
  useCustom?: boolean = false;
  @observable
  useSort?: boolean = false;
  @observable
  isActive: boolean = true;
  @observable
  colspan?: number | null = null;
  @observable
  rowSpan?: number | null = null;
  @observable
  licenseCode?: string = "";
  @observable
  className: string = "";
  @observable
  textAlign: string = "";
  @observable
  useSortClassName: string = "";
  @observable
  useSortBrClassName: string = "";

  isOriginActive: boolean = true;

  @observable
  customTheme: string = "";

  @action
  toggleIsActive = () => {
    this.isActive = !this.isActive;
  };

  @action
  setIsActive = (isActive: boolean) => {
    this.isActive = isActive;
  };
}

export default TableFieldModel;
