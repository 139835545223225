import React from "react";
import LicenseCheckRenderWrap from "./LicenseCheckRenderWrap";

interface Props {
  licenseCode?: string;
}

const LicenseCheckSwitch: React.FC<Props> = ({ children, licenseCode }) => {
  if (licenseCode) {
    return <LicenseCheckRenderWrap licenseCode={licenseCode}>{children}</LicenseCheckRenderWrap>;
  } else {
    return <>{children}</>;
  }
};

export default LicenseCheckSwitch;
