import React from "react";
import PropTypes from "prop-types";
import PartialLoading from "./PartialLoading";

const TableLoading = ({ isLoading, children, minHeight }) => {
  if (isLoading) {
    return children;
  } else {
    return (
      <tr>
        <td colSpan={100}>
          <PartialLoading minHeight={minHeight} />
        </td>
      </tr>
    );
  }
};

TableLoading.propTypes = {
  minHeight: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

TableLoading.defaultProps = {
  minHeight: 300,
};

export default TableLoading;
