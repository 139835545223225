import React from "react";
import { Row, Col, ButtonGroup, Button } from "reactstrap";
import { Observer, observer } from "mobx-react";

import { SearchListFilter, DefaultTable, TableWithView, CustomPagination, PartialLoadingWrapper } from "@qubit-utill/src";
import ButtonTypes from "@qubit-utill/src/type/ButtonTypes";
import TableConfig from "@qubit-utill/src/type/TableConfig";

import { languageSet } from "@src/utils";
import { ListCondition } from "@src/utils/store";
import Footer from "@src/utils/components/Layout/Footer";

interface PropsType {
  store: ListCondition & {
    tableList: Array<any>;
  };
  buttons?: Array<ButtonTypes>;
  isUsePagination?: boolean;
  tableConfig: TableConfig;
  isTotalCount?: boolean;
  children?: any;
  isLoading?: boolean;
  loadingMinHeight?: string | number;
}

const DefaultListLayout: React.FC<PropsType> = ({
  store,
  buttons = [],
  isUsePagination = true,
  isTotalCount = true,
  tableConfig,
  children,
  isLoading = false,
  loadingMinHeight,
}) => {
  const { totalCount, tableList, params, offset, changePage } = store;
  const _totalCount = totalCount || tableList.length;

  const _renderButtons = () => (
    <>
      {buttons.map(({ name, action, isUseIcon, color = "dark", size, className = "btn-rounded " }) => (
        <Button color={color} className={className} onClick={action} key={name} size={size}>
          <span>{name}</span>
          {isUseIcon && <span className="fa fa-level-up p-l-5" />}
        </Button>
      ))}
    </>
  );

  const TableComponent = tableConfig.tableType === "default" ? DefaultTable : TableWithView;

  return (
    <div className="page-content width-100p">
      <PartialLoadingWrapper isLoading={isLoading} minHeight={loadingMinHeight || 600}>
        {children}
        <Row className="p-5">
          <Col lg="12">
            <div className="panel min_h650">
              <div className="panel-content">
                <Row>
                  <Col md={isTotalCount ? "8" : "12"} className="f-12 p-t-10">
                    <div className="dis-inline-b">
                      <SearchListFilter store={store} isUsePagination={isUsePagination} />
                    </div>
                    {buttons.length > 0 && (
                      <div className="f-right">
                        {isTotalCount && (
                          <div className="dis-inline-b bold f-12 c-orange p-r-5 p-t-10">
                            <span>{languageSet("총게시물")}</span> : <span>{_totalCount}</span>
                          </div>
                        )}
                        <ButtonGroup className="m-r-0 dis-inline-b">
                          <Observer render={_renderButtons} />
                        </ButtonGroup>
                      </div>
                    )}
                  </Col>
                </Row>
                <TableComponent
                  useIndex={tableConfig.useIndex}
                  trType={tableConfig.trType}
                  isUseSetting={tableConfig.isUseSetting}
                  isUseCheck={tableConfig.isUseCheck}
                  viewType={tableConfig.viewType}
                  emptyMessage={tableConfig.emptyMessage}
                  thFields={tableConfig.thFields}
                  rowThFields={tableConfig.rowThFields}
                  colorTheme={tableConfig.colorTheme}
                  tableBordered={tableConfig.tableBordered}
                  className={tableConfig.className}
                  useRow={tableConfig.useRow}
                  totalCount={_totalCount}
                  tableList={tableList}
                  offset={offset}
                  store={store}
                  settingThemeColor="bg-blue-che4"
                  settingRowSpan={tableConfig.settingRowSpan}
                  thClassName={tableConfig.thClassName}
                />
                {isUsePagination && (
                  <CustomPagination
                    changePage={changePage}
                    limit={params.size}
                    LINECOUNT={params.size}
                    totalCount={_totalCount}
                    page={params.page}
                    isUseUrl
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </PartialLoadingWrapper>
      <Footer />
    </div>
  );
};

export default observer(DefaultListLayout);
