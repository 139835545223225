import React from "react";
import { SORTTYPE, ThFieldsConfig } from "../store";

type thValue = string | number;

const getSortValue = (value: thValue) => {
  if (value != null && value != undefined) {
    return isNaN(Number(value)) ? value.toString().toLowerCase() : Number(value);
  }
  return "";
};

function isDash(value: thValue) {
  return value === "-" || value === "";
}

function compareValue(a: thValue, b: thValue, sortType: boolean) {
  if (a < b) {
    return sortType ? -1 : 1;
  }
  if (a > b) {
    return sortType ? 1 : -1;
  }
  return 0;
}

function compareNull(a: thValue, b: thValue) {
  // null인 경우는 후순위로
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return 0;
}
function compareDash(a: thValue, b: thValue) {
  //"-" 나 "" 문자열은 소팅 기준 후순위로 하기 원함.
  const aIsDash = isDash(a);
  const bIsDash = isDash(b);
  if (aIsDash && !bIsDash) {
    return 1;
  }
  if (bIsDash && !aIsDash) {
    return -1;
  }
  return 0;
}

function compareType(a: thValue, b: thValue) {
  //같은 타입 끼리 묶이도록
  if (typeof a === "number" && typeof b !== "number") {
    return 1;
  } else if (typeof b === "number" && typeof a !== "number") {
    return -1;
  }
  return 0;
}

function useTableSort({ tableList, thFields }: { tableList: any[]; thFields: ThFieldsConfig }) {
  const _tableList = React.useMemo(() => {
    if (thFields.selectedSort) {
      const keyArr = thFields.selectedSort.split(",");
      const isDepth = keyArr.length > 1;
      const sortType = thFields.sortType === SORTTYPE.UP;
      return tableList.sort(function(a: thValue, b: thValue) {
        const valueA = isDepth ? a[keyArr[0]][keyArr[1]] : a[thFields.selectedSort];
        const valueB = isDepth ? b[keyArr[0]][keyArr[1]] : b[thFields.selectedSort];
        let sortA = getSortValue(valueA);
        let sortB = getSortValue(valueB);
        if (sortA === sortB) {
          sortA = valueA;
          sortB = valueB;
        }
        return (
          compareNull(valueA, valueB) ||
          compareDash(valueA, valueB) ||
          compareType(sortA, sortB) ||
          compareValue(sortA, sortB, sortType)
        );
      });
    }
    return tableList;
  }, [tableList, thFields, thFields.selectedSort, thFields.sortType]);

  React.useEffect(() => {
    thFields.selectedSort = "";
    thFields.sortType = SORTTYPE.UP;
  }, [tableList]);

  return { _tableList };
}

export default useTableSort;
