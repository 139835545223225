import { observable, action } from "mobx";

import { dateUtil, formatConstants, withLoading } from "@qubit-utill/src";

import { dashboardApis } from "@src/apis";
import { LineModel } from "@src/common/models";
import { LineType } from "@src/common/models/GraphModel/LineModel";
import { ListCondition } from "@src/utils/store";

type GraphType = {
  domainId: number;
  domain: string;
  data: Array<LineType>;
  category?: Array<string>;
};

class DomainGraphStore extends ListCondition {
  constructor(root) {
    super();
  }

  @observable
  tableList: Array<GraphType> = [];

  @observable
  graphInfo: GraphType = { domainId: 0, domain: "", data: [], category: [] };

  @observable
  speedUnit: string = "ms";

  @observable
  sizeUnit: string = "bytes";

  @withLoading("isLoading")
  @action
  getList = async (isSpeed) => {
    try {
      const getGraphApi = isSpeed ? dashboardApis.getSpeedGraphList : dashboardApis.getSizeGraphList;
      const { list, totalCount } = await getGraphApi({ page: this.params.page, size: this.params.size });
      if (list.length > 0) {
        this.tableList = list.map((info) => {
          const domainId = info.domainId;
          const domain = info.domain;
          const stats = isSpeed ? info.speedStats : info.sizeStats;
          const statsValue = isSpeed ? "speed" : "size";
          const _category: Array<string> = [];
          const _data: Array<number> = [];
          stats.forEach((info) => {
            _data.push(info[statsValue]);
            _category.push(dateUtil.format(new Date(info.time), formatConstants.TIMEFORMAT));
          });
          const { category, chartData } = new LineModel({ category: _category, data: _data });
          return { domainId, domain, data: chartData, category };
        });
        this.totalCount = totalCount;
      }
    } catch (err) {
      this.tableList = [];
      this.totalCount = 0;
      return err;
    }
  };

  @action
  getInfo = async (id, isSpeed) => {
    try {
      const getGraphApi = isSpeed ? dashboardApis.getSpeedGraphList : dashboardApis.getSizeGraphList;
      const { list } = await getGraphApi({
        page: this.params.page,
        size: this.params.size,
        isExtend: true,
        domainId: id,
      });
      const info = list[0];

      const _category: Array<string> = [];
      const _data: Array<number> = [];

      const stats = isSpeed ? info.speedStats : info.sizeStats;
      const statsValue = isSpeed ? "speed" : "size";

      stats.forEach((info) => {
        _data.push(info[statsValue]);
        _category.push(dateUtil.format(new Date(info.time), formatConstants.TIMEFORMAT));
      });

      const { category, chartData } = new LineModel({ category: _category, data: _data });

      this.graphInfo = {
        domainId: info.domainId,
        domain: info.domain,
        data: chartData,
        category: category,
      };
      return this.graphInfo;
    } catch (err) {
      return err;
    }
  };

  @action
  setIsSubLoading = (flag: boolean) => {
    this.isSubLoading = flag;
  };
}

export default DomainGraphStore;
