import stringUtils from "./stringUtils";

export default {
  IsNullFormat: string => {
    if (string === null || string === undefined) {
      return "-";
    } else {
      return string;
    }
  },
  tryDecode: string => {
    try {
      if (string) {
        return decodeURIComponent(string);
      } else {
        return string;
      }
    } catch (e) {
      return unescape(string);
    }
  },
  logDecode(str) {
    return stringUtils.replaceAll(this.tryDecode(str), "+", " ");
  },
};
