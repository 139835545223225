import languageSet from "@src/utils/lang/languageSet";

export const CSV_DOWNLOAD_COUNT = 4000;

export const SESSION_KEY_INDEX_DOMAIN = "INDEX_DOMAIN"; //index domain session storage name

export const BLOGLINK = "https://docs.checurl.site/";

export const SECONDS_OPTION = [
  { name: "120s", value: 120 },
  { name: "90s", value: 90 },
  { name: "60s", value: 60 },
  { name: "30s", value: 30 },
];

export const BIT_OPTION = [
  { name: "32", value: 32 },
  { name: "31", value: 31 },
  { name: "30", value: 30 },
  { name: "29", value: 29 },
  { name: "28", value: 28 },
  { name: "27", value: 27 },
  { name: "26", value: 26 },
  { name: "25", value: 25 },
  { name: "24", value: 24 },
  { name: "23", value: 23 },
  { name: "16", value: 16 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const COUNT_OPTION_MAX_10 = [
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: "5", value: 5 },
  { name: "10", value: 10 },
];

export const COUNT_OPTION_MAX_60 = [
  { name: "1", value: 1 },
  { name: "5", value: 5 },
  { name: "10", value: 10 },
  { name: "20", value: 20 },
  { name: "30", value: 30 },
  { name: "60", value: 60 },
];

export const PROTOCOL = [
  { name: "http", value: "http" },
  { name: "https", value: "https" },
];
