export default {
  newArray: function(array: any[], func: Function) {
    let newArray = [...array];
    func(newArray);
    return newArray;
  },
  move: function(array: any[], from: number, to: number) {
    return this.newArray(array, (newArray: any[]) => {
      newArray.splice(to, 0, newArray.splice(from, 1)[0]);
    });
  },

  upOrder: function(array: any[], from: number) {
    return this.newArray(array, (newArray: any[]) => {
      newArray.splice(from + 1, 0, newArray.splice(from, 1)[0]);
    });
  },

  downOrder: function(array: any[], from: number) {
    return this.newArray(array, (newArray: any[]) => {
      newArray.splice(from - 1, 0, newArray.splice(from, 1)[0]);
    });
  },

  sortByKey(arr: any[], orderName: string) {
    return arr.sort((a, b) => {
      const _a = a[orderName],
        _b = b[orderName];
      if (_a > _b) return 1;
      if (_b > _a) return -1;
      return 0;
    });
  },

  deleteElement(arr: any[], index: number) {
    arr.splice(index, 1);
  },

  deleteByKey(arr: any[], key: string, value: any) {
    return arr.filter(element => element[key] !== value);
  },

  handleEditOrder(arr: any[], orderKey: string, index: number, isDown: boolean) {
    let currentItem = arr[index];
    let otherItem = arr[isDown ? ++index : --index];
    const currenetOrderNo = currentItem[orderKey];
    const otherOrderNo = otherItem[orderKey];
    currentItem[orderKey] = otherOrderNo;
    otherItem[orderKey] = currenetOrderNo;
  },

  sortByDataDESC(arr: any[], orderName: string): any[] {
    return arr.sort((a, b) => {
      const _a = new Date(a[orderName]),
        _b = new Date(b[orderName]);
      if (_a < _b) return 1;
      if (_b < _a) return -1;
      return 0;
    });
  },

  sortByDataASC(arr: any[], orderName: string): any[] {
    return arr.sort((a, b) => {
      const _a = new Date(a[orderName]),
        _b = new Date(b[orderName]);
      if (_a > _b) return 1;
      if (_b > _a) return -1;
      return 0;
    });
  },

  //model observable array 용
  resetOrder(arr: any[], orderName: string) {
    arr.forEach((element, index) => {
      element[orderName] = index;
    });
  },

  isDifference(oriArr: any[], newArr: any[]): boolean | void {
    try {
      if (oriArr.length !== newArr.length) {
        return true;
      }
      return oriArr.filter(element => newArr.indexOf(element) < 0).length > 0;
    } catch (e) {
      console.log("isDifference", e);
      console.log("oriArr", oriArr);
      console.log("newArr", newArr);
    }
  },

  makeUniqueArray(array: any[]) {
    return array.filter((item, index) => array.indexOf(item) === index);
  },

  reorder(list: any[], startIndex: number, endIndex: number): any[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  },

  replaceText(array: string[], key: string, value: string) {
    return array.map(string => string.replace(key, value));
  },

  mergeUnionArray<A, B>(arrA: A[], arrB: B[], identifyKey: string): (A & B)[] {
    return arrA.map(a => {
      const source = arrB.find(b => b[identifyKey] === a[identifyKey]);
      return Object.assign({}, a, source);
    });
  },

  sortByIpAddress(list: Array<any>, key: string) {
    list.sort((a, b) => {
      const firstIp = Number(
        a[key]
          .split(".")
          .map((num: any) => `000${num}`.slice(-3))
          .join("")
      );
      const secondIp = Number(
        b[key]
          .split(".")
          .map((num: any) => `000${num}`.slice(-3))
          .join("")
      );
      return firstIp - secondIp;
    });
  },
};
