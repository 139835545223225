import React from "react";
import { useObserver } from "mobx-react";

function useTableCheck(tableList: any[]) {
  const isAllCheck = useObserver(() =>
    Boolean(tableList && tableList.length && tableList.every(info => info.isSelected))
  );

  const toggleAllCheck = React.useCallback(() => {
    if (!tableList.some(info => !info.disableCheckbox)) return;
    tableList.forEach(info => info.setInfo("isSelected", !isAllCheck));
  }, [isAllCheck, tableList]);

  return { isAllCheck, toggleAllCheck };
}

export default useTableCheck;
