import languageSet from "@src/utils/lang/languageSet";
import getURI from "@src/constants/uriset";
import { DashboardContainer } from "@src/pages";

export default {
  DASHBOARD: {
    text: languageSet("대시보드"),
    title: languageSet("대시보드"),
    link: getURI("DASHBOARD"),
    withPageLink: `${getURI("MEMBER")}/page/:page`,
    component: DashboardContainer,
  },
};
