import getURI from "@src/constants/uriset";
import { IndexKoContainer, IndexJaContainer, IndexEnContainer } from "@src/pages";

export default {
  INDEX_KO: {
    link: getURI("INDEX_KO"),
    component: IndexKoContainer,
  },
  INDEX_JA: {
    link: getURI("INDEX_JA"),
    component: IndexJaContainer,
  },
  INDEX_EN: {
    link: getURI("INDEX_EN"),
    component: IndexEnContainer,
  },
};
