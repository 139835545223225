import { observable, action, computed } from "mobx";

import { default as dateFormatUtils } from "@src/utils/dateFormatUtils";
import { SetterModel } from "@src/utils/model";

class LicenseCodeModel extends SetterModel {
  constructor(params: any) {
    super(params);
  }

  @observable
  licenseGradeName: string = ""; //라이선스 등급명

  @observable
  licenseGrade: string = ""; //라이선스 등급

  @observable
  startDate: string = ""; //라이선스 시작일

  @observable
  endDate: string = ""; //라이선스 종료일

  @observable
  remaining: number = 0; //라이선스 잔여일

  @observable
  alert: boolean = false; //알림필요여부

  @observable
  isOverGold: boolean = false; //골드 이상 여부

  @observable
  isOverPlatinum: boolean = false; //플래티넘 이상 여부

  @observable
  expired: boolean = false; //라이선스 만료 여부

  @action
  setInfo(elementName: string, value: any) {
    this[elementName] = value;
  }

  @computed
  get licenseStartDate() {
    return dateFormatUtils.defaultDateTimeToLocalTime(this.startDate, true);
  }

  @computed
  get licenseEndDate() {
    return dateFormatUtils.defaultDateTimeToLocalTime(this.endDate, true);
  }

  @computed
  get isAlertFlag() {
    return this.alert;
  }
}

export default LicenseCodeModel;
