import { computed, observable } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

class HostListModel extends SetterModel {
  constructor(data?: any, setFlag?: boolean) {
    super(data);
    if (data) {
      if (data.isRegEx) {
        this.isRegEx = typeof data.isRegEx === "string" ? Common_Utils.isCheckBool(data.isRegEx) : data.isRegEx;
      }
      if (setFlag) {
        this.isChecked = true;
      }
    }
  }

  @observable
  host: string = "";

  @observable
  isChecked: boolean = false;

  @observable
  isRegEx: boolean = false;

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  userHostId: number = 0;

  @observable
  sslExpirationDate: string = "";

  @computed
  get setIsChecked() {
    return Common_Utils.BoolToString(this.isChecked);
  }

  @computed
  get setIsRegEx() {
    return Common_Utils.BoolToString(this.isRegEx);
  }
}

export default HostListModel;
