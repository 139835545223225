import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatConstants } from "../../../common_constants";
import { languageSet } from "@qubit-utill/src";
import Input from ".";

const TimePicker = ({
  timeIntervals,
  disabled,
  onChange,
  value,
  timeFormat,
  className,
  minDate,
}) => {
  return (
    <ReactDatePicker
      disabledKeyboardNavigation={false}
      disabled={disabled}
      placeholderText={languageSet("시간선택")}
      className={`form-control ${disabled ? "cursor_d" : "cursor_p"} input-sm w_100px ${className}`}
      selected={value ? value : null}
      onChange={onChange}
      customInput={<Input.Text noColor />}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeIntervals}
      dateFormat={timeFormat}
      timeFormat={timeFormat}
      minDate={minDate}
      popperClassName="z-in3"
    />
  );
};

TimePicker.propTypes = {
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
};

TimePicker.defaultProps = {
  timeFormat: formatConstants.TIMEFORMAT,
  onChange: () => {},
  timeIntervals: 60,
  minDate: null,
  className: "",
  disabled: false,
};

export default TimePicker;
