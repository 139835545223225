import React from "react"
import { Button } from "reactstrap"
import { PropTypes } from "prop-types"

//todo:사용여부 판단후 삭제
const Add = ({ text, href, handleModal, history, className }) => {
  return (
    <div className="btn-group m-r-0">
      <Button
        color="dark"
        className="btn-rounded"
        onClick={e => {
          href && history.push(href)
          handleModal && handleModal()
        }}
      >
        {text}
        <span className={`fa fa-level-up ${className}`} />
      </Button>
    </div>
  )
}

Add.prototype = {
  text: PropTypes.string,
  className: PropTypes.stirng
}

Add.defaultProps = {
  className: "p-l-5"
}

export default Add
